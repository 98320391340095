import { useEffect, useState } from 'react';
import { Space, Table, Button, Alert, Modal, Popconfirm, Tag  } from 'antd';
import { DateComponent } from '../../components/liste/dateComponent';
import { listAuditApp } from '../../data/audit/auditapp';



const AuditPage = () => {
  const [audits, setAudits] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false) 

  const columns = [
    {
      title: '#',
      render: (text, record, index) => <span className='display-block chart-blue'>{ index+1 }</span>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text) => <span className='display-block bold'>{text === 'CONSULTATION_LISTE' ? 'CONSULTATION' : text}</span>,
    },
    {
      title: 'Contenu',
      dataIndex: 'contenu',
      key: 'contenu',
    },
    {
      title: 'Adress IP',
      dataIndex: 'ip_adress',
      key: 'ip_adress',
      render: (text) =>  <Tag color="green">{text}</Tag>
      },
    {
      title: 'Système / Navigateur',
      dataIndex: 'os',
        key: 'os',
      render: (text, record) =>  <><span>{record.os} <br /> {record.navigateur} ({record.terminal})</span></>
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => <DateComponent date={record.createdAt} user={record.nom_user+" "+record.prenom_user} key={index} />
    }
  ];



  const handleListeAudit = async () => {
    setLoading(true)
    const data = {
        "user_id": "undefined", 
        "action": "undefined", 
        "ip_adress": "undefined", 
        "contenu": "undefined", 
        "date_debut": "undefined", 
        "date_fin": "undefined"
    }
    const { result, status, message } = await listAuditApp(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setAudits(result)
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }





  useEffect(() => {
    handleListeAudit()
  }, [])


  return (
    <>
      <div className="card mb-5 mb-lg-10">
        {error === true &&
          <Space
            direction="vertical"
            style={{
              width: '100%',
            }}
          >
            <Alert
              message="Error"
              description={message}
              type="error"
              showIcon
              
            />
          </Space>
        }
        
        <div className="card-header">
            
          <div className="card-title">
            <h3>Historiques des utilisateurs</h3>
          </div>
    
          <div className="card-toolbar">

          </div>
            
        </div>
                
        <div className="card-body p-0">
            
            <div className="table-responsive">
                
              <Table loading={loading} columns={columns} dataSource={audits} pagination= { {current: 1, pageSize: 50} } />
                
            </div>
            
        </div>
                
      </div>

    </>
  )

}

export default AuditPage;