import React, {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag} from 'antd'

import {DateComponent} from '../../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addParams,
  deleteParams,
  getListeParams,
  updateEtatParams,
  updateParams,
} from '../../../data/params/paramsGlobaux'
import { getListeUnite } from '../../../data/params/unites'

const ParametreGlobauxPage = () => {
  const [parametres, setParametres] = useState([])
  const [unites, setUnites] = useState([])
  const [parametre, setParametre] = useState([])
  const [serchDesc, setSerchDesc] = useState('')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [valeur, setValeur] = useState('')
  const [unite_id, setUniteId] = useState(1)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Valeur',
      dataIndex: 'valeur',
      key: 'valeur',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Unité',
      dataIndex: 'libelle_unite',
      key: 'libelle_unite',
      render: (text) => <span className='display-block bold chart-orange'>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button type='primary' icon={<EditOutlined />} onClick={() => selectParams(record.id)} />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer ce paramètre ${record.libelle} ?`}
              description={`Voulez vous vraiment activer ce paramètre ${record.libelle} ?`}
              onConfirm={() => handleUpdateParams(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver ce paramètre ${record.libelle} ?`}
              description={`Voulez vous vraiment désactiver ce paramètre ${record.libelle} ?`}
              onConfirm={() => handleUpdateParams(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer ce paramètre ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer ce paramètre ${record.libelle} ?`}
            onConfirm={() => handleDeleteParams(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const showModal = () => {
    setOpen(true)
  }
  const handleAddParams = async () => {
    setLoading(true)
    const data = { libelle, valeur, description, unite_id}
    console.log(data)
    const {status, message} = await addParams(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeParams()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierParams = async () => {
    setLoading(true)
    const data = {
      params_id: parametre.id,
      libelle,
      valeur,
      description,
      unite_id
    }
    console.log(data)
    const {status, message} = await updateParams(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeParams()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setError(false)
  }

  const selectParams = (params_id) => {
    console.log(params_id)
    const params = parametres.filter((parametre) => parametre.id === params_id)[0]
    setUniteId(params.unite_id)
    setParametre(params)
    setLibelle(params.libelle)
    setValeur(params.valeur)
    setDescription(params.description)
    setEdite(true)
    setOpen(true)
  }

  const handleListeParams = async () => {
    setLoading(true)
    const {result, status, message} = await getListeParams()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setParametres(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteParams = async (params_id) => {
    setLoading(true)
    const data = {params_id}
    const {status, message} = await deleteParams(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeParams()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateParams = async (params_id, etat) => {
    setLoading(true)
    const data = {params_id, etat}
    const {status, message} = await updateEtatParams(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeParams()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const filteredItems = parametres.filter(
    (item) =>
      item.libelle.toLowerCase().includes(serchDesc.toLowerCase()) ||
      item.valeur.toLowerCase().includes(serchDesc.toLowerCase()) ||
      item.id === parseInt(serchDesc)
  )
  const handleClear = () => {
    if (serchDesc) {
      setSerchDesc('')
    }
  }
  
  const handleListeUnite = async () => {
    // setLoading(true)
    const { result, status, message } = await getListeUnite()
    setMessage(message)
    if (status === 'success') {
      setUnites(result)
      // setLoading(false)
    } else {
      setError(true)
      // setLoading(false)
    }
  }

  useEffect(() => {
    handleListeParams()
    handleListeUnite()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Paramètre globaux enregistrés</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='search'
                name='fname'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
                onChange={(e) => setSerchDesc(e.target.value)}
                onClear={handleClear}
                value={serchDesc}
              />
            </div>

            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table loading={loading} columns={columns} dataSource={filteredItems} pagination= { {current: 1, pageSize: 50} } />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer un nouveau paramètre' : 'Modifier le paramètre'}
        onOk={!edite ? handleAddParams : handleModifierParams}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddParams : handleModifierParams}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && <Space
          direction="vertical"
          style={{
            width: '100%',
          }}
        >
          <Alert message={message} type="error" showIcon />

        </Space>}
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
        <p>
          <label className='bold'>Valeur</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setValeur(e.target.value)}
            value={valeur}
            placeholder='Entrez la valeur'
          />
        </p>
        <p>
          <label className='bold'>Description</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder='Entrez la description'
          />
        </p>
        <p>
          <label className='bold'>Unité</label>
          <select value={unite_id} onChange={(e) => setUniteId(e.target.value)} className='form-select form-select-solid form-select-lg fw-bold'>
            {unites.map(unite => <option value={unite.id} key={unite.id}>{unite.libelle}</option>) }
          </select>
        </p>
      </Modal>
    </>
  )
}

export default ParametreGlobauxPage
