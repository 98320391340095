import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LayoutSplashScreen} from './core/layout'
import {AuthInit} from './core/auth'
import {LayoutProvider} from './core/layout'
import {MasterInit} from './layouts/MasterInit'
import {I18nProvider} from './helpers/i18n/i18nProvider'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
