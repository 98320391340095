import axios from 'axios'
import { AJOUTER_OPERATEUR, LISTE_OPERATEUR, MODIFIER_ETAT_OPERATEUR, MODIFIER_OPERATEUR, SUPPRIMER_OPERATEUR } from '../../api/apiRoute'

export const addOperateur = async (data) => {
    return axios.post(AJOUTER_OPERATEUR, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateOperateur = async (data) => {
    return axios.post(MODIFIER_OPERATEUR, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeOperateur = async () => {
    return axios.get(LISTE_OPERATEUR).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteOperateur = async (data) => {
    return axios.post(SUPPRIMER_OPERATEUR, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatOperateur = async (data) => {
    return axios.post(MODIFIER_ETAT_OPERATEUR, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}