import axios from 'axios'

const LISTE_QUESTION_CYCLE = `${process.env.REACT_APP_API_URL}/params/liste-question-cycle`
const AJOUTER_QUESTION_CYCLE = `${process.env.REACT_APP_API_URL}/params/ajouter-question-cycle`
const MODIFIER_QUESTION_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-question-cycle`
const SUPPRIMER_QUESTION_CYCLE = `${process.env.REACT_APP_API_URL}/params/supprimer-question-cycle`
const MODIFIER_QUESTION_ETAT_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-question-cycle`

export const addQuestionCycle = async (data) => {
  return axios
    .post(AJOUTER_QUESTION_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateQuestionCycle = async (data) => {
  return axios
    .post(MODIFIER_QUESTION_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeQuestionCycle = async () => {
  return axios
    .get(LISTE_QUESTION_CYCLE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeQuestionByCycle = async (rubriqueCycle_id) => {
  return axios
    .get(`${LISTE_QUESTION_CYCLE}/${rubriqueCycle_id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteQuestionCycle = async (data) => {
  return axios
    .post(SUPPRIMER_QUESTION_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatQuestionCycle = async (data) => {
  return axios
    .post(MODIFIER_QUESTION_ETAT_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
