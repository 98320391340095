import axios from 'axios'
import {
    AJOUTER_EVENT,
    LISTE_EVENT, LISTE_EVENT_ACTIVE,  
    MODIFIER_EVENT,
    MODIFIER_ETAT_EVENT,  SUPPRIMER_EVENT
} from '../../api/apiRoute'

export const addEvent = async (data) => {
    return axios.post(AJOUTER_EVENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateEvent = async (data) => {
    return axios.post(MODIFIER_EVENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeEvent = async () => {
    return axios.get(LISTE_EVENT).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeEventActive = async () => {
    return axios.get(LISTE_EVENT_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteEvent = async (data) => {
    return axios.post(SUPPRIMER_EVENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatEvent = async (data) => {
    return axios.post(MODIFIER_ETAT_EVENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}