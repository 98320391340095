import React from 'react'
import {KTSVG} from '../helpers/KTSVG'
import clsx from 'clsx'
import {HomeOutlined} from '@ant-design/icons'

const StatCard = ({icon = '', fontIcon = '', data = []}) => (
  <>
    <div className='col-lg-4 position-relative' style={{paddingRight: '20px', paddingLeft: '20px'}}>
      <div
        className='border-0 mb-5'
        style={{
          color: '#f4f5fd',
          boxShadow: '0 15px 50px rgba(160, 163, 189, .1)',
          borderRadius: '10px',
          backgroundColor: '#fff',
          padding: '24px 19px',
          backgroundImage: 'linear-gradient(90deg, #02b0c8 0, #c72d85)',
        }}
      >
        <div className='card-body'>
          <div className='d-flex align-items-start'>
            <div className='font-weight-bold'>
              <small className='text-black-50 d-block mb-1 text-uppercase'>New Accounts</small>
              <span className='mt-1' style={{fontSize: '1.6625rem'}}>
                586,356
              </span>
            </div>
            <div className='' style={{marginLeft: 'auto'}}>
              <div className='bg-white text-center text-success d-50 rounded-circle'>
                {icon && (
                  <span className='menu-icon'>
                    <KTSVG path={icon} className='svg-icon-2' />
                  </span>
                )}
                {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
              </div>
            </div>
          </div>
          <div className='mt-3'>
            {' '}
            <HomeOutlined />{' '}
          </div>
        </div>
      </div>
    </div>
  </>
)

export {StatCard}
