import axios from 'axios'

const LISTE_HONORAIRE_PARAMETRE = `${process.env.REACT_APP_API_URL}/params/liste-honoraire-parametre`
const LISTE_ACTIVE_HONORAIRE_PARAMETRE = `${process.env.REACT_APP_API_URL}/params/liste-active-honoraire-parametre`
const AJOUTER_HONORAIRE_PARAMETRE = `${process.env.REACT_APP_API_URL}/params/ajouter-honoraire-parametre`
const MODIFIER_HONORAIRE_PARAMETRE = `${process.env.REACT_APP_API_URL}/params/modifier-honoraire-parametre`
const MODIFIER_ETAT_HONORAIRE_PARAMETRE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-honoraire-parametre`
const SUPPRIMER_HONORAIRE_PARAMETRE = `${process.env.REACT_APP_API_URL}/params/supprimer-honoraire-parametre`

export const ajouterHonoraireParametre = async (data) => {
  return axios
    .post(AJOUTER_HONORAIRE_PARAMETRE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const modifierHonoraireParametre = async (data) => {
  return axios
    .post(MODIFIER_HONORAIRE_PARAMETRE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeHonoraireParametre = async () => {
  return axios
    .get(LISTE_HONORAIRE_PARAMETRE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeActiveHonoraireParametre = async (cycle_id) => {
  return axios
    .get(LISTE_ACTIVE_HONORAIRE_PARAMETRE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const supprimerEtatHonoraireParametre = async (data) => {
  return axios
    .post(SUPPRIMER_HONORAIRE_PARAMETRE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const modifierEtatHonoraireParametre = async (data) => {
  return axios
    .post(MODIFIER_ETAT_HONORAIRE_PARAMETRE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
