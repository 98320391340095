import React, {useEffect, useState} from 'react'
import {getRevendeurUsers} from '../../../core/users/_request'
import {getFormuleSouscriptions} from '../../../core/formulesSouscription/_requests'
import {useFormik} from 'formik'
import {AbonnementClient} from '../../../core/clients/_requests'
import * as Yup from 'yup'
import clsx from 'clsx'

const ClientAbonnement = ({client, handleUpdate}) => {
  const [formules, setFormules] = useState([])
  const [revendeurs, setRevendeurs] = useState([])

  const AbonnementDetailSchema = Yup.object().shape({
    formule_id: Yup.number().required('La formule est requise'),
    prix: Yup.number().required('Le prix est requis'),
    remise: Yup.number().required('La rémise est requise'),
    apporteur_client_id: Yup.number().required("L'apporteur  est requise"),
  })

  const today = new Date().toISOString().split('T')[0]
  const Abonnement = {
    formule_id: 1,
    date_debut_abon: today,
    date_fin_abon: '',
    apporteur_client_id: 0,
    prix: 0,
    montant_a_payer: 0,
    remise: 0,
    client_id: client.id,
  }

  const handleListeAbonnement = async () => {
    const {result, status} = await getFormuleSouscriptions()
    if (status === 'success') {
      setFormules(result)
    }
  }
  const handleListeRevendeur = async () => {
    const {result, status} = await getRevendeurUsers()
    if (status === 'success') {
      setRevendeurs(result)
    }
  }

  useEffect(() => {
    handleListeRevendeur()
    handleListeAbonnement()
  }, [])

  const formik = useFormik({
    initialValues: Abonnement,
    validationSchema: AbonnementDetailSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        const {message, status} = await AbonnementClient(values)
        if (status !== 'success') {
          setStatus(message)
        }
        handleUpdate()
        window.reload()
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const handleFormuleChange = (event) => {
    const formuleId = parseInt(event.target.value)
    formik.setFieldValue('formule_id', formuleId)
    const selectedFormule = formules.find((formule) => formule.id === formuleId)
    if (selectedFormule) {
      formik.setFieldValue('prix', selectedFormule.prix)
      formik.setFieldValue('montant_a_payer', selectedFormule.prix)
    }
  }

  useEffect(() => {
    const montantFormule = formik.values.prix || 0
    const remise = formik.values.remise || 0
    const montantAPayer = montantFormule - remise
    formik.setFieldValue('montant_a_payer', montantAPayer)
  }, [formik.values.prix, formik.values.remise])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Abonnement client</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* Input group */}
      <div className='row g-9 mb-8'>
        {/* Col */}
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-bold mb-2'>Formules</label>
          <select
            {...formik.getFieldProps('formule_id')}
            className={clsx(
              'form-select form-select-solid select2-hidden-accessible',
              {'is-invalid': formik.touched.formule_id && formik.errors.formule_id},
              {
                'is-valid': formik.touched.formule_id && !formik.errors.formule_id,
              }
            )}
            data-control='select2'
            data-hide-search='true'
            data-placeholder='Selectionner'
            name='formule_id'
            tabIndex='-1'
            aria-hidden='true'
            onChange={handleFormuleChange}
          >
            <option value=''>Selectionner...</option>
            {formules.map((formule) => (
              <option value={formule.id} key={formule.id}>
                {formule.libelle}
              </option>
            ))}
          </select>

          {formik.touched.formule_id && formik.errors.formule_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.formule_id}</div>
            </div>
          )}
        </div>
        {/* End Col */}
        {/* Col */}
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-bold mb-2'>Date de debut d'utilisation</label>
          <input
            {...formik.getFieldProps('date_debut_abon')}
            className={clsx(
              'form-control form-control-solid ps-12',
              {
                'is-invalid': formik.touched.date_debut_abon && formik.errors.date_debut_abon,
              },
              {
                'is-valid': formik.touched.date_debut_abon && !formik.errors.date_debut_abon,
              }
            )}
            placeholder='Selectionner une date'
            name='date_debut_abon'
            type='date'
            value={formik.values.date_debut_abon}
          />
          {formik.touched.date_debut_abon && formik.errors.date_debut_abon && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.date_debut_abon}</div>
            </div>
          )}
        </div>
        {/* End Col */}
      </div>
      {/* End Input group */}
      {/* Input group */}
      <div className='row g-9 mb-8'>
        {/* Col */}
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-bold mb-2'>Montant de formule</label>
          {/* End Label */}
          <input
            type='number'
            {...formik.getFieldProps('prix')}
            className={clsx(
              'form-control form-control-solid',
              {'is-invalid': formik.touched.prix && formik.errors.prix},
              {
                'is-valid': formik.touched.prix && !formik.errors.prix,
              }
            )}
            placeholder='Entrez le prix'
            name='prix'
          />
          {formik.touched.prix && formik.errors.prix && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.prix}</div>
            </div>
          )}
        </div>
        {/* End Col */}
        {/* Col */}
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-bold mb-2'>Remise</label>
          <input
            type='number'
            {...formik.getFieldProps('remise')}
            className={clsx(
              'form-control form-control-solid',
              {'is-invalid': formik.touched.remise && formik.errors.remise},
              {
                'is-valid': formik.touched.remise && !formik.errors.remise,
              }
            )}
            placeholder='Entrez la remise'
            name='remise'
          />
          {formik.touched.remise && formik.errors.remise && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.remise}</div>
            </div>
          )}
        </div>
        {/* End Col */}
      </div>
      {/* End Input group */}
      {/* Input group */}
      <div className='row g-9 mb-8'>
        {/* Col */}
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-bold mb-2'>Montant à payer(FCFA)</label>
          {/* End Label */}
          <input
            type='text'
            readOnly
            value={parseInt(formik.values.montant_a_payer).toLocaleString('fr')}
            className='form-control form-control-solid'
            placeholder='Montant à payer'
            name='montantAPayer'
          />
        </div>
        {/* End Col */}
      </div>
      {/* End Input group */}
      {/* Input group */}
      <div className='row g-9 mb-8'>
        {/* Col */}
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-bold mb-2'>Revendeurs</label>
          <select
            {...formik.getFieldProps('apporteur_client_id')}
            className={clsx(
              'form-select form-select-solid select2-hidden-accessible',
              {
                'is-invalid':
                  formik.touched.apporteur_client_id && formik.errors.apporteur_client_id,
              },
              {
                'is-valid':
                  formik.touched.apporteur_client_id && !formik.errors.apporteur_client_id,
              }
            )}
            data-control='select2'
            data-hide-search='true'
            data-placeholder='Selectionner'
            name='apporteur_client_id'
            tabIndex='-1'
            aria-hidden='true'
          >
            <option value=''>Selectionner...</option>
            {revendeurs.map((revendeur) => (
              <option value={revendeur.id} key={revendeur.id}>
                {revendeur.codeCivilite + ' ' + revendeur.nom_user + ' ' + revendeur.prenom_user}
              </option>
            ))}
          </select>
          {formik.touched.apporteur_client_id && formik.errors.apporteur_client_id && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.apporteur_client_id}</div>
            </div>
          )}
        </div>
        {/* End Col */}
      </div>
      {/* End Input group */}
      {/* begin::Action */}
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
        >
          <span className='indicator-label'>Enregistrer</span>
          {formik.isSubmitting && (
            <span className='indicator-progress'>
              Veillez patientez...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

export default ClientAbonnement
