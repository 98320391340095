import {useEffect, useRef, useState} from 'react'
import {
  Space,
  Table,
  Button,
  Alert,
  Modal,
  Popconfirm,
  Tag,
  Select,
  Tooltip,
  Divider,
  Input,
} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addProgrammeTravauxProfessionnel,
  deleteProgrammeTravauxProfessionnel,
  getListeProgrammeTravauxProfessionnel,
  updateEtatProgrammeTravauxProfessionnel,
  updateProgrammeTravauxProfessionnel,
} from '../../../data/params/programme'
import {getListeAssertionActive} from '../../../data/params/assertion'

const sommaires = [
  {
    libelle: 'EG1.1',
    description: "Régularité des réunions du Conseil d'Administration"
  },
  {
    libelle: 'EG1.2',
    description: "Régularité des AGO"
  },
  {
    libelle: 'EG1.3',
    description: "Contrôle du respect du droit de communication"
  },
  {
    libelle: 'EG1.4',
    description: "Contrôle des conventions réglementées"
  },
  {
    libelle: 'EG1.5',
    description: "Contrôle des conventions interdites "
  },
  {
    libelle: 'EG1.6',
    description: "Appréciation du rapport de Gestion"
  },
  {
    libelle: 'EG1.7',
    description: "Contrôle du registre des titres nominatifs"
  },
  {
    libelle: 'EG1.8',
    description: "Contrôle des pactes d'actionnaires"
  },
  {
    libelle: 'EG1.9',
    description: "Procédures d'alerte"
  },
  {
    libelle: 'EG1.10',
    description: "Contrôle des cessions de biens des actionnaires à la société"
  },
  {
    libelle: 'EG2.1',
    description: "Régularité des AGO"
  },
  {
    libelle: 'EG2.2',
    description: "Contrôle du respect du droit de communication"
  },
  {
    libelle: 'EG2.3',
    description: "Contrôle des conventions réglementées"
  },
  {
    libelle: 'EG2.4',
    description: "Contrôle des conventions interdites"
  },
  {
    libelle: 'EG2.5',
    description: "Appréciation du rapport de Gestion"
  },
  {
    libelle: 'EG2.6',
    description: "Contrôle des pactes d'associés"
  },
  {
    libelle: 'EG2.7',
    description: "Procédures d'alerte"
  },
]

const VerificationSASARL = () => {
  const [programmes, setProgrammes] = useState([])
  const [programme, setProgramme] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState([''])
  const [codeSommaire, setCodeSommaire] = useState('')
  const [groupe, setGroupe] = useState('')
  const [groupeProgrammes, setGroupeProgrammes] = useState([])
  const [libellesGroup, setLibellesGroup] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [groupPro, setGroupPro] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [assertions, setAssertions] = useState([])
  const [assertionsSelected, setAssertionsSelected] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    fetchAssertions()
    handleListeProgramme()
  }, [])

  const fetchAssertions = async () => {
    try {
      const res = await getListeAssertionActive()
      if (res.result) {
        setAssertions(res.result)
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des événements risques', error)
    }
  }

  useEffect(() => {
    const libelleGroup = [...new Set(programmes.map((item) => item.groupe))]
    setGroupeProgrammes(libelleGroup)
  }, [programmes])

  useEffect(() => {
    const libelleGroup = [...new Set(programmes.map((item) => item.libelle))]
    setLibellesGroup(libelleGroup)
  }, [programmes])

  const onNameChange = (event) => {
    setGroupPro(event.target.value)
  }

  const onChange = (value) => {
    setGroupe(value)
  }

  const addItem = (e) => {
    e.preventDefault()
    setGroupeProgrammes([...groupeProgrammes, groupPro])
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const handleAddInput = (e) => {
    e.preventDefault()

    setLibelle([...libelle, ''])
  }

  const handleInputChange = (index, value) => {
    const updated = [...libelle]
    updated[index] = value
    setLibelle(updated)
  }

  const handleDeleteInputValue = (index, e) => {
    e.preventDefault()
    const updated = [...libelle]
    updated.splice(index, 1)
    setLibelle(updated)
  }

  const handleChange = (value) => {
    setAssertionsSelected(value)
  }

  const handleFiltered = (search) => {
    const filtered = programmes.filter(
      (item) =>
        item?.diligence?.map((opt) => opt.toLowerCase().includes(search.toLowerCase())) ||
        item.groupe.toLowerCase().includes(search.toLowerCase()) ||
        item.libelle.toLowerCase().includes(search.toLowerCase()) ||
        item.id === parseInt(search)
    )
    setFilteredItems(filtered)
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setLibelle([])
    setCodeSommaire('')
    setGroupe('')
    setAssertionsSelected([])
    setError(false)
  }

  const selectProgramme = (programme_id) => {
    const privil = programmes.filter((periode) => periode.id === programme_id)[0]
    setProgramme(privil)
    setLibelle(privil.diligence)
    setGroupe(privil.groupe)
    setCodeSommaire(privil.libelle)
    setAssertionsSelected(privil.assertions)
    setEdite(true)
    setOpen(true)
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleAddProgramme = async () => {
    setLoading(true)
    const data = {
      diligence: libelle,
      groupe,
      assertions: assertionsSelected,
      libelle: codeSommaire,
    }
    
    const {status, message} = await addProgrammeTravauxProfessionnel(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle([])
      setGroupe('')
      setCodeSommaire('')
      setGroupPro('')
      setAssertionsSelected([])
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierProgramme = async () => {
    setLoading(true)
    const data = {
      programme_id: programme.id,
      diligence: libelle,
      groupe,
      assertions: assertionsSelected,
      libell: codeSommaire,
    }
    
    const {status, message} = await updateProgrammeTravauxProfessionnel(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle([])
      setGroupe('')
      setCodeSommaire('')
      setAssertionsSelected([])
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleListeProgramme = async () => {
    setLoading(true)
    const {result, status, message} = await getListeProgrammeTravauxProfessionnel()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setProgrammes(result)
      setFilteredItems(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteProgramme = async (programme_id) => {
    setLoading(true)
    const data = {programme_id}
    const {status, message} = await deleteProgrammeTravauxProfessionnel(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatProgramme = async (programme_id, etat) => {
    setLoading(true)
    const data = {programme_id, etat}
    const {status, message} = await updateEtatProgrammeTravauxProfessionnel(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle([])
      setGroupe('')
      setCodeSommaire('')
      setAssertionsSelected([])
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: "Secteur d'activité",
      dataIndex: 'groupe',
      key: 'groupe',
      width: 150,
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: "Cycles réalisées par l'auditeur",
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text, record) =>
        record.diligence?.length > 1
          ? record.diligence?.map((opt, indexOpt) => (
              <div key={indexOpt} className='d-flex flex-column'>
                <span className='' style={{fontSize: '12px'}}>
                  <strong className='me-2 fw-bolder' style={{fontSize: '15px'}}>
                    -
                  </strong>
                  {opt}
                </span>
              </div>
            ))
          : record.diligence?.length >= 0
          ? record.diligence?.map((opt, indexOpt) => (
              <span className='' key={indexOpt} style={{fontSize: '12px'}}>
                {opt}
              </span>
            ))
          : '',
    },
    {
      title: 'Assertions',
      dataIndex: 'assertions',
      key: 'assertions',
      render: (_, record) => (
        <div className='d-flex align-center'>
          {record.assertions?.map((assertion, index) => (
            <span className='fw-bolder me-3' key={index}>
              {assertion}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space size='middle'>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => selectProgramme(record.id)}
          />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer la période ${record.diligence[index]} ?`}
              reference={`Voulez vous vraiment activer la période ${record.diligence[index]} ?`}
              onConfirm={() => handleUpdateEtatProgramme(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver la période ${record.diligence[index]} ?`}
              reference={`Voulez vous vraiment désactiver la période ${record.diligence[index]} ?`}
              onConfirm={() => handleUpdateEtatProgramme(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la période ${record.diligence[index]} ?`}
            reference={`Voulez vous vraiment supprimer la période ${record.libelle[index]} ?`}
            onConfirm={() => handleDeleteProgramme(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' reference={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des programmes de travail d'autres Professionnels EG</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={(e) => handleFiltered(e.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <div className='d-flex align-items-center'>
              <div className='fv-row fv-plugins-icon-container me-7'>
                <select
                  className='form-select mb-2 w-350px'
                  data-control='select2'
                  data-placeholder='Select an option'
                  name='payment_method'
                  data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
                  tabIndex='-1'
                  onChange={(e) => {
                    const updateList = programmes.filter((item) => item.groupe === e.target.value)
                    if (updateList && updateList.length > 0) {
                      setFilteredItems(updateList)
                      setGroupPro(e.target.value)
                    } else {
                      setFilteredItems(programmes)
                      setGroupPro(null)
                    }
                  }}
                  value={groupPro}
                >
                  <option data-select2-id='select2-data-11-txbs'>
                    Filtrer par le secteur d'activité...
                  </option>
                  {groupeProgrammes.map((item, index) => (
                    <option value={item} key={index} data-select2-id='select2-data-132-sp74'>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className='fv-row fv-plugins-icon-container me-7'>
                <select
                  className='form-select mb-2 w-350px'
                  data-control='select2'
                  data-placeholder='Select an option'
                  name='payment_method'
                  data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
                  tabIndex='-1'
                  onChange={(e) => {
                    const updateList = programmes.filter((item) => item.codeSommaire === e.target.value)
                    if (updateList && updateList.length > 0) {
                      setFilteredItems(updateList)
                      // setGroupPro(e.target.value)
                    } else {
                      setFilteredItems(programmes)
                      // setGroupPro(null)
                    }
                  }}
                  value={''}
                >
                  <option data-select2-id='select2-data-11-txbs'>
                    Filtrer par le sommaire...
                  </option>
                  {libellesGroup.map((item, index) => (
                    <option value={item} key={index} data-select2-id='select2-data-132-sp74'>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 100}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        width={1000}
        title={!edite ? 'Enregistrer une nouvelle programme' : 'Modifier la programme'}
        onOk={!edite ? handleAddProgramme : handleModifierProgramme}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddProgramme : handleModifierProgramme}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Code Sommaire</label>
          <select
            name='codeSommaire'
            className='form-select w-100 me-15'
            onChange={(e) => {
              setCodeSommaire(e.target.value)
            }}
            value={codeSommaire}
          >
            <option value=''>Selectionner un code...</option>
            {sommaires.map((opt, index) => (
              <option key={index} value={opt.libelle}>
                {`${opt.libelle} - ${opt.description}`}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label className='bold'>Secteur d'activité</label>
          <Select
            // style={{width: 400}}
            placeholder='Critère relatif à '
            onChange={onChange}
            showSearch
            className='form-control mb-5 me-2 w-100'
            optionFilterProp='children'
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{margin: '8px 0'}} />
                <Space style={{padding: '0 8px 4px'}}>
                  <div className='d-flex ajustify-content-between'>
                    <div className='col-lg-8'>
                      <Input
                        placeholder="Entrer un secteur d'activité"
                        ref={inputRef}
                        value={groupPro}
                        onChange={onNameChange}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
                        Ajouter...
                      </Button>
                    </div>
                  </div>
                </Space>
              </>
            )}
            options={
              groupeProgrammes && groupeProgrammes.map((item) => ({label: item, value: item}))
            }
          />
        </p>
        <p>
          <label className='bold'>Diligence</label>
          <div className='d-flex justify-content-between'>
            <div className='col-lg-11 me-5'>
              <div className=' d-flex flex-column mb-2'>
                {libelle?.map((value, index) => (
                  <div className='d-flex align-items-center mb-2' key={index}>
                    <input
                      style={{fontSize: '12px'}}
                      className='form-control w-100 me-3'
                      type='text'
                      value={value}
                      name='libelle'
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                    <Tooltip title='supprimer un point de libellé'>
                      <Button
                        onClick={(e) => handleDeleteInputValue(index, e)}
                        title='Supprimer ?'
                        type='primary'
                        icon={<DeleteOutlined />}
                        danger
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-lg-1'>
              <Tooltip title='Ajouter un point de libellé'>
                <Button className='mb-2 ' onClick={(e) => handleAddInput(e)}>
                  <PlusOutlined style={{color: 'blue'}} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </p>
        <p>
          <label className='bold'>Assertions</label>
          <Select
            mode='tags'
            style={{width: '100%'}}
            placeholder='choisir les assertions'
            onChange={handleChange}
            value={assertionsSelected}
            options={assertions.map((item) => ({
              label: item.libelle,
              value: item.libelle,
            }))}
          />
        </p>
      </Modal>
    </>
  )
}

export default VerificationSASARL


// import {useEffect, useRef, useState} from 'react'
// import {
//   Space,
//   Table,
//   Button,
//   Alert,
//   Modal,
//   Popconfirm,
//   Tag,
//   Select,
//   Tooltip,
//   Divider,
//   Input,
// } from 'antd'
// import {DateComponent} from '../../../components/liste/dateComponent'
// import {
//   DeleteOutlined,
//   DislikeOutlined,
//   EditOutlined,
//   LikeOutlined,
//   PlusOutlined,
// } from '@ant-design/icons'
// import {
//   addProgrammeTravauxProfessionnel,
//   deleteProgrammeTravauxProfessionnel,
//   getListeProgrammeTravauxProfessionnel,
//   updateEtatProgrammeTravauxProfessionnel,
//   updateProgrammeTravauxProfessionnel,
// } from '../../../data/params/programme'
// import {getListeAssertionActive} from '../../../data/params/assertion'

// const sommaires = [
//   {
//     libelle: 'EG1.1',
//     description: "Régularité des réunions du Conseil d'Administration"
//   },
//   {
//     libelle: 'EG1.2',
//     description: "Régularité des AGO"
//   },
//   {
//     libelle: 'EG1.3',
//     description: "Contrôle du respect du droit de communication"
//   },
//   {
//     libelle: 'EG1.4',
//     description: "Contrôle des conventions réglementées"
//   },
//   {
//     libelle: 'EG1.5',
//     description: "Contrôle des conventions interdites "
//   },
//   {
//     libelle: 'EG1.6',
//     description: "Appréciation du rapport de Gestion"
//   },
//   {
//     libelle: 'EG1.7',
//     description: "Contrôle du registre des titres nominatifs"
//   },
//   {
//     libelle: 'EG1.8',
//     description: "Contrôle des pactes d'actionnaires"
//   },
//   {
//     libelle: 'EG1.9',
//     description: "Procédures d'alerte"
//   },
//   {
//     libelle: 'EG1.10',
//     description: "Contrôle des cessions de biens des actionnaires à la société"
//   },
//   {
//     libelle: 'EG2.1',
//     description: "Régularité des AGO"
//   },
//   {
//     libelle: 'EG2.2',
//     description: "Contrôle du respect du droit de communication"
//   },
//   {
//     libelle: 'EG2.3',
//     description: "Contrôle des conventions réglementées"
//   },
//   {
//     libelle: 'EG2.4',
//     description: "Contrôle des conventions interdites"
//   },
//   {
//     libelle: 'EG2.5',
//     description: "Appréciation du rapport de Gestion"
//   },
//   {
//     libelle: 'EG2.6',
//     description: "Contrôle des pactes d'associés"
//   },
//   {
//     libelle: 'EG2.7',
//     description: "Procédures d'alerte"
//   },
// ]

// const VerificationSASARL = () => {
//   const [programmes, setProgrammes] = useState([])
//   const [programme, setProgramme] = useState([])
//   const [error, setError] = useState(false)
//   const [message, setMessage] = useState('')
//   const [libelle, setLibelle] = useState([''])
//   const [codeSommaire, setCodeSommaire] = useState('')
//   const [groupe, setGroupe] = useState('')
//   const [groupeProgrammes, setGroupeProgrammes] = useState([])
//   const [libellesGroup, setLibellesGroup] = useState([])
//   const [filteredItems, setFilteredItems] = useState([])
//   const [groupPro, setGroupPro] = useState('')
//   const [loading, setLoading] = useState(false)
//   const [open, setOpen] = useState(false)
//   const [edite, setEdite] = useState(false)
//   const [assertions, setAssertions] = useState([])
//   const [assertionsSelected, setAssertionsSelected] = useState([])
//   const inputRef = useRef(null)

//   useEffect(() => {
//     fetchAssertions()
//     handleListeProgramme()
//   }, [])

//   const fetchAssertions = async () => {
//     try {
//       const res = await getListeAssertionActive()
//       if (res.result) {
//         setAssertions(res.result)
//       }
//     } catch (error) {
//       console.error('Erreur lors de la récupération des événements risques', error)
//     }
//   }

//   useEffect(() => {
//     const libelleGroup = [...new Set(programmes.map((item) => item.groupe))]
//     setGroupeProgrammes(libelleGroup)
//   }, [programmes])

//   useEffect(() => {
//     const libelleGroup = [...new Set(programmes.map((item) => item.codeSommaire))]
//     setLibellesGroup(libelleGroup)
//   }, [programmes])

//   const onNameChange = (event) => {
//     setGroupPro(event.target.value)
//   }

//   const onChange = (value) => {
//     setGroupe(value)
//   }

//   const addItem = (e) => {
//     e.preventDefault()
//     setGroupeProgrammes([...groupeProgrammes, groupPro])
//     setTimeout(() => {
//       inputRef.current?.focus()
//     }, 0)
//   }

  
//   // const handleAddInput = (e) => {
//   //   e.preventDefault()

//   //   setLibelle([...libelle, ''])
//   // }

//   // const handleInputChange = (index, value) => {
//   //   const updated = [...libelle]
//   //   updated[index] = value
//   //   setLibelle(updated)
//   // }

//   // const handleDeleteInputValue = (index, e) => {
//   //   e.preventDefault()
//   //   const updated = [...libelle]
//   //   updated.splice(index, 1)
//   //   setLibelle(updated)
//   // }

//   const handleChange = (value) => {
//     setAssertionsSelected(value)
//   }

//   const handleFiltered = (search) => {
//     const filtered = programmes.filter(
//       (item) =>
//         item?.libelle?.map((opt) => opt.toLowerCase().includes(search.toLowerCase())) ||
//         item.groupe.toLowerCase().includes(search.toLowerCase()) ||
//         item.codeSommaire.toLowerCase().includes(search.toLowerCase()) ||
//         item.id === parseInt(search)
//     )
//     setFilteredItems(filtered)
//   }

//   const handleCancel = () => {
//     setOpen(false)
//     setEdite(false)
//     setLibelle([])
//     setCodeSommaire('')
//     setGroupe('')
//     setAssertionsSelected([])
//     setError(false)
//   }

//   const selectProgramme = (programme_id) => {
//     const privil = programmes.filter((periode) => periode.id === programme_id)[0]
//     setProgramme(privil)
//     setLibelle(privil.diligence)
//     setGroupe(privil.groupe)
//     setCodeSommaire(privil.codeSommaire)
//     setAssertionsSelected(privil.assertions)
//     setEdite(true)
//     setOpen(true)
//   }

//   const showModal = () => {
//     setOpen(true)
//   }

//   const handleAddProgramme = async () => {
//     setLoading(true)
//     const data = {
//       diligence: libelle,
//       groupe,
//       assertions: assertionsSelected,
//       codeSommaire,
//     }
    
//     const {status, message} = await addProgrammeTravauxProfessionnel(data)
//     setMessage(message)
//     if (status === 'success') {
//       setError(false)
//       setLibelle([])
//       setGroupe('')
//       setCodeSommaire('')
//       setGroupPro('')
//       setAssertionsSelected([])
//       handleListeProgramme()
//       setLoading(false)
//     } else {
//       setError(true)
//       setLoading(false)
//     }
//   }

//   const handleModifierProgramme = async () => {
//     setLoading(true)
//     const data = {
//       programme_id: programme.id,
//       diligence: libelle,
//       groupe,
//       assertions: assertionsSelected,
//       codeSommaire,
//     }
    
//     const {status, message} = await updateProgrammeTravauxProfessionnel(data)
//     setMessage(message)
//     if (status === 'success') {
//       setError(false)
//       setLibelle([])
//       setGroupe('')
//       setCodeSommaire('')
//       setAssertionsSelected([])
//       handleListeProgramme()
//       setLoading(false)
//     } else {
//       setError(true)
//       setLoading(false)
//     }
//   }

//   const handleListeProgramme = async () => {
//     setLoading(true)
//     const {result, status, message} = await getListeProgrammeTravauxProfessionnel()
//     setMessage(message)
//     if (status === 'success') {
//       setError(false)
//       setProgrammes(result)
//       setFilteredItems(result)
//       handleCancel()
//       setLoading(false)
//     } else {
//       setError(true)
//       setLoading(false)
//     }
//   }

//   const handleDeleteProgramme = async (programme_id) => {
//     setLoading(true)
//     const data = {programme_id}
//     const {status, message} = await deleteProgrammeTravauxProfessionnel(data)
//     setMessage(message)
//     if (status === 'success') {
//       setError(false)
//       handleListeProgramme()
//       setLoading(false)
//     } else {
//       setError(true)
//       setLoading(false)
//     }
//   }

//   const handleUpdateEtatProgramme = async (programme_id, etat) => {
//     setLoading(true)
//     const data = {programme_id, etat}
//     const {status, message} = await updateEtatProgrammeTravauxProfessionnel(data)
//     setMessage(message)
//     if (status === 'success') {
//       setError(false)
//       setLibelle([])
//       setGroupe('')
//       setCodeSommaire('')
//       setAssertionsSelected([])
//       handleListeProgramme()
//       setLoading(false)
//     } else {
//       setError(true)
//       setLoading(false)
//     }
//   }

//   const columns = [
//     {
//       title: '#',
//       render: (text, record, index) => (
//         <span className='display-block chart-blue'>{index + 1}</span>
//       ),
//     },
//     {
//       title: '#',
//       dataIndex: 'codeSommaire',
//       key: 'codeSommaire',
//       render: (text, record, index) => (
//         <span className='display-block bold'>{text}</span>
//       ),
//     },
//     {
//       title: "Secteur d'activité ",
//       dataIndex: 'groupe',
//       key: 'groupe',
//       width: 150,
//       render: (text) => <span className='display-block bold'>{text}</span>,
//     },
//     {
//       title: "Diligence",
//       dataIndex: 'libelle',
//       key: 'libelle',
//       render: (text, record) =>(
//         <Input.TextArea
//           size="large"
//           value={record.diligence}
//           maxLength={5000}
//           showCount
//           rows={4}
//           autoSize
//           readOnly
//         />
//       ),
        
//     },
//     {
//       title: 'Assertions',
//       dataIndex: 'assertions',
//       key: 'assertions',
//       render: (_, record) => (
//         <div className='d-flex align-center'>
//           {record.assertions?.map((assertion, index) => (
//             <span className='fw-bolder me-3' key={index}>
//               {assertion}
//             </span>
//           ))}
//         </div>
//       ),
//     },
//     {
//       title: 'Etat',
//       dataIndex: 'etat',
//       key: 'etat',
//       render: (_, record, index) =>
//         record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
//     },
//     {
//       title: 'Créée le / Par',
//       dataIndex: 'createdAt',
//       key: 'createdAt',
//       render: (_, record, index) => (
//         <DateComponent
//           date={record.createdAt}
//           user={record.nom_user + ' ' + record.prenom_user}
//           key={index}
//         />
//       ),
//     },
//     {
//       title: 'Action',
//       key: 'action',
//       render: (_, record, index) => (
//         <Space size='middle'>
//           <Button
//             type='primary'
//             icon={<EditOutlined />}
//             onClick={() => selectProgramme(record.id)}
//           />

//           {record.etat === 0 && (
//             <Popconfirm
//               title={`Activer la période ${record.diligence} ?`}
//               reference={`Voulez vous vraiment activer la période ${record.diligence} ?`}
//               onConfirm={() => handleUpdateEtatProgramme(record.id, 1)}
//               okText='Oui'
//               cancelText='Non'
//             >
//               <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
//             </Popconfirm>
//           )}

//           {record.etat === 1 && (
//             <Popconfirm
//               title={`Désactiver la période ${record.diligence} ?`}
//               reference={`Voulez vous vraiment désactiver la période ${record.diligence} ?`}
//               onConfirm={() => handleUpdateEtatProgramme(record.id, 0)}
//               okText='Oui'
//               cancelText='Non'
//             >
//               <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
//             </Popconfirm>
//           )}

//           <Popconfirm
//             title={`Supprimer la période ${record.diligence} ?`}
//             reference={`Voulez vous vraiment supprimer la période ${record.diligence} ?`}
//             onConfirm={() => handleDeleteProgramme(record.id)}
//             okText='Oui'
//             cancelText='Non'
//           >
//             <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
//           </Popconfirm>
//         </Space>
//       ),
//     },
//   ]

//   return (
//     <>
//       <div className='card mb-5 mb-lg-10'>
//         {error === true && (
//           <Space
//             direction='vertical'
//             style={{
//               width: '100%',
//             }}
//           >
//             <Alert message='Error' reference={message} type='error' showIcon />
//           </Space>
//         )}

//         <div className='card-header'>
//           <div className='card-title'>
//             <h3>Liste des programmes de travaux professionnels EG</h3>
//           </div>

//           <div className='card-toolbar'>
//             <div className='my-1 me-4'>
//               <input
//                 type='text'
//                 name='fname'
//                 onChange={(e) => handleFiltered(e.target.value)}
//                 className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
//                 placeholder='Rechercher'
//               />
//             </div>

//             <div className='d-flex align-items-center'>
//               <div className='fv-row fv-plugins-icon-container me-7'>
//                 <select
//                   className='form-select mb-2 w-350px'
//                   data-control='select2'
//                   data-placeholder='Select an option'
//                   name='payment_method'
//                   data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
//                   tabIndex='-1'
//                   onChange={(e) => {
//                     const updateList = programmes.filter((item) => item.groupe === e.target.value)
//                     if (updateList && updateList.length > 0) {
//                       setFilteredItems(updateList)
//                       setGroupPro(e.target.value)
//                     } else {
//                       setFilteredItems(programmes)
//                       setGroupPro(null)
//                     }
//                   }}
//                   value={groupPro}
//                 >
//                   <option data-select2-id='select2-data-11-txbs'>
//                     Filtrer par le secteur d'activité...
//                   </option>
//                   {groupeProgrammes.map((item, index) => (
//                     <option value={item} key={index} data-select2-id='select2-data-132-sp74'>
//                       {item}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className='fv-row fv-plugins-icon-container me-7'>
//                 <select
//                   className='form-select mb-2 w-350px'
//                   data-control='select2'
//                   data-placeholder='Select an option'
//                   name='payment_method'
//                   data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
//                   tabIndex='-1'
//                   onChange={(e) => {
//                     const updateList = programmes.filter((item) => item.codeSommaire === e.target.value)
//                     if (updateList && updateList.length > 0) {
//                       setFilteredItems(updateList)
//                       // setGroupPro(e.target.value)
//                     } else {
//                       setFilteredItems(programmes)
//                       // setGroupPro(null)
//                     }
//                   }}
//                   value={''}
//                 >
//                   <option data-select2-id='select2-data-11-txbs'>
//                     Filtrer par le code sommaire...
//                   </option>
//                   {libellesGroup.map((item, index) => (
//                     <option value={item} key={index} data-select2-id='select2-data-132-sp74'>
//                       {item}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>
//             <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
//               Ajouter
//             </Button>
//             {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
//           </div>
//         </div>

//         <div className='card-body p-0'>
//           <div className='table-responsive'>
//             <Table
//               loading={loading}
//               columns={columns}
//               dataSource={filteredItems}
//               pagination={{current: 1, pageSize: 100}}
//             />
//           </div>
//         </div>
//       </div>

//       <Modal
//         open={open}
//         width={1000}
//         title={!edite ? 'Enregistrer un nouveau programme' : 'Modifier le programme'}
//         onOk={!edite ? handleAddProgramme : handleModifierProgramme}
//         onCancel={handleCancel}
//         footer={[
//           <Button key='back' onClick={handleCancel}>
//             Annuler
//           </Button>,
//           <Button
//             key='submit'
//             type='primary'
//             loading={loading}
//             onClick={!edite ? handleAddProgramme : handleModifierProgramme}
//           >
//             Enregistrer
//           </Button>,
//         ]}
//       >
//         {error === true && (
//           <Space
//             direction='vertical'
//             style={{
//               width: '100%',
//             }}
//           >
//             <Alert message={message} type='error' showIcon />
//           </Space>
//         )}
//         <p>
//           <label className='bold'>Code Sommaire</label>
//           <select
//             name='codeSommaire'
//             className='form-select w-100 me-15'
//             onChange={(e) => {
//               setCodeSommaire(e.target.value)
//             }}
//             value={codeSommaire}
//           >
//             <option value=''>Selectionner un code...</option>
//             {sommaires.map((opt, index) => (
//               <option key={index} value={opt.libelle}>
//                 {`${opt.libelle} - ${opt.description}`}
//               </option>
//             ))}
//           </select>
//         </p>
//         <p>
//           <label className='bold'>Secteur d'activité </label>
//           <Select
//             // style={{width: 400}}
//             placeholder='Critère relatif à '
//             onChange={onChange}
//             showSearch
//             className='form-control mb-5 me-2 w-100'
//             optionFilterProp='children'
//             // onSearch={onSearch}
//             filterOption={(input, option) =>
//               (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
//             }
//             dropdownRender={(menu) => (
//               <>
//                 {menu}
//                 <Divider style={{margin: '8px 0'}} />
//                 <Space style={{padding: '0 8px 4px'}}>
//                   <div className='d-flex ajustify-content-between'>
//                     <div className='col-lg-8'>
//                       <Input
//                         placeholder="Entrer un secteur d'activité"
//                         ref={inputRef}
//                         value={groupPro}
//                         onChange={onNameChange}
//                       />
//                     </div>
//                     <div className='col-lg-4'>
//                       <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
//                         Ajouter...
//                       </Button>
//                     </div>
//                   </div>
//                 </Space>
//               </>
//             )}
//             options={
//               groupeProgrammes && groupeProgrammes.map((item) => ({label: item, value: item}))
//             }
//           />
//         </p>
//         <p>
//           <label className='bold'>Diligence</label>
//           <div className='d-flex justify-content-between'>
//               <Input.TextArea
//                 size="large"
//                 placeholder="Diagnostic du médecin"
//                 value={libelle}
//                 maxLength={1000}
//                 showCount
//                 rows={4}
//                 onChange={(e) => setLibelle(e.target.value)}
//               />
//           </div>
//         </p>
//         <p>
//           <label className='bold'>Assertions</label>
//           <Select
//             mode='tags'
//             style={{width: '100%'}}
//             placeholder='choisir les assertions'
//             onChange={handleChange}
//             value={assertionsSelected}
//             options={assertions.map((item) => ({
//               label: item.libelle,
//               value: item.libelle,
//             }))}
//           />
//         </p>
//       </Modal>
//     </>
//   )
// }

// export default VerificationSASARL
