import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const UPLOAD_NORMES = `${API_URL}/params/ajouter-normes-isa`
const LISTE_NORMES_ISA = `${API_URL}/params/liste-normes-isa`

export const uploadNormesISA = async (data) => {
  return axios
    .post(UPLOAD_NORMES, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}


export const getListeNormeISA = async () => {
  return axios
    .get(LISTE_NORMES_ISA)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
