import {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag, Select} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addQuestionCycle,
  deleteQuestionCycle,
  updateEtatQuestionCycle,
  updateQuestionCycle,
} from '../../../data/params/questionCycle'
import {getListeQuestionCycle} from '../../../data/params/questionCycle'
import {getListeRubriqueCycle} from '../../../data/params/rubriqueCycle'
import {getListeAssertionActive} from '../../../data/params/assertion'

const QuestionCycle = () => {
  const [questions, setQuestions] = useState([])
  const [assertions, setAssertions] = useState([])
  const [assertionsSelected, setAssertionsSelected] = useState([])
  const [question, setQuestion] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [rubriques, setRubriques] = useState([])
  const [rubriqueCycle_id, setRubriqueCycle_id] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [filteredItems, setFilteredItems] = useState([])
  const [group, setGroup] = useState([])

  useEffect(() => {
    handleListeQuestionCycle()
    handleListeCycle()
    fetchAssertions()
  }, [])

  const fetchAssertions = async () => {
    try {
      const res = await getListeAssertionActive()
      if (res.result) {
        setAssertions(res.result)
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des événements risques', error)
    }
  }


  const handleFiltered = (search) => {
    const filtered = questions.filter(
      (item) =>
      item.Bog_Cycle?.libelle.toLowerCase().includes(search.toLowerCase()) ||
      item.libelle.toLowerCase().includes(search.toLowerCase()) ||
      item.description.toLowerCase().includes(search.toLowerCase()) ||
        item.id === parseInt(search)
    )
    setFilteredItems(filtered)
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleAddCycle = async () => {
    setLoading(true)
    const data = {rubriqueCycle_id, libelle, assertions: assertionsSelected, description: ''}
    console.log(data)
    const {status, message} = await addQuestionCycle(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setRubriqueCycle_id('')
      setAssertionsSelected([])
      await handleListeQuestionCycle()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierCycle = async () => {
    setLoading(true)
    const data = {
      rubriqueCycle_id,
      question_id: question.id,
      libelle,
      assertions: assertionsSelected,
      description: '',
    }
    const {status, message} = await updateQuestionCycle(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setRubriqueCycle_id('')
      setAssertionsSelected([])
      await handleListeQuestionCycle()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setLibelle('')
    setRubriqueCycle_id('')
    setDescription('')
    setAssertionsSelected([])
    setError(false)
  }

  const selectCycle = (question_id) => {
    const privil = questions.filter((periode) => periode.id === question_id)[0]
    setQuestion(privil)
    setLibelle(privil.libelle)
    setRubriqueCycle_id(privil.rubriqueCycle_id)
    setDescription(privil.description)
    setEdite(true)
    setOpen(true)
  }

  const handleListeCycle = async () => {
    setLoading(true)
    const {result, status, message} = await getListeRubriqueCycle()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setRubriques(result)
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleListeQuestionCycle = async () => {
    setLoading(true)
    const {result, status, message} = await getListeQuestionCycle()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setQuestions(result)
      setFilteredItems(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteCycle = async (rubriqueCycle_id) => {
    setLoading(true)
    const data = {rubriqueCycle_id}
    const {status, message} = await deleteQuestionCycle(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeQuestionCycle()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatCycle = async (rubriqueCycle_id, etat) => {
    setLoading(true)
    const data = {rubriqueCycle_id, etat}
    const {status, message} = await updateEtatQuestionCycle(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      await handleListeQuestionCycle()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleChange = (value) => {
    setAssertionsSelected(value)
  }

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Intitule cycle',
      dataIndex: 'cycle',
      key: 'rubriqueCycle_id',
      render: (_, record) => (
        <span className='display-block bold'>{record.Bog_RubriqueCycle?.libelle}</span>
      ),
    },
    {
      title: 'Libelle question',
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Assertions',
      dataIndex: 'assertions',
      key: 'assertions',
      render: (_, record) =>
        record.assertions.map((assertion, index) => (
          <div className='d-flex align-center' key={index}>
            <span className='display-block bold'>{assertion}</span>
          </div>
        )),
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.User?.nom_user + ' ' + record.User?.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button type='primary' icon={<EditOutlined />} onClick={() => selectCycle(record.id)} />
          {record.etat === 0 && (
            <Popconfirm
              title={`Activer la période ${record.libelle} ?`}
              description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
              onConfirm={() => handleUpdateEtatCycle(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver la période ${record.libelle} ?`}
              description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
              onConfirm={() => handleUpdateEtatCycle(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la période ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
            onConfirm={() => handleDeleteCycle(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des questions</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
            <input
                type='text'
                name='fname'
                onChange={(e) => handleFiltered(e.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <div className='d-flex align-items-center'>
              <div className='fv-row fv-plugins-icon-container me-7'>
                <select
                  className='form-select mb-2 w-350px'
                  data-control='select2'
                  data-placeholder='Select an option'
                  name='payment_method'
                  data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
                  tabIndex='-1'
                  onChange={(e) => {
                    const updateList = questions.filter(
                      (item) => parseInt(item.rubriqueCycle_id) === parseInt(e.target.value)
                    )
                    if (updateList && updateList.length > 0) {
                      setFilteredItems(updateList)
                      setGroup(e.target.value)
                    } else {
                      setFilteredItems(questions)
                      setGroup(null)
                    }
                  }}
                  value={group}
                >
                  <option data-select2-id='select2-data-11-txbs'>
                    Filtrer par la rubrique du cycle...
                  </option>
                  {rubriques.map((item, index) => (
                    <option value={item.id} key={index} data-select2-id='select2-data-132-sp74'>
                      {item.libelle}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer une nouvelle question' : 'Modifier la question'}
        onOk={!edite ? handleAddCycle : handleModifierCycle}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddCycle : handleModifierCycle}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Rubrique de cycles</label>
          <select
            name='rubriqueCycle_id'
            className='form-select w-100 me-15'
            onChange={(e) => {
              setRubriqueCycle_id(parseInt(e.target.value))
            }}
            value={rubriqueCycle_id}
          >
            <option value=''>Selectionner une rubrique de cycle...</option>
            {rubriques.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.libelle}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
        <p>
          <label className='bold'>Assertions</label>
          <Select
            mode='tags'
            style={{width: '100%'}}
            placeholder='choisir les assertions'
            onChange={handleChange}
            options={assertions.map((item) => ({
              label: item.libelle,
              value: item.libelle,
            }))}
          />
        </p>
      </Modal>
    </>
  )
}

export default QuestionCycle
