import axios from 'axios'
import {
    AJOUTER_FORMULE_ABONNEMENT,
    LISTE_FORMULE_ABONNEMENT,
    MODIFIER_ETAT_FORMULE_ABONNEMENT,
    MODIFIER_FORMULE_ABONNEMENT,
    SUPPRIMER_FORMULE_ABONNEMENT,
} from '../../api/apiRoute'

export const addFormule = async (data) => {
  return axios
    .post(AJOUTER_FORMULE_ABONNEMENT, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateFormule = async (data) => {
  return axios
    .post(MODIFIER_FORMULE_ABONNEMENT, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeFormule = async () => {
  return axios
    .get(LISTE_FORMULE_ABONNEMENT)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteFormule = async (data) => {
  return axios
    .post(SUPPRIMER_FORMULE_ABONNEMENT, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatFormule = async (data) => {
  return axios
    .post(MODIFIER_ETAT_FORMULE_ABONNEMENT, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
