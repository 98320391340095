import axios from 'axios'
import { AJOUTER_UNITE, LISTE_UNITE, LISTE_UNITE_ACTIVE, MODIFIER_ETAT_UNITE, MODIFIER_UNITE, SUPPRIMER_UNITE } from '../../api/apiRoute'

export const addUnite = async (data) => {
    return axios.post(AJOUTER_UNITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateUnite = async (data) => {
    return axios.post(MODIFIER_UNITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeUnite = async () => {
    return axios.get(LISTE_UNITE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const getListeUniteActive = async () => {
    return axios.get(LISTE_UNITE_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteUnite = async (data) => {
    return axios.post(SUPPRIMER_UNITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatUnite = async (data) => {
    return axios.post(MODIFIER_ETAT_UNITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}