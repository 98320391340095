import axios from 'axios'
import {
  AJOUTER_PARAMS_GLOBAUX,
  LISTE_PARAMS_GLOBAUX,
  MODIFIER_ETAT_PARAMS_GLOBAUX,
  MODIFIER_PARAMS_GLOBAUX,
  SUPPRIMER_PARAMS_GLOBAUX,
} from '../../api/apiRoute'

export const addParams = async (data) => {
  return axios
    .post(AJOUTER_PARAMS_GLOBAUX, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateParams = async (data) => {
  return axios
    .post(MODIFIER_PARAMS_GLOBAUX, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeParams = async () => {
  return axios
    .get(LISTE_PARAMS_GLOBAUX)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteParams = async (data) => {
  return axios
    .post(SUPPRIMER_PARAMS_GLOBAUX, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatParams = async (data) => {
  return axios
    .post(MODIFIER_ETAT_PARAMS_GLOBAUX, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
