import React from 'react'
import {Typography, Row, Col} from 'antd'
import 'antd/dist/antd.css'
import {useLocation} from 'react-router-dom'

const {Title, Text} = Typography

const ClientInfo = () => {
  let location = useLocation()
  const client = location.state.record
  return (
    <div className='ant-spin-nested-loading'>
      <div className='ant-spin-container'>
        <div id='top_bar' className='top-bar'>
          <Title level={2}>Fiche du Client</Title>
        </div>
        <div className='info-container'>
          <div className='info-section'>
            <div className='info-heading'>Informations du client</div>
            <div className='info-content'>
              <Row gutter={20}>
                <Col span={12}>
                  <p>
                    <Text strong>Nom du client:</Text>
                    <br />
                    <Text>{client?.nom_cabinet}</Text>
                  </p>
                  <p>
                    <Text strong>Status juridique:</Text>
                    <br />
                    <Text>{client?.libelle_formule}</Text>
                  </p>
                  <p>
                    <Text strong>Secteur d'activité:</Text>
                    <br />
                    <Text>{client?.libelle_secteur}</Text>
                  </p>
                  <p>
                    <Text strong>Localisation:</Text>
                    <br />
                    <Text>{client?.localisation}</Text>
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <Text strong>Lieu de naissance:</Text>
                    <br />
                    <Text>Ivoirienne</Text>
                  </p>
                  <p>
                    <Text strong>Lieu d'habitation:</Text>
                    <br />
                    <Text></Text>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div className='info-section'>
            <div className='info-heading'>Informations Professionnelles</div>
            <div className='info-content'>
              <Row gutter={30}>
                <Col span={12}>
                  <p>
                    <Text strong>Matricule:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Fonction:</Text>
                    <br />
                    <Text>Accueil</Text>
                  </p>
                  <p>
                    <Text strong>Date d'entrée:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Service:</Text>
                    <br />
                    <Text>SERVICE ADMINISTRATIF</Text>
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <Text strong>Groupe:</Text>
                    <br />
                    <Text>CAISSIERS</Text>
                  </p>
                  <p>
                    <Text strong>Spécialité:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Catégorie:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Type de contrat:</Text>
                    <br />
                    <Text>CDD</Text>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div className='info-section'>
            <div className='info-heading'>Contacts et Infos d'urgences</div>
            <div className='info-content'>
              <Row gutter={20}>
                <Col span={12}>
                  <p>
                    <Text strong>Cellulaire 1:</Text>
                    <br />
                    <Text>0777873294</Text>
                  </p>
                  <p>
                    <Text strong>Cellulaire 2:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Numéro Fixe:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Adresse E-mail:</Text>
                    <br />
                    <Text></Text>
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <Text strong>Personne à contacter en cas d'urgence:</Text>
                    <br />
                    <Text>Dossou</Text>
                  </p>
                  <p>
                    <Text strong>Lien de parenté:</Text>
                    <br />
                    <Text></Text>
                  </p>
                  <p>
                    <Text strong>Cellulaire urgence 1:</Text>
                    <br />
                    <Text>0585984760</Text>
                  </p>
                  <p>
                    <Text strong>Cellulaire urgence 2:</Text>
                    <br />
                    <Text></Text>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientInfo
