import React, {useEffect, useState} from 'react'
import {Space, Table, Alert, Tag} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {getListePays} from '../../../data/params/pays'

const PaysPage = () => {
  const [pays, setPays] = useState([])
  const [serchDesc, setSerchDesc] = useState('')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Code Alpha',
      dataIndex: 'alpha2',
      key: 'alpha2',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Nom',
      dataIndex: 'nom_fr_fr',
      key: 'nom_fr_fr',
    },
    {
      title: 'Etat',
      dataIndex: 'actif',
      key: 'actif',
      render: (_, record, index) =>
        record.actif === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    // {
    //   title: 'Créée le / Par',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (_, record, index) => <DateComponent date={record.createdAt} key={index} />,
    // },
  ]

  const handleListeCivilite = async () => {
    setLoading(true)
    const {result, status, message} = await getListePays()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setPays(result)
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const filteredItems = pays.filter(
    (item) =>
      item.alpha2.toLowerCase().includes(serchDesc.toLowerCase()) ||
      item.nom_fr_fr.toLowerCase().includes(serchDesc.toLowerCase()) ||
      item.id === parseInt(serchDesc)
  )
  const handleClear = () => {
    if (serchDesc) {
      setSerchDesc('')
    }
  }

  useEffect(() => {
    handleListeCivilite()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' nom_fr={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Civilités enregistrées</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='search'
                name='fname'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
                onChange={(e) => setSerchDesc(e.target.value)}
                onClear={handleClear}
                value={serchDesc}
              />
            </div>
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table loading={loading} columns={columns} dataSource={filteredItems} pagination= { {current: 1, pageSize: 50} } />
          </div>
        </div>
      </div>
    </>
  )
}

export default PaysPage
