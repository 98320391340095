import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {addClient} from '../../core/clients/_requests'
import {getListeSecteur} from '../../data/params/secteur'
import {getListeStatut} from '../../data/params/statut'
import {getListeCivilite} from '../../data/params/civilite'

function ClientCreate() {
  // const history = useHistory()
  const [error, setError] = useState({})
  const [secteurs, setSecteurs] = useState([])
  const [statuts, setStatuts] = useState([])
  const [civilites, setCivilites] = useState([])
  const navigate = useNavigate()

  // form validation rules
  const ClientDetailSchema = Yup.object().shape({
    nom_cabinet: Yup.string().required('Le nom du cabinet est requis'),
    secteur_id: Yup.number(),
    statut_id: Yup.number().required('Le status juridique est requis'),
    civilite_id: Yup.number().required('La civilité du gérant est requise'),
    numero_cc: Yup.string(),
    numero_rcc: Yup.string(),
    localisation: Yup.string().required('La localisation est requise'),
    cel1: Yup.string().required('Le numero de telephone est requis'),
    cel2: Yup.string().required('Le numero de telephone du SMS est requis'),
    email: Yup.string()
      .email('Mauvais format de email')
      .min(8, 'Minimum 8 symbols')
      .max(50, 'Maximum 50 symbols'),
    nom_gerant: Yup.string().required('Le nom du gerant est requis'),
    telephone_gerant: Yup.string().required('Le numero de telephone du gerant est requis'),
  })

  const CLientForEdit = {
    nom_cabinet: '',
    nom_gerant: '',
    telephone_gerant: '',
    fonction_gerant: '',
    secteur_id: 0,
    statut_id: 0,
    civilite_id: 0,
    numero_cc: '',
    numero_rcc: '',
    capital_social: 0,
    // nbr_dossier: 0,
    localisation: '',
    cel1: '',
    cel2: '',
    cel_message: '',
    email: '',
  }

  // get list secteurs
  const handleListeSecteur = async () => {
    const {result, status, message} = await getListeSecteur()
    if (status === 'success') {
      setError(false)
      setSecteurs(result)
    } else {
      setError(true)
    }
  }

  const handleListeStatut = async () => {
    const {result, status, message} = await getListeStatut()
    if (status === 'success') {
      setError(false)
      setStatuts(result)
    } else {
      setError(true)
    }
  }

  const handleGetCivilite = async () => {
    const {result} = await getListeCivilite()
    setCivilites(result)
  }

  useEffect(() => {
    handleListeStatut()
    handleListeSecteur()
    handleGetCivilite()
  }, [])

  // get all showrooms list

  const formik = useFormik({
    initialValues: CLientForEdit,
    validationSchema: ClientDetailSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        const {message, status} = await addClient(values)
        if (status !== 'success') {
          setStatus(message)
        }
        navigate('/apps/clients/liste')
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Information administrative sur le client</h3>
        </div>
      </div>
      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/*Nom client*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                <span className='required'>Nom du client</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('nom_cabinet')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.nom_cabinet && formik.errors.nom_cabinet},
                    {
                      'is-valid': formik.touched.nom_cabinet && !formik.errors.nom_cabinet,
                    }
                  )}
                  placeholder='Nom client'
                  {...formik.getFieldProps('nom_cabinet')}
                />
                {formik.touched.nom_cabinet && formik.errors.nom_cabinet && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.nom_cabinet}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Status juridique*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Status juridique</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  {...formik.getFieldProps('statut_id')}
                  className={clsx(
                    'form-select form-select-solid form-select-lg fw-bold',
                    {'is-invalid': formik.touched.statut_id && formik.errors.statut_id},
                    {
                      'is-valid': formik.touched.statut_id && !formik.errors.statut_id,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                >
                  <option value=''>Selectionner...</option>
                  {statuts.map((statut) => (
                    <option value={statut.id} key={statut.id}>
                      {statut.libelle}
                    </option>
                  ))}
                </select>
                {formik.touched.statut_id && formik.errors.statut_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.statut_id}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Secteur activite*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Secteur d'activité</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  {...formik.getFieldProps('secteur_id')}
                  className={clsx(
                    'form-select form-select-solid form-select-lg fw-bold',
                    {'is-invalid': formik.touched.secteur_id && formik.errors.secteur_id},
                    {
                      'is-valid': formik.touched.secteur_id && !formik.errors.secteur_id,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                >
                  <option value=''>Selectionner...</option>
                  {secteurs.map((secteur) => (
                    <option value={secteur.id} key={secteur.id}>
                      {secteur.libelle}
                    </option>
                  ))}
                </select>
                {formik.touched.secteur_id && formik.errors.secteur_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.secteur_id}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Numero CC*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Numéro CC</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.numero_cc && formik.errors.numero_cc,
                    },
                    {
                      'is-valid': formik.touched.numero_cc && !formik.errors.numero_cc,
                    }
                  )}
                  placeholder='Numero CC'
                  {...formik.getFieldProps('numero_cc')}
                />
                {formik.touched.numero_cc && formik.errors.numero_cc && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.numero_cc}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Numero RCC*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Numéro RCC</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.numero_rcc && formik.errors.numero_rcc,
                    },
                    {
                      'is-valid': formik.touched.numero_rcc && !formik.errors.numero_rcc,
                    }
                  )}
                  placeholder='Numero RCC'
                  {...formik.getFieldProps('numero_rcc')}
                />
                {formik.touched.numero_rcc && formik.errors.numero_rcc && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.numero_rcc}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Capital social*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Capital Social</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.capital_social && formik.errors.capital_social,
                    },
                    {
                      'is-valid': formik.touched.capital_social && !formik.errors.capital_social,
                    }
                  )}
                  placeholder='Login'
                  {...formik.getFieldProps('capital_social')}
                />
                {formik.touched.capital_social && formik.errors.capital_social && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.capital_social}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Loclisation*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                <span className='required'>Localisation</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps('localisation')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.localisation && formik.errors.localisation},
                    {
                      'is-valid': formik.touched.localisation && !formik.errors.localisation,
                    }
                  )}
                  placeholder='Localisation'
                />
                {formik.touched.localisation && formik.errors.localisation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.localisation}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Email*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.email && formik.errors.email,
                    },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Telephone 1*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Téléphone </span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.cel1 && formik.errors.cel1,
                    },
                    {
                      'is-valid': formik.touched.cel1 && !formik.errors.cel1,
                    }
                  )}
                  placeholder='Téléphone '
                  {...formik.getFieldProps('cel1')}
                />
                {formik.touched.cel1 && formik.errors.cel1 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cel1}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Telephone 2*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>N° Cel pour SMS</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Téléphone message'
                  {...formik.getFieldProps('cel2')}
                />
                {formik.touched.cel2 && formik.errors.cel2 && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cel2}</div>
                  </div>
                )}
              </div>
            </div>
            {/*Nom gerant*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Nom complet gerant
              </label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-4 fv-row'>
                    <select
                      {...formik.getFieldProps('civilite_id')}
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-bold',
                        {'is-invalid': formik.touched.civilite_id && formik.errors.civilite_id},
                        {
                          'is-valid': formik.touched.civilite_id && !formik.errors.civilite_id,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    >
                      <option value=''>Selectionner...</option>
                      {civilites.map((civilite) => (
                        <option value={civilite.id} key={civilite.id}>
                          {civilite.libelleCivilite}
                        </option>
                      ))}
                    </select>
                    {formik.touched.civilite_id && formik.errors.civilite_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.civilite_id}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.nom_gerant && formik.errors.nom_gerant,
                        },
                        {
                          'is-valid': formik.touched.nom_gerant && !formik.errors.nom_gerant,
                        }
                      )}
                      placeholder='Nom du gerant'
                      {...formik.getFieldProps('nom_gerant')}
                    />
                    {formik.touched.nom_gerant && formik.errors.nom_gerant && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.nom_gerant}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*Fonction gerant*/}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Fonction</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Fonction'
                  {...formik.getFieldProps('fonction_gerant')}
                />
                {formik.touched.fonction_gerant && formik.errors.fonction_gerant && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.fonction_gerant}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Téléphone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telephone gerant'
                  {...formik.getFieldProps('telephone_gerant')}
                />
                {formik.touched.telephone_gerant && formik.errors.telephone_gerant && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.telephone_gerant}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Enregistrer</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Veillez patientez...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ClientCreate
