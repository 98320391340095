/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {login} from '../../core/auth/_requests'
import {useAuth} from '../../core/auth'
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons'

const loginSchema = Yup.object().shape({
  login: Yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Le login est requis'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Le mot de passe est requis'),
})

const initialValues = {
  login: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [passwordVisible, setPasswordVisible] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.login, values.password)
        if (auth.status === 'success') {
          saveAuth(auth)
          setCurrentUser(auth.result)
        } else {
          console.log(auth.message)
          setStatus('Les informations de connexion sont incorrectes')
          setSubmitting(false)
          setLoading(false)
          saveAuth(undefined)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Un problème est survenu! Veillez réessayer!')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Connexion</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Login</label>
        <input
          placeholder='Login'
          {...formik.getFieldProps('login')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.login && formik.errors.login},
            {
              'is-valid': formik.touched.login && !formik.errors.login,
            }
          )}
          type='text'
          name='login'
          autoComplete='off'
        />
        {formik.touched.login && formik.errors.login && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.login}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
          </div>
        </div>
        <input
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          type={passwordVisible ? 'text' : 'password'}
          name='password'
          autoComplete='off'
        />
        <span
          onClick={() => setPasswordVisible(!passwordVisible)}
          className='password-toggle-button'
          style={{
            background: 'none',
            border: 'none',
            color: '#FA981B',
            cursor: 'pointer',
            position: 'absolute',
            top: '54%',
            right: '18%',
            transform: 'translateY(-50%)',
          }}
        >
          {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </span>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-active-light-success w-100 mb-5'
          style={{backgroundColor: '#d1ec51'}}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Patientez svp...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        <div className='text-center text-muted text-uppercase fw-bolder mb-5'></div>
        {/* end::Separator */}
      </div>
      {/* end::Action */}
    </form>
  )
}
