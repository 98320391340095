import axios from 'axios'
import {
  CREATE_CATEGORIE_URL,
  UPDATE_CATEGORIE_URL,
  MODIFIER_CIVILITE,
  SUPPRIMER_CIVILITE,
  GET_CATEGORIE_URL,
  DELETE_CATEGORIE_URL,
  MODIFIER_ETAT_CATEGORIE,
} from '../../api/apiRoute'
import {MODIFIER_ETAT_CIVILITE} from '../../api/apiRoute'

export const addCategorie = async (data) => {
  return axios
    .post(CREATE_CATEGORIE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateCategorie = async (data) => {
  return axios
    .post(UPDATE_CATEGORIE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeCategorie = async () => {
  return axios
    .get(GET_CATEGORIE_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteCategorie = async (data) => {
  return axios
    .post(DELETE_CATEGORIE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatCategorie = async (data) => {
  return axios
    .post(MODIFIER_ETAT_CATEGORIE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
