import axios from 'axios'
import { AJOUTER_PERIODE, LISTE_PERIODE, MODIFIER_ETAT_PERIODE, MODIFIER_PERIODE, SUPPRIMER_PERIODE } from '../../api/apiRoute'

export const addPeriode = async (data) => {
    return axios.post(AJOUTER_PERIODE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updatePeriode = async (data) => {
    return axios.post(MODIFIER_PERIODE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListePeriode = async () => {
    return axios.get(LISTE_PERIODE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deletePeriode = async (data) => {
    return axios.post(SUPPRIMER_PERIODE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatPeriode = async (data) => {
    return axios.post(MODIFIER_ETAT_PERIODE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}