import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_FORMULE_ABON_URL = `${API_URL}/client/add-client`
const EDITED_FORMULE_ABON_URL = `${API_URL}/client/modifier-client`
const GET_FORMULE_ABON_BY_ID = `${API_URL}/user/select-user`
const GET_FORMULE_ABONS_URL = `${API_URL}/params/liste-abonnement-active`
const DELETE_FORMULE_ABONS_URL = `${API_URL}/client/supprimer-client`
const UPDATE_FORMULE_ABONS_STATE_URL = `${API_URL}/client/modifier-etat-client`

export const addFormuleSouscription = async (data) => {
  return axios
    .post(CREATE_FORMULE_ABON_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const ModifierFormuleSouscription = async (data) => {
  return axios
    .post(EDITED_FORMULE_ABON_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getFormuleSouscriptions = async () => {
  return axios
    .get(GET_FORMULE_ABONS_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getFormuleSouscriptionById = async (id) => {
  return axios
    .get(GET_FORMULE_ABON_BY_ID, id)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteFormuleSouscription = async (data) => {
  return axios
    .post(DELETE_FORMULE_ABONS_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatFormuleSouscription = async (data) => {
  return axios
    .post(UPDATE_FORMULE_ABONS_STATE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
