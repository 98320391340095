/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import {useAuth} from '../../../../core/auth'
import clsx from 'clsx'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
            <a href='/apps/profil/vue'>
              {currentUser?.avatar ? (
                <div className='symbol-label'>
                  <img
                    src={toAbsoluteUrl(`/media/${currentUser.avatar}`)}
                    alt={currentUser.nom_user + ' ' + currentUser.prenom_user}
                    className='w-100'
                  />
                </div>
              ) : currentUser?.codeCivilite === 'M.' ? (
                <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                  <img
                    src={toAbsoluteUrl('/media/avatars/male.jpg')}
                    alt={currentUser.nom_user + ' ' + currentUser.prenom_user}
                    className='w-100'
                  />
                </div>
              ) : (
                <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                  <img
                    src={toAbsoluteUrl('/media/avatars/female.jpg')}
                    alt={currentUser?.nom_user + ' ' + currentUser?.prenom_user}
                    className='w-100'
                  />
                </div>
              )}
            </a>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.nom_user} {currentUser?.prenom_user}
              {/*<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>*/}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email_user}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link
          to={`/apps/profil/vue/${currentUser?.id}}`}
          state={{currentUser}}
          className='menu-link px-5'
        >
          Mon Profil
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Se deconnecter
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
