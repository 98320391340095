import axios from 'axios'
import {
  CREATE_PROFIL_URL,
  DELETE_PROFILS_URL,
  GET_PROFILS_URL,
  MODIFIER_ETAT_PROFIL,
  UPDATE_PROFIL_URL,
} from '../../api/apiRoute'

export const addProfil = async (data) => {
  return axios
    .post(CREATE_PROFIL_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateProfil = async (data) => {
  return axios
    .post(UPDATE_PROFIL_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProfil = async () => {
  return axios
    .get(GET_PROFILS_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteProfil = async (data) => {
  return axios
    .post(DELETE_PROFILS_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatProfil = async (data) => {
  return axios
    .post(MODIFIER_ETAT_PROFIL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
