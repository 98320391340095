import React, {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag} from 'antd'
import {DateComponent} from '../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  FileDoneOutlined,
  LikeOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import {addBanque, deleteBanque, updateBanque, updateEtatBanque} from '../../data/params/banque'
import {getListePaiement} from '../../data/facturation/paiement'
import {Link} from 'react-router-dom'

const Paiement = () => {
  const [paiements, setPaiement] = useState([])
  const [banque, setBanque] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Nom du cabinet',
      dataIndex: 'nom_cabinet',
      key: 'nom_cabinet',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Libelle de formule',
      dataIndex: 'libelle_formule',
      key: 'libelle_formule',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Montant ttc',
      dataIndex: 'montant_ttc',
      key: 'montant_ttc',
      render: (text) => (
        <span className='display-block bold'>{parseInt(text).toLocaleString('fr') + ' FCFA'}</span>
      ),
    },

    {
      title: 'Montant payé',
      dataIndex: 'net_a_payer',
      key: 'net_a_payer',
      render: (_, record, index) => (
        <div className='text-end fw-bolder fs-6 text-gray-800'>
          {' '}
          {parseInt(record?.montant_paye).toLocaleString('fr') + ' FCFA'}
        </div>
      ),
    },

    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Link
            className='menu-link px-5'
            to={`/apps/clients/reglement/print/${record.id}`}
            state={{record}}
          >
            {<PrinterOutlined style={{fontSize: '20px'}} />}
            <span className='indicator-label' style={{paddingLeft: '15px'}}></span>
          </Link>
        </Space>
      ),
    },
  ]

  const filteredItems = paiements.filter(
    (item) =>
      item.libelle_formule.toLowerCase().includes(search.toLowerCase()) ||
      item.libelle_categorie.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
  )

  const showModal = () => {
    setOpen(true)
  }

  const handleAddBanque = async () => {
    setLoading(true)
    const data = {libelle, description}
    console.log(data)
    const {status, message} = await addBanque(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      handleListePaiement()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierBanque = async () => {
    setLoading(true)
    const data = {banque_id: banque.id, libelle, description}
    console.log(data)
    const {status, message} = await updateBanque(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      handleListePaiement()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setLibelle('')
    setDescription('')
    setError(false)
  }

  const selectBanque = (banque_id) => {
    const privil = paiements.filter((periode) => periode.id === banque_id)[0]
    setBanque(privil)
    setLibelle(privil.libelle)
    setDescription(privil.description)
    setEdite(true)
    setOpen(true)
  }

  const handleListePaiement = async () => {
    setLoading(true)
    const {result, status, message} = await getListePaiement()
    setMessage(message)
    if (status === 'success') {
      console.log(result)
      setError(false)
      setPaiement(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteBanque = async (banque_id) => {
    setLoading(true)
    const data = {banque_id}
    const {status, message} = await deleteBanque(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListePaiement()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatBanque = async (banque_id, etat) => {
    setLoading(true)
    const data = {banque_id, etat}
    const {status, message} = await updateEtatBanque(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      handleListePaiement()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  // const handleSearchDesCivilite = () => {
  //   const civs = paiements.filter(civilite => civilite.id == )

  // }

  useEffect(() => {
    handleListePaiement()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des paiements</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              {/* <input type="text" name="fname" onChange={e => setSearch(e.target.value)} class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Rechercher" /> */}
            </div>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer une nouvelle banque' : 'Modifier la banque'}
        onOk={!edite ? handleAddBanque : handleModifierBanque}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddBanque : handleModifierBanque}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            class='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
        <p>
          <label className='bold'>Description</label>
          <input
            type='text'
            name='fname'
            class='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder='Entrez la description'
          />
        </p>
      </Modal>
    </>
  )
}

export default Paiement
