import axios from 'axios'
import {
    AJOUTER_ASSERTION,
    LISTE_ASSERTION, LISTE_ASSERTION_ACTIVE,  
    MODIFIER_ASSERTION,
    MODIFIER_ETAT_ASSERTION,  SUPPRIMER_ASSERTION
} from '../../api/apiRoute'

export const addAssertion = async (data) => {
    return axios.post(AJOUTER_ASSERTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateAssertion = async (data) => {
    return axios.post(MODIFIER_ASSERTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeAssertion = async () => {
    return axios.get(LISTE_ASSERTION).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeAssertionActive = async () => {
    return axios.get(LISTE_ASSERTION_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteAssertion = async (data) => {
    return axios.post(SUPPRIMER_ASSERTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatAssertion = async (data) => {
    return axios.post(MODIFIER_ETAT_ASSERTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}