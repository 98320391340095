import React, {useEffect, useRef} from 'react'
import {Chart} from 'chart.js'

const CamembertChart1 = (className, innerPadding = '') => {
  const chartRef = useRef(null)

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')
    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Attente', 'Teminées'],
        datasets: [
          {
            data: [15, 65],
            backgroundColor: ['#d1ec51', '#5D78FF'],
            hoverBackgroundColor: ['#d1ec51', '#5D78FF'],
          },
        ],
      },
      options: {
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    })

    return () => {
      chart.destroy()
    }
  }, [])

  return (
    <div className={`card ${className}`}>
      {/*begin::Header*/}
      <div className='card-header pt-2'>
        {/*begin::Title*/}
        <div className='card-title d-flex flex-column'>
          {/*begin::Info*/}
          <div className='d-flex align-items-center'>
            {/*begin::Amount*/}
            <span className='fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2'>Factures</span>
            {/*end::Amount*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Title*/}
      </div>
      {/*end::Header*/}
      {/*begin::Card body*/}
      <div className='card-body pt-2 pb-4 d-flex align-items-center'>
        {/*begin::Chart*/}
        <div className='d-flex flex-center me-2 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            style={{minWidth: '30px', minHeight: '35px'}}
            data-kt-size='60'
            data-kt-line='11'
          >
            <canvas ref={chartRef} height='auto' width='150px'></canvas>
          </div>
        </div>
        {/*end::Chart*/}
        {/*begin::Labels*/}
        <div className='d-flex flex-column content-justify-center w-30'>
          {/*begin::Label*/}
          <div className='d-flex fw-bold align-items-center'>
            {/*begin::Bullet*/}
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            {/*end::Bullet*/}
            {/*begin::Label*/}
            <div className='text-gray-500 flex-grow-1 me-4'>En attente</div>
            {/*end::Label*/}
            {/*begin::Stats*/}
            <div className='fw-boldest text-gray-700 text-xxl-end'>15</div>
            {/*end::Stats*/}
          </div>
          {/*end::Label*/}
          {/*begin::Label*/}
          <div className='d-flex fw-bold align-items-center my-3'>
            {/*begin::Bullet*/}
            <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
            {/*end::Bullet*/}
            {/*begin::Label*/}
            <div className='text-gray-500 flex-grow-1 me-4'>Terminés</div>
            {/*end::Label*/}
            {/*begin::Stats*/}
            <div className='fw-boldest text-gray-700 text-xxl-end'>65</div>
            {/*end::Stats*/}
          </div>
          {/*end::Label*/}
        </div>
        {/*end::Labels*/}
      </div>
      {/*end::Card body*/}
    </div>
  )
}

export default CamembertChart1
