import React, {useEffect, useState} from 'react'
import { Alert, Button, Modal, Popconfirm, Space, Tag } from "antd";
import {toAbsoluteUrl} from '../../helpers'
import { EditOutlined } from "@ant-design/icons";
import { addProfilLea, deleteProfilLea, getListeProfilLea, updateEtatProfilLea, updateProfilLea } from '../../data/params/profilLea';

const ProflLea = () => {
  const [profils, setProfils] = useState([])
  const [profil, setProfil] = useState({})
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [menuVisible, setMenuVisible] = useState(false)

  const handleGetProfil = async () => {
    setLoading(true)
    const {result, status, message} = await getListeProfilLea()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setProfils(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetProfil()
  }, [])

  const handleAddProfil = async () => {
    setLoading(true)
    const data = {libelleProfil: libelle, description: description}
    console.log(data)
    const {status, message} = await addProfilLea(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleGetProfil()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible)
  }

  const handleModifierProfil = async () => {
    setLoading(true)
    const data = {profil_id: profil.id, libelleProfil: libelle, description: description}
    console.log(data)
    const {status, message} = await updateProfilLea(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleGetProfil()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteProfil = async (profil_id) => {
    setLoading(true)
    const data = {profil_id}
    const {status, message} = await deleteProfilLea(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleGetProfil()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateProfil = async (profil_id, etat_profil) => {
    setLoading(true)
    const data = {profil_id, etat_profil}
    const {status, message} = await updateEtatProfilLea(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleGetProfil()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
  }

  const selectProfil = (profil_id) => {
    console.log(profil_id)

    const profil = profils.filter((profil) => profil.id === profil_id)[0]
    setProfil(profil)
    setLibelle(profil.libelleProfil)
    setDescription(profil.description)
    setEdite(true)
    setOpen(true)
  }

  return (
    <>
      {error === true && (
        <Space
          direction='vertical'
          style={{
            width: '100%',
          }}
        >
          <Alert message='Error' description={message} type='error' showIcon />
        </Space>
      )}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/* begin::Container */}
        <div id='kt_content_container' className='container-xxl'>
          {/* begin::Row */}
          <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9'>
            {profils.map((profil) => (
              <>
                <div id={profil.id} key={profil.id} className='col-md-4'>
                  <div  className='card card-flush h-md-100'>
                    {/*begin::Card header*/}
                    <div className='card-header flex-nowrap border-0 pt-9'>
                      {/*begin::Card title*/}
                      <div className='card-title'>
                        <h2>{profil.libelleProfil}</h2>
                      </div>
                      <div className='card-toolbar m-0'>
                        {/*begin::Menu*/}
                        <button
                          type='button'
                          className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3 show menu-dropdown'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          onClick={handleMenuClick}
                        >
                          {/*begin::Svg Icon | path: icons/duotune/general/gen024.svg*/}
                          <span className='svg-icon svg-icon-3 svg-icon-primary'>
                            <img
                              src={toAbsoluteUrl('/media/icons/duotune/general/gen024.svg')}
                              alt=''
                              className='mw-100 mh-150px mb-7'
                            />
                          </span>
                          {/*end::Svg Icon*/}
                        </button>
                        {menuVisible && (
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-150px show'
                            data-kt-menu='true'
                            data-popper-placement='bottom-end'
                            style={{
                              zIndex: 105,
                              position: 'fixed',
                              marginTop: '120px',
                              // inset: '0px 0px auto auto',
                              // margin: '0px',
                              // transform: 'translate(-186px, 271px)',
                            }}
                          >
                            <div className='menu-item px-3'>
                              <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                                Actions
                              </div>
                            </div>

                            {/*<div className='menu-item px-3'>*/}
                            {/*  <a href='#' className='menu-link px-3'>*/}
                            {/*    Create Invoice*/}
                            {/*  </a>*/}
                            {/*</div>*/}

                            <div className='menu-item px-3'>
                              {profil.etat_profil === 0 && (
                                <Popconfirm
                                  title={`Activer la civilité ${profil.libelleProfil} ?`}
                                  onConfirm={() => handleUpdateProfil(profil.id, 1)}
                                  okText='Oui'
                                  cancelText='Non'
                                >
                                  <a href='#' className='menu-link flex-stack px-3'>
                                    Activer
                                  </a>
                                </Popconfirm>
                              )}
                              {profil.etat_profil === 1 && (
                                <Popconfirm
                                  title={`Désactiver la profil ${profil.libelleProfil} ?`}
                                  onConfirm={() => handleUpdateProfil(profil.id, 0)}
                                  okText='Oui'
                                  cancelText='Non'
                                >
                                  <a href='#' className='menu-link flex-stack px-3'>
                                    Désactiver
                                  </a>
                                </Popconfirm>
                              )}
                            </div>

                            <div className='menu-item px-3'>
                              <Popconfirm
                                title={`Supprimer la profil ${profil.libelleProfil} ?`}
                                onConfirm={() => handleDeleteProfil(profil.id)}
                                okText='Oui'
                                cancelText='Non'
                              >
                                <a href='#' className='menu-link flex-stack px-3'>
                                  Supprimer
                                </a>
                              </Popconfirm>
                            </div>
                          </div>
                        )}
                        {/*end::Menu*/}
                      </div>
                      {/*end::Card title*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className='card-body pt-1'>
                      {/*begin::Users*/}
                      <div className='fw-bolder text-gray-600 mb-5'>
                        {profil.etat_profil === 1 ? (
                          <Tag color='green'>Activé</Tag>
                        ) : (
                          <Tag color='red'>Désactivé</Tag>
                        )}
                      </div>
                      {/*end::Users*/}
                      {/*begin::Permissions*/}
                      <div className='d-flex flex-column text-gray-600'>
                        <div className='d-flex align-items-center py-2'>
                          <span className='bullet bg-primary me-3'></span>Tous les controls
                          d'administeur
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          <span className='bullet bg-primary me-3'></span>Consulter et Editer -
                          Gestion Utilisateurs
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          <span className='bullet bg-primary me-3'></span>Activer - Generation de
                          Facture
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          <span className='bullet bg-primary me-3'></span>Consulter and Editer -
                          Paiements
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          <span className='bullet bg-primary me-3'></span>Consulter and Editer -
                          Clients
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          <span className='bullet bg-primary me-3'></span>
                          <em>et 7 autres...</em>
                        </div>
                      </div>
                      {/*end::Permissions*/}
                    </div>
                    {/*end::Card body*/}
                    {/*begin::Card footer*/}
                    <div className='card-footer flex-wrap pt-0'>
                      <Button
                        type='primary'
                        icon={<EditOutlined />}
                        onClick={() => selectProfil(profil.id)}
                      />
                    </div>
                    {/*end::Card footer*/}
                  </div>
                </div>
              </>
            ))}
            <div className='ol-md-4'>
              {/*begin::Card*/}
              <div className='card h-md-100'>
                {/*begin::Card body*/}
                <div className='card-body d-flex flex-center'>
                  {/*begin::Button*/}
                  <button
                    type='button'
                    className='btn btn-clear d-flex flex-column flex-center'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_add_role'
                    onClick={showModal}
                  >
                    {/*begin::Illustration*/}
                    <img
                      src={toAbsoluteUrl('/media/illustrations/sketchy-1/4.png')}
                      alt=''
                      className='mw-100 mh-150px mb-7'
                    />
                    {/*end::Illustration*/}
                    {/*begin::Label*/}
                    <div className='fw-bolder fs-3 text-gray-600 text-hover-primary'>Ajout</div>
                    {/*end::Label*/}
                  </button>
                  {/*begin::Button*/}
                </div>
                {/*begin::Card body*/}
              </div>
              {/*begin::Card*/}
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/*end::Container*/}
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer un nouveau profil' : 'Modifier le prfoil'}
        onOk={!edite ? handleAddProfil : handleModifierProfil}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddProfil : handleModifierProfil}
          >
            Enregistrer
          </Button>,
        ]}
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          {/*begin::Modal content*/}
          <div className='modal-content'>
            {/*begin::Modal body*/}
            <div className='modal-body scroll-y mx-lg-5 my-7'>
              {/*begin::Form*/}
              <form
                id='kt_modal_add_role_form'
                className='form fv-plugins-bootstrap5 fv-plugins-framework'
                action='#'
              >
                {/*begin::Scroll*/}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_role_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_role_header'
                  data-kt-scroll-wrappers='#kt_modal_add_role_scroll'
                  data-kt-scroll-offset='300px'
                  style={{maxHeight: '375px;'}}
                >
                  {/*begin::Input group*/}
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    {/*begin::Label*/}
                    <label className='fs-5 fw-bolder form-label mb-2'>
                      <span className='required'>Libelle</span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type='text'
                      name='libelle'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e) => setLibelle(e.target.value)}
                      value={profil.libelleProfil}
                      placeholder='Entrez le libelle'
                    />
                    {/*end::Input*/}
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    {/*begin::Label*/}
                    <label className='fs-5 fw-bolder form-label mb-2'>
                      <span>Description</span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <input
                      type='text'
                      name='libelle'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e) => setDescription(e.target.value)}
                      value={profil.description}
                      placeholder='Entrez le libelle'
                    />
                    {/*end::Input*/}
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Permissions*/}
                  <div className='fv-row'>
                    {/*begin::Label*/}
                    <label className='fs-5 fw-bolder form-label mb-2'>Privilèges</label>
                    {/*end::Label*/}
                    {/*begin::Table wrapper*/}
                    <div className='table-responsive'>
                      {/*begin::Table*/}
                      <table className='table align-middle table-row-dashed fs-6 gy-5'>
                        {/*begin::Table body*/}
                        <tbody className='text-gray-600 fw-bold'>
                          {/*begin::Table row*/}
                          <tr>
                            <td className='text-gray-800'>
                              Accès Administrateur
                              <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title=''
                                data-bs-original-title='Allows a full access to the system'
                                aria-label='Allows a full access to the system'
                              ></i>
                            </td>
                            <td>
                              {/*begin::Checkbox*/}
                              <label className='form-check form-check-custom form-check-solid me-9'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='kt_roles_select_all'
                                />
                                <span className='form-check-label'>Tout selectionner</span>
                              </label>
                              {/*end::Checkbox*/}
                            </td>
                          </tr>
                          {/*end::Table row*/}
                          {/*begin::Table row*/}
                          <tr>
                            {/*begin::Label*/}
                            <td className='text-gray-800'>Gestion Utilisateur</td>
                            {/*end::Label*/}
                            {/*begin::Options*/}
                            <td>
                              {/*begin::Wrapper*/}
                              <div className='d-flex'>
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_read'
                                  />
                                  <span className='form-check-label'>Consulter</span>
                                </label>
                                {/*end::Checkbox*/}
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_write'
                                  />
                                  <span className='form-check-label'>Créer</span>
                                </label>
                                {/*end::Checkbox*/}
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_create'
                                  />
                                  <span className='form-check-label'>Modifier</span>
                                </label>
                                {/*end::Checkbox*/}
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_create'
                                  />
                                  <span className='form-check-label'>Supprimer</span>
                                </label>
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_create'
                                  />
                                  <span className='form-check-label'>Activer/Désactiver</span>
                                </label>
                                {/*end::Checkbox*/}
                              </div>
                              {/*end::Wrapper*/}
                            </td>
                            {/*end::Options*/}
                          </tr>
                          {/*end::Table row*/}
                          {/*begin::Table row*/}
                          <tr>
                            {/*begin::Label*/}
                            <td className='text-gray-800'>Gestion Souscription</td>
                            {/*end::Label*/}
                            {/*begin::Options*/}
                            <td>
                              {/*begin::Wrapper*/}
                              <div className='d-flex'>
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_read'
                                  />
                                  <span className='form-check-label'>Consulter</span>
                                </label>
                                {/*end::Checkbox*/}
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_write'
                                  />
                                  <span className='form-check-label'>Créer</span>
                                </label>
                                {/*end::Checkbox*/}
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_create'
                                  />
                                  <span className='form-check-label'>Modifier</span>
                                </label>
                                {/*end::Checkbox*/}
                                {/*begin::Checkbox*/}
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_create'
                                  />
                                  <span className='form-check-label'>Supprimer</span>
                                </label>
                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    name='user_management_create'
                                  />
                                  <span className='form-check-label'>Activer/Désactiver</span>
                                </label>
                                {/*end::Checkbox*/}
                              </div>
                              {/*end::Wrapper*/}
                            </td>
                            {/*end::Options*/}
                          </tr>
                          {/*end::Table row*/}
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                      {/*end::Table*/}
                    </div>
                    {/*end::Table wrapper*/}
                  </div>
                  {/*end::Permissions*/}
                </div>
                {/*end::Scroll*/}
                <div></div>
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Modal body*/}
          </div>
          {/*end::Modal content*/}
        </div>
      </Modal>
    </>
  )
}

export default ProflLea
