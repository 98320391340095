/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {AcccesClient} from '../../../core/clients/_requests'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'

const passwordFormValidationSchema = Yup.object().shape({
  login_user: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Le login est requis'),
  password_user: Yup.string()
    .min(6, 'Minimum 6 caractères')
    .max(50, 'Maximum 50 caractères')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Le mot de passe doit contenir au moins 1 Lettre majuscule, 1 chiffre, et 1 caractère spécial'
    ),
})

const CompteClientAccess = ({client, handleUpdate}) => {
  const navigate = useNavigate()

  const accessEdit = {
    login_user: client.login_user ? client?.login_user : '',
    password_user: '',
    client_id: client.id,
  }

  const formik = useFormik({
    initialValues: accessEdit,
    validationSchema: passwordFormValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        const {message, status} = await AcccesClient(values)

        if (status !== 'success') {
          setStatus(message)
        }
        handleUpdate()
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Accès compte princial</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      <div className='row g-9 mb-8'>
        {/* Col */}
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold mb-2'>Login</label>
          {/* Input */}
          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              autoComplete='off'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.login_user && formik.errors.login_user,
                },
                {
                  'is-valid': formik.touched.login_user && !formik.errors.login_user,
                }
              )}
              placeholder='Login'
              {...formik.getFieldProps('login_user')}
            />
            {formik.touched.login_user && formik.errors.login_user && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.login_user}</div>
              </div>
            )}
          </div>
          {/* End Input */}
        </div>
        {/* End Col */}
        {/* Col */}
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold mb-2'>Mot de passe</label>
          {/* Input */}
          <div className='col-lg-8 fv-row'>
            <input
              type='password'
              autoComplete='off'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password_user && formik.errors.password_user,
                },
                {
                  'is-valid': formik.touched.password_user && !formik.errors.password_user,
                }
              )}
              placeholder='Mot de passe'
              {...formik.getFieldProps('password_user')}
            />
            {formik.touched.password_user && formik.errors.password_user && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.password_user}</div>
              </div>
            )}
          </div>
          {/* End Input */}
        </div>
        {/* End Col */}
      </div>
      {/* begin::Action */}
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
        >
          <span className='indicator-label'>Enregistrer</span>
          {formik.isSubmitting && (
            <span className='indicator-progress'>
              Veillez patientez...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

export default CompteClientAccess
