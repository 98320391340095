import React, {useEffect, useState} from 'react'
import {Radio} from 'antd'
import {getListeMode} from '../../data/params/mode'
import {getListeBanque} from '../../data/params/banque'
import {getListeOperateur} from '../../data/params/operateur'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {paiementAdd} from '../../core/paiement/_requests'

function PaiementClient({facture, handleUpdate}) {
  const [modePayement, setModePayement] = useState('')
  const [modes, setModes] = useState([])
  const [showMobileMoney, setShowMobileMoney] = useState(false)
  const [showCheque, setShowCheque] = useState(false)
  const [banques, setBanques] = useState([])
  const [operateurs, setOperateurs] = useState([])
  const [mode_paiement_id, setMode_Paiement_Id] = useState(0)
  // const [montantRestant, setMontantRestant] = useState(facture.net_a_payer - facture.montant_paye)

  const handleListeMode = async () => {
    const {result, status} = await getListeMode()
    if (status === 'success') {
      setModes(result)
    }
  }

  const handleListeBanque = async () => {
    const {result, status} = await getListeBanque()
    if (status === 'success') {
      setBanques(result)
    }
  }

  const handleListeOperateur = async () => {
    const {result, status} = await getListeOperateur()
    if (status === 'success') {
      setOperateurs(result)
    }
  }

  useEffect(() => {
    handleListeOperateur()
    handleListeBanque()
    handleListeMode()
  }, [])

  const handlehangePaymentMode = (value) => {
    setModePayement(value)
    setMode_Paiement_Id(value)
    console.log('modePaiement', value)
    if (value === 4) {
      setShowMobileMoney(true)
      setShowCheque(false)
    } else if (value === 2) {
      setShowMobileMoney(false)
      setShowCheque(true)
    } else {
      setShowMobileMoney(false)
      setShowCheque(false)
    }
    const soldeRestant = calculateSoldeRestant(formik.values.montant)
    formik.setFieldValue('montant_reste', soldeRestant)
  }

  const paiementDetailsSchema = Yup.object().shape({
    montant: Yup.number().required('Le montant est requis'),
    mode_paiement_id: Yup.number().required('Le mode de paiement est requis'),
    date_paiement: Yup.string().required('La localisation est requise'),
  })

  const calculateSoldeRestant = (montant) => {
    const soldeRestant = facture.montant_ttc - montant
    return soldeRestant >= 0 ? soldeRestant : 0
  }

  const today = new Date().toISOString().split('T')[0]
  const paiementDetails = {
    montant: facture.montant_ttc,
    montant_paye: facture.montant_paye,
    montant_reste: 0,
    mode_paiement_id: 1,
    facture_id: facture.id,
    date_paiement: today,
    nom_concerner: '',
    cel_concerner: '',
    chq_numero: '',
    chq_date: today,
    chq_banque_id: null,
    mm_ref: '',
    mm_operateur_id: null,
    mm_numero: '',
  }

  const formik = useFormik({
    initialValues: paiementDetails,
    validationSchema: paiementDetailsSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        values.mode_paiement_id = mode_paiement_id
        const {message, status} = await paiementAdd(values)
        if (status === 'success') {
          handleUpdate()
          window.reload()
        }
        setStatus(message)
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  useEffect(() => {
    const montantFacture = formik.values.montant || 0
    const montant_payer = formik.values.montant_paye || 0
    const montantAPayer = montantFacture - montant_payer
    formik.setFieldValue('montant_reste', montantAPayer)
  }, [formik.values.montant, formik.values.montant_paye])

  return (
    <>
      <div className='card '>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Détails du client</h3>
          </div>
        </div>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          ''
        )}
        {/*begin::Content*/}
        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/*Date de paiement*/}
              <p>
                <label className='bold'>Date de paiement</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='date'
                    {...formik.getFieldProps('date_paiement')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.date_paiement && formik.errors.date_paiement},
                      {
                        'is-valid': formik.touched.date_paiement && !formik.errors.date_paiement,
                      }
                    )}
                    placeholder='Selection'
                    {...formik.getFieldProps('date_paiement')}
                    value={formik.values.date_paiement}
                  />
                </div>
                {formik.touched.date_paiement && formik.errors.date_paiement && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.date_paiement}</div>
                  </div>
                )}
              </p>
              <p>
                {/*<label className='bold'>Nom du client: </label>*/}
                <span
                  style={{
                    color: '#24247a',
                    fontWeight: 'bold',
                    paddingLeft: '30px',
                    fontSize: '16px',
                  }}
                >
                  {facture?.nom_cabinet}
                </span>
              </p>
              <p>
                {/*<label className='bold'>Formule d'abonnement: </label>*/}
                <div
                  className='badge badge-light fw-bolder'
                  style={{fontSize: '15px', marginLeft: '30px'}}
                >
                  {facture?.libelle_formule}
                </div>
              </p>
              <p className='d-flex justify-content-between'>
                <div className='col-lg-4 fv-row'>
                  <label className='bold'>Montant</label>
                  <input
                    type='number'
                    {...formik.getFieldProps('montant')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.montant && formik.errors.montant},
                      {
                        'is-valid': formik.touched.montant && !formik.errors.montant,
                      }
                    )}
                    max={formik.values.montant_reste}
                    placeholder='Entrer le montant'
                    {...formik.getFieldProps('montant')}
                  />
                </div>
                {formik.touched.montant && formik.errors.montant && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.montant}</div>
                  </div>
                )}
                <div className='col-lg-4 fv-row'>
                  <label className='bold'></label>
                  <div className='text-start fw-bolder fs-6 text-gray-800 pt-5'>
                    {parseInt(formik.values.montant_reste).toLocaleString('fr')}
                  </div>
                </div>
              </p>
              <p className='d-flex justify-content-between'>
                <div className='col-lg-7 fv-row'>
                  <label className='bold'>Remettant</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('nom_concerner')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.nom_concerner && formik.errors.nom_concerner,
                      },
                      {
                        'is-valid': formik.touched.nom_concerner && !formik.errors.nom_concerner,
                      }
                    )}
                    placeholder='Nomt et prenoms'
                  />
                  {formik.touched.nom_concerner && formik.errors.nom_concerner && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.nom_concerner}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-4 fv-row'>
                  <label className='bold'>Téléphone Remettant</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('cel_concerner')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.cel_concerner && formik.errors.cel_concerner,
                      },
                      {
                        'is-valid': formik.touched.cel_concerner && !formik.errors.cel_concerner,
                      }
                    )}
                    placeholder='Numéro'
                  />
                  {formik.touched.cel_concerner && formik.errors.cel_concerner && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cel_concerner}</div>
                    </div>
                  )}
                </div>
              </p>
              <div className='row mb-6'>
                <div className='col-lg-8 fv-row'>
                  <Radio.Group
                    {...formik.getFieldProps('mode_paiement_id')}
                    className={clsx(
                      'form-select form-select-solid form-select-lg fw-bold',
                      {
                        'is-invalid':
                          formik.touched.mode_paiement_id && formik.errors.mode_paiement_id,
                      },
                      {
                        'is-valid':
                          formik.touched.mode_paiement_id && !formik.errors.mode_paiement_id,
                      }
                    )}
                    onChange={(e) => handlehangePaymentMode(e.target.value)}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    name='mode_paiement_id'
                    value={modePayement}
                    defaultValue={formik.values.mode_paiement_id}
                  >
                    {modes.map((mode) => (
                      <Radio value={mode.id}>{mode.libelle}</Radio>
                    ))}
                  </Radio.Group>
                </div>
                {formik.touched.mode_paiement_id && formik.errors.mode_paiement_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mode_paiement_id}</div>
                  </div>
                )}
                {showCheque === true && (
                  <div className='card-body border-top p-9'>
                    {/* Banque */}
                    <div className='row mb-6'>
                      <div className='col-lg-8 fv-row'>
                        <label className='bold'>Banque :</label>
                        <select
                          {...formik.getFieldProps('chq_banque_id')}
                          className={clsx(
                            'form-select form-select-solid form-select-lg fw-bold',
                            {
                              'is-invalid':
                                formik.touched.chq_banque_id && formik.errors.chq_banque_id,
                            },
                            {
                              'is-valid':
                                formik.touched.chq_banque_id && !formik.errors.chq_banque_id,
                            }
                          )}
                          // onChange={(e) => setChq_Banque_Id(e.target.value)}
                          autoComplete='off'
                          // id='chq_banque_id'
                          name='chq_banque_id'
                        >
                          <option value=''>Sélectionner un compte bancaire</option>
                          {banques.map((bank) => (
                            <option value={bank.id} key={bank.id}>
                              {bank.libelle}
                            </option>
                          ))}
                        </select>
                        {formik.touched.chq_banque_id && formik.errors.chq_banque_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.chq_banque_id}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Numero Chèque */}
                    <div className='row mb-6 '>
                      <div className='row'>
                        <div className='col-lg-8 fv-row'>
                          <label className='bold'>Numéro</label>
                          <input
                            {...formik.getFieldProps('secteur_id')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid': formik.touched.chq_numero && formik.errors.chq_numero,
                              },
                              {
                                'is-valid': formik.touched.chq_numero && !formik.errors.chq_numero,
                              }
                            )}
                            type='text'
                            placeholder='Numéro du chèque'
                            // onChange={(e) => setChq_Numero(e.target.value)}
                            name='chq_numero'
                            // id='chq_numero'
                          />
                        </div>
                        {formik.touched.chq_numero && formik.errors.chq_numero && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.chq_numero}</div>
                          </div>
                        )}
                        <div className='col-lg-4 fv-row'>
                          <label className='bold'>Date </label>
                          <input
                            {...formik.getFieldProps('chq_date')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {'is-invalid': formik.touched.chq_date && formik.errors.chq_date},
                              {
                                'is-valid': formik.touched.chq_date && !formik.errors.chq_date,
                              }
                            )}
                            type='date'
                            name='chq_date'
                            value={formik.values.chq_date}
                            // id='chq_date'
                            // onChange={(e) => setChq_Date(e.target.value)}
                          />
                        </div>
                        {formik.touched.chq_date && formik.errors.chq_date && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.chq_date}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showMobileMoney === true && (
                  <div className='card-body border-top p-9'>
                    {/* Nom client */}
                    <div className='row mb-6'>
                      <div className='col-lg-6 fv-row'>
                        <label className='bold'>Opérateurs </label>
                        <select
                          {...formik.getFieldProps('mm_operateur_id')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid':
                                formik.touched.mm_operateur_id && formik.errors.mm_operateur_id,
                            },
                            {
                              'is-valid':
                                formik.touched.mm_operateur_id && !formik.errors.mm_operateur_id,
                            }
                          )}
                          // onChange={(e) => setMM_Operateur_Id(e.target.value)}
                          autoComplete='off'
                          // id='mm_operateur_id'
                          name='mm_operateur_id'
                        >
                          <option value=''>Sélectionner un opérateur</option>
                          {operateurs.map((mobile) => (
                            <option value={mobile.id} key={mobile.id}>
                              {mobile.libelle}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formik.touched.mm_operateur_id && formik.errors.mm_operateur_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.mm_operateur_id}</div>
                        </div>
                      )}
                    </div>
                    {/* Numero Chèque */}
                    <div className='row mb-6'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <label className='bold'>Numero </label>
                          <input
                            {...formik.getFieldProps('mm_numero')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {'is-invalid': formik.touched.mm_numero && formik.errors.mm_numero},
                              {
                                'is-valid': formik.touched.mm_numero && !formik.errors.mm_numero,
                              }
                            )}
                            type='text'
                            placeholder='Référence'
                            // onChange={(e) => setMM_Ref(e.target.value)}
                            name='mm_numero'
                            id='mm_numero'
                          />
                        </div>
                        {formik.touched.mm_numero && formik.errors.mm_numero && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.mm_numero}</div>
                          </div>
                        )}
                        <div className='col-lg-6 fv-row'>
                          <label className='bold'>Reéférence </label>
                          <input
                            {...formik.getFieldProps('mm_ref')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {'is-invalid': formik.touched.mm_ref && formik.errors.mm_ref},
                              {
                                'is-valid': formik.touched.mm_ref && !formik.errors.mm_ref,
                              }
                            )}
                            type='text'
                            placeholder='Référence'
                            // onChange={(e) => setMM_Ref(e.target.value)}
                            name='mm_ref'
                            id='mm_ref'
                          />
                        </div>
                        {formik.touched.mm_ref && formik.errors.mm_ref && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.mm_ref}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Enregistrer</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Veillez patientez...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default PaiementClient
