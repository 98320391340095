import {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addNiveau,
  deleteNiveau,
  getListeNiveau,
  updateEtatNiveau,
  updateNiveau,
} from '../../../data/params/niveau'

const NiveauDeRisque = () => {
  const [niveaus, setNiveaus] = useState([])
  const [niveau, setNiveau] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [valueMin, setValueMin] = useState('')
  const [valueMax, setValueMax] = useState('')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)

  const options = []
  for (let i = 1; i < 26; i++) {
    options.push(i)
  }

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Valeur Minimale',
      dataIndex: 'valueMin',
      key: 'valueMin',
    },
    {
      title: 'Valeur Maximale',
      dataIndex: 'valueMax',
      key: 'valueMax',
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button type='primary' icon={<EditOutlined />} onClick={() => selectNiveau(record.id)} />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer la période ${record.libelle} ?`}
              description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
              onConfirm={() => handleUpdateEtatNiveau(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver la période ${record.libelle} ?`}
              description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
              onConfirm={() => handleUpdateEtatNiveau(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la période ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
            onConfirm={() => handleDeleteNiveau(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const filteredItems = niveaus.filter(
    (item) =>
      item.libelle.toLowerCase().includes(search.toLowerCase()) || item.id === parseInt(search)
  )

  const showModal = () => {
    setOpen(true)
  }

  const handleAddNiveau = async () => {
    setLoading(true)
    const data = {libelle, valueMin, valueMax}
    console.log(data)
    const {status, message} = await addNiveau(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setValueMin('')
      setValueMax('')
      handleListeNiveau()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierNiveau = async () => {
    setLoading(true)
    const data = {niveau_id: niveau.id, libelle, valueMin, valueMax}
    console.log(data)
    const {status, message} = await updateNiveau(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setLibelle('')
      setValueMin('')
      setValueMax('')
      handleListeNiveau()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setLibelle('')
    setValueMin('')
    setValueMax('')
    setError(false)
  }

  const selectNiveau = (niveau_id) => {
    const privil = niveaus.filter((periode) => periode.id === niveau_id)[0]
    setNiveau(privil)
    setLibelle(privil.libelle)
    setValueMin(privil.valueMin)
    setValueMax(privil.valueMax)
    setEdite(true)
    setOpen(true)
  }

  const handleListeNiveau = async () => {
    setLoading(true)
    const {result, status, message} = await getListeNiveau()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setNiveaus(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteNiveau = async (niveau_id) => {
    setLoading(true)
    const data = {niveau_id}
    const {status, message} = await deleteNiveau(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeNiveau()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatNiveau = async (niveau_id, etat) => {
    setLoading(true)
    const data = {niveau_id, etat}
    const {status, message} = await updateEtatNiveau(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setValueMin('')
      setValueMax('')
      handleListeNiveau()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  // const handleSearchDesCivilite = () => {
  //   const civs = niveaus.filter(civilite => civilite.id == )

  // }

  useEffect(() => {
    handleListeNiveau()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des niveau de risque</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={(e) => setSearch(e.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer une nouvelle niveau' : 'Modifier la niveau'}
        onOk={!edite ? handleAddNiveau : handleModifierNiveau}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddNiveau : handleModifierNiveau}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Valeur minimale</label>
          <select
            name='risque'
            className='form-select w-100 me-15'
            onChange={(e) => {
              setValueMin(parseInt(e.target.value))
            }}
            value={valueMin}
          >
            <option value=''>Selectionner une valeur...</option>
            {options.map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label className='bold'>Valeur maximale</label>
          <select
            name='risque'
            className='form-select w-100 me-15'
            onChange={(e) => {
              setValueMax(parseInt(e.target.value))
            }}
            value={valueMax}
          >
            <option value=''>Selectionner une valeur...</option>
            {options.map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
      </Modal>
    </>
  )
}

export default NiveauDeRisque
