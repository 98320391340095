import {useEffect, useRef, useState} from 'react'
import {
  Space,
  Table,
  Button,
  Alert,
  Modal,
  Popconfirm,
  Tag,
  Select,
  Tooltip,
  Divider,
  Input,
} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addProgrammeAuditInitial,
  deleteProgrammeAuditInitial,
  getListeProgrammeAuditInitial,
  updateEtatProgrammeAuditInitial,
  updateProgrammeAuditInitial,
} from '../../../data/params/programme'
import {getListeAssertionActive} from '../../../data/params/assertion'

const ProgrammeAuditInitial = () => {
  const [programmes, setProgrammes] = useState([])
  const [programme, setProgramme] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [procedure, setProcedure] = useState([''])
  const [reference, setReference] = useState('')
  const [procedureAudit, setProcedureAudit] = useState('')
  const [refActivite, setRefActivite] = useState('')
  const [groupeProgrammes, setGroupeProgrammes] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [group, setGroup] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [assertions, setAssertions] = useState([])
  const [assertionsSelected, setAssertionsSelected] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    fetchAssertions()
    handleListeProgramme()
  }, [])

  const fetchAssertions = async () => {
    try {
      const res = await getListeAssertionActive()
      if (res.result) {
        setAssertions(res.result)
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des événements risques', error)
    }
  }

  useEffect(() => {
    const libelleGroup = [...new Set(programmes.map((item) => item.procedureAudit))]
    setGroupeProgrammes(libelleGroup)
  }, [programmes])

  const onNameChange = (event) => {
    setGroup(event.target.value)
  }

  const onChange = (value) => {
    setProcedureAudit(value)
  }

  const addItem = (e) => {
    e.preventDefault()
    setGroupeProgrammes([...groupeProgrammes, group])
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const handleAddInput = (e) => {
    e.preventDefault()

    setProcedure([...procedure, ''])
  }

  const handleInputChange = (index, value) => {
    const updated = [...procedure]
    updated[index] = value
    setProcedure(updated)
  }

  const handleDeleteInputValue = (index, e) => {
    e.preventDefault()
    const updated = [...procedure]
    updated.splice(index, 1)
    setProcedure(updated)
  }

  const handleChange = (value) => {
    setAssertionsSelected(value)
  }

  const handleFiltered = (search) => {
    const filtered = programmes.filter(
      (item) =>
        item?.procedure?.map((opt) => opt.toLowerCase().includes(search.toLowerCase())) ||
        item.ref.toLowerCase().includes(search.toLowerCase()) ||
        item.id === parseInt(search)
    )
    setFilteredItems(filtered)
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setProcedure([])
    setReference('')
    setProcedureAudit('')
    setRefActivite('')
    setAssertionsSelected([])
    setError(false)
  }

  const selectProgramme = (programme_id) => {
    const privil = programmes.filter((periode) => periode.id === programme_id)[0]
    setProgramme(privil)
    setProcedure(privil.procedure)
    setReference(privil.ref)
    setProcedureAudit(privil.procedureAudit)
    setRefActivite(privil.refActivite)
    setAssertionsSelected(privil.assertions)
    setEdite(true)
    setOpen(true)
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleAddProgramme = async () => {
    setLoading(true)
    const data = {
      procedure,
      procedureAudit,
      refActivite,
      ref: reference,
      assertions: assertionsSelected,
    }
    console.log(data)
    const {status, message} = await addProgrammeAuditInitial(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setProcedure([])
      setReference('')
      setProcedureAudit('')
      setRefActivite('')
      setAssertionsSelected([])
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierProgramme = async () => {
    setLoading(true)
    const data = {
      programme_id: programme.id,
      procedure,
      procedureAudit,
      refActivite,
      ref: reference,
      assertions: assertionsSelected,
    }
    console.log(data)
    const {status, message} = await updateProgrammeAuditInitial(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setProcedure([])
      setReference('')
      setProcedureAudit('')
      setRefActivite('')
      setAssertionsSelected([])
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleListeProgramme = async () => {
    setLoading(true)
    const {result, status, message} = await getListeProgrammeAuditInitial()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setProgrammes(result)
      setFilteredItems(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteProgramme = async (programme_id) => {
    setLoading(true)
    const data = {programme_id}
    const {status, message} = await deleteProgrammeAuditInitial(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatProgramme = async (programme_id, etat) => {
    setLoading(true)
    const data = {programme_id, etat}
    const {status, message} = await updateEtatProgrammeAuditInitial(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setProcedure([])
      setReference('')
      setProcedureAudit('')
      setRefActivite('')
      setAssertionsSelected([])
      handleListeProgramme()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'procedureAudit',
      dataIndex: 'procedureAudit',
      key: 'procedureAudit',
      width: 150,
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'ref',
      dataIndex: 'ref',
      key: 'ref',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Procédure',
      dataIndex: 'procedure',
      key: 'procedure',
      render: (text, record) =>
        record.procedure?.length > 1
          ? record.procedure?.map((opt, indexOpt) => (
              <div key={indexOpt} className='d-flex flex-column'>
                <span className='' style={{fontSize: '12px'}}>
                  <strong className='me-2 fw-bolder' style={{fontSize: '15px'}}>
                    -
                  </strong>
                  {opt}
                </span>
              </div>
            ))
          : record.procedure?.length >= 0
          ? record.procedure?.map((opt, indexOpt) => (
              <span className='' key={indexOpt} style={{fontSize: '12px'}}>
                {opt}
              </span>
            ))
          : '',
    },
    {
      title: 'Référence activité',
      dataIndex: 'refActivite',
      key: 'refActivite',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Assertions',
      dataIndex: 'assertions',
      key: 'assertions',
      render: (_, record) => (
        <div className='d-flex align-center'>
          {record.assertions?.map((assertion, index) => (
            <span className='fw-bolder me-3' key={index}>
              {assertion}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space size='middle'>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => selectProgramme(record.id)}
          />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer la période ${record.procedure[index]} ?`}
              reference={`Voulez vous vraiment activer la période ${record.procedure[index]} ?`}
              onConfirm={() => handleUpdateEtatProgramme(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver la période ${record.procedure[index]} ?`}
              reference={`Voulez vous vraiment désactiver la période ${record.procedure[index]} ?`}
              onConfirm={() => handleUpdateEtatProgramme(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la période ${record.procedure[index]} ?`}
            reference={`Voulez vous vraiment supprimer la période ${record.procedure[index]} ?`}
            onConfirm={() => handleDeleteProgramme(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' reference={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des programmes d'audit initial</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={(e) => handleFiltered(e.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <div className='d-flex align-items-center'>
              <div className='fv-row fv-plugins-icon-container me-7'>
                <select
                  className='form-select mb-2 w-350px'
                  data-control='select2'
                  data-placeholder='Select an option'
                  name='payment_method'
                  data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
                  tabIndex='-1'
                  onChange={(e) => {
                    const updateList = programmes.filter(
                      (item) => item.procedureAudit === e.target.value
                    )
                    if (updateList && updateList.length > 0) {
                      setFilteredItems(updateList)
                      setGroup(e.target.value)
                    } else {
                      setFilteredItems(programmes)
                      setGroup(null)
                    }
                  }}
                  value={group}
                >
                  <option data-select2-id='select2-data-11-txbs'>
                    Filtrer par la procédure d'audit...
                  </option>
                  {groupeProgrammes.map((item, index) => (
                    <option value={item} key={index} data-select2-id='select2-data-132-sp74'>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        width={1000}
        title={!edite ? 'Enregistrer une nouvelle programme' : 'Modifier la programme'}
        onOk={!edite ? handleAddProgramme : handleModifierProgramme}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddProgramme : handleModifierProgramme}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Procédure d'audit</label>
          <Select
            // style={{width: 400}}
            placeholder='Critère relatif à '
            onChange={onChange}
            showSearch
            className='form-control mb-5 me-2 w-100'
            optionFilterProp='children'
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{margin: '8px 0'}} />
                <Space style={{padding: '0 8px 4px'}}>
                  <Input
                    placeholder="Entrer procédure d'audit"
                    ref={inputRef}
                    value={group}
                    onChange={onNameChange}
                  />
                  <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
                    Ajouter...
                  </Button>
                </Space>
              </>
            )}
            options={
              groupeProgrammes && groupeProgrammes.map((item) => ({label: item, value: item}))
            }
          />
        </p>
        <div className='d-flex align-items-center my-2'>
          <div className='col-lg-6'>
            <p className='me-7'>
              <label className='bold'>Assertions</label>
              <Select
                mode='tags'
                style={{width: '100%'}}
                placeholder='choisir les assertions'
                onChange={handleChange}
                value={assertionsSelected}
                options={assertions.map((item) => ({
                  label: item.libelle,
                  value: item.libelle,
                }))}
              />
            </p>
          </div>
          <div className='col-lg-6'>
            <p>
              <label className='bold'>Reference</label>
              <input
                type='text'
                name='fname'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                onChange={(e) => setReference(e.target.value)}
                value={reference}
                placeholder='Entrez la procedure'
              />
            </p>
          </div>
        </div>
        <p>
          <label className='bold'>Procédure</label>
          <div className='d-flex justify-content-between'>
            <div className='col-lg-11 me-5'>
              <div className=' d-flex flex-column mb-2'>
                {procedure?.map((value, index) => (
                  <div className='d-flex align-items-center mb-2' key={index}>
                    <input
                      style={{fontSize: '12px'}}
                      className='form-control w-100 me-3'
                      type='text'
                      value={value}
                      name='procedure'
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                    <Tooltip title='supprimer un point de libellé'>
                      <Button
                        onClick={(e) => handleDeleteInputValue(index, e)}
                        title='Supprimer ?'
                        type='primary'
                        icon={<DeleteOutlined />}
                        danger
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-lg-1'>
              <Tooltip title='Ajouter un point de libellé'>
                <Button className='mb-2 ' onClick={(e) => handleAddInput(e)}>
                  <PlusOutlined style={{color: 'blue'}} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </p>
      </Modal>
    </>
  )
}

export default ProgrammeAuditInitial
