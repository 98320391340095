import React, {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag} from 'antd'

import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {DateComponent} from '../components/liste/dateComponent'
import {
  addFormule,
  deleteFormule,
  getListeFormule,
  updateEtatFormule,
  updateFormule,
} from '../data/formule/formule_abonnement'
import {getListeCategorieFormule} from '../data/params/categorie_formule'

const FormulePage = () => {
  const [formules, setFormules] = useState([])
  const [categories, setCategorieForms] = useState([])
  const [formule, setFormule] = useState([])
  const [serchDesc, setSerchDesc] = useState('')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [valeur, setValeur] = useState('')
  const [prix, setPrix] = useState(0)
  const [categorie_id, setCategorieId] = useState(1)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Prix (FCFA)',
      dataIndex: 'prix',
      key: 'prix',
      render: (text) => (
        <span className='display-block bold chart-orange'>
          {parseFloat(text).toLocaleString('fr')}
        </span>
      ),
    },
    {
      title: 'Valeur',
      dataIndex: 'valeur',
      key: 'valeur',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Catégorie',
      dataIndex: 'libelle_categorie',
      key: 'libelle_categorie',
      render: (text) => <span className='display-block'>{text}</span>,
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button type='primary' icon={<EditOutlined />} onClick={() => selectFormule(record.id)} />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer ce paramètre ${record.libelle} ?`}
              description={`Voulez vous vraiment activer ce paramètre ${record.libelle} ?`}
              onConfirm={() => handleUpdateFormule(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver ce paramètre ${record.libelle} ?`}
              description={`Voulez vous vraiment désactiver ce paramètre ${record.libelle} ?`}
              onConfirm={() => handleUpdateFormule(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer ce paramètre ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer ce paramètre ${record.libelle} ?`}
            onConfirm={() => handleDeleteFormule(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const showModal = () => {
    setOpen(true)
  }
  const handleAddFormule = async () => {
    setLoading(true)
    const data = {libelle, valeur, description, categorie_id, prix}
    console.log(data)
    const {status, message} = await addFormule(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeFormules()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierFormule = async () => {
    setLoading(true)
    const data = {
      formule_id: formule.id,
      libelle,
      valeur,
      description,
      categorie_id,
      prix,
    }
    console.log(data)
    const {status, message} = await updateFormule(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeFormules()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setError(false)
  }

  const selectFormule = (formule_id) => {
    console.log(formule_id)
    const forms = formules.filter((formule) => formule.id === formule_id)[0]
    setCategorieId(forms.categorie_id)
    setFormule(forms)
    setLibelle(forms.libelle)
    setValeur(forms.valeur)
    setPrix(forms.prix)
    setDescription(forms.description)
    setEdite(true)
    setOpen(true)
  }

  const handleListeFormules = async () => {
    setLoading(true)
    const {result, status, message} = await getListeFormule()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setFormules(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteFormule = async (formule_id) => {
    setLoading(true)
    const data = {formule_id}
    const {status, message} = await deleteFormule(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeFormules()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateFormule = async (formule_id, etat) => {
    setLoading(true)
    const data = {formule_id, etat}
    const {status, message} = await updateEtatFormule(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeFormules()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const filteredItems = formules.filter((item) =>
    item.libelle.toLowerCase().includes(serchDesc.toLowerCase())
  )
  const handleClear = () => {
    if (serchDesc) {
      setSerchDesc('')
    }
  }

  const handleListeCategorie = async () => {
    // setLoading(true)
    const {result, status, message} = await getListeCategorieFormule()
    setMessage(message)
    if (status === 'success') {
      setCategorieForms(result)
      // setLoading(false)
    } else {
      setError(true)
      // setLoading(false)
    }
  }

  useEffect(() => {
    handleListeFormules()
    handleListeCategorie()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Formules d'abonnement enregistrés</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='search'
                name='fname'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
                onChange={(e) => setSerchDesc(e.target.value)}
                onClear={handleClear}
                value={serchDesc}
              />
            </div>

            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer nouvelle formule' : 'Modifier la formule'}
        onOk={!edite ? handleAddFormule : handleModifierFormule}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddFormule : handleModifierFormule}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
        <p>
          <label className='bold'>Description</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder='Entrez la description'
          />
        </p>
        <p>
          <label className='bold'>Prix</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setPrix(e.target.value)}
            value={prix}
            placeholder='Entrez le prix'
          />
        </p>
        <p>
          <label className='bold'>Valeur</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setValeur(e.target.value)}
            value={valeur}
            placeholder='Entrez la valeur'
          />
        </p>
        <p>
          <label className='bold'>Catégorie</label>
          <select
            value={categorie_id}
            onChange={(e) => setCategorieId(e.target.value)}
            className='form-select form-select-solid form-select-lg fw-bold'
          >
            {categories.map((unite) => (
              <option value={unite.id} key={unite.id}>
                {unite.libelle}
              </option>
            ))}
          </select>
        </p>
      </Modal>
    </>
  )
}

export default FormulePage
