import axios from 'axios'
import { AJOUTER_MODE_PAIEMENT, LISTE_MODE_PAIEMENT, MODIFIER_ETAT_MODE_PAIEMENT, MODIFIER_MODE_PAIEMENT, SUPPRIMER_MODE_PAIEMENT } from '../../api/apiRoute'

export const addMode = async (data) => {
    return axios.post(AJOUTER_MODE_PAIEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateMode = async (data) => {
    return axios.post(MODIFIER_MODE_PAIEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeMode = async () => {
    return axios.get(LISTE_MODE_PAIEMENT).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteMode = async (data) => {
    return axios.post(SUPPRIMER_MODE_PAIEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatMode = async (data) => {
    return axios.post(MODIFIER_ETAT_MODE_PAIEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}