import {ClockCircleOutlined, UserOutlined} from '@ant-design/icons'
import moment from 'moment'

export const DateComponent = ({date, user}) => {
  return (
    <>
      <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
        <ClockCircleOutlined style={{fontSize: '15px'}} />{' '}
        {moment(date).format('DD/MM/YYYY à HH:mm')}
      </span>
      <span className='chart-orange display-block f-size-12' style={{fontWeight: 600}}>
        <UserOutlined style={{fontSize: '15px'}} /> {user}
      </span>
    </>
  )
}
