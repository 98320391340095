import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const GET_PAYS_URL = `${API_URL}/params/liste-pays`
export const getListePays = async () => {
  return axios
    .get(GET_PAYS_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
