import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import './assets/sass/style.scss'
import './assets/sass/style.react.scss'
import {AuthProvider} from './core/auth'
import {AppRoutes} from './routes/AppRoutes'
import {setupAxios} from './core/auth'

setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
    {/*<ReactQueryDevtools initialIsOpen={false} />*/}
  </QueryClientProvider>,
  document.getElementById('root')
)
