import React, {useEffect, useState} from 'react'
import {List, Avatar, Tag} from 'antd'
import {
  UserOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import moment from 'moment/moment'
import {GetApporteurClient} from '../../../core/clients/_requests'

const ApporteurAffaireModal = ({client, client_id}) => {
  const [apporteurs, setApporteurs] = useState([])

  useEffect(() => {
    GetApporteurClient(client_id)
      .then((res) => {
        setApporteurs(res.result)
      })
      .catch((err) => console.log(err))
  }, [client.id, setApporteurs])

  return (
    <div className='apporteur-modal'>
      <List
        itemLayout='horizontal'
        dataSource={apporteurs}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<HistoryOutlined />} />}
              title={item.libelle_formule}
              description={`Apporteur: ${item.nom_apporteur + ' ' + item.prenom_apporteur}`}
            />
            <div style={{padding: '20px'}}>
              <p>
                <ScheduleOutlined /> Etat:{' '}
                {item.cloture === 1 ? (
                  <Tag color='green'>Terminé</Tag>
                ) : (
                  <Tag color='yellow'>En cours</Tag>
                )}
              </p>
            </div>
            <div style={{padding: '20px'}}>
              <p>
                <CalendarOutlined /> Date de début: {moment(item.date_debut_abon).format('DD/MM/Y')}
              </p>
              <p>
                <ClockCircleOutlined /> Date de fin: {moment(item.date_fin_abon).format('DD/MM/Y')}
              </p>
            </div>
          </List.Item>
        )}
      />
    </div>
  )
}

export default ApporteurAffaireModal
