import axios from 'axios'
import {
    AJOUTER_QUESTION,
    LISTE_QUESTION, LISTE_QUESTION_ACTIVE,  
    MODIFIER_QUESTION,
    MODIFIER_ETAT_QUESTION,  SUPPRIMER_QUESTION
} from '../../api/apiRoute'

export const addQuestion = async (data) => {
    return axios.post(AJOUTER_QUESTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateQuestion = async (data) => {
    return axios.post(MODIFIER_QUESTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeQuestion = async () => {
    return axios.get(LISTE_QUESTION).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeQuestionActive = async () => {
    return axios.get(LISTE_QUESTION_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteQuestion = async (data) => {
    return axios.post(SUPPRIMER_QUESTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatQuestion = async (data) => {
    return axios.post(MODIFIER_ETAT_QUESTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}