import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../core/layout'
import HonoraireParametrage from './HonoraireParametrage'
import HonoraireListeApporteur from './HonoraireListeApporteur'
import HonoraireListePartenaireMet from './honoraireListePartenaireMet'
import HonoraireListePartenaireTech from './HonoraireListePartenaireTech'

const clientsBreadcrumbs = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Retour',
    path: '/dashboard',
    isSeparator: true,
    isActive: false,
  },
]

const HonorairesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='parametrages'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Paramétrage Honoraire</PageTitle>
              <HonoraireParametrage />
            </>
          }
        />
        <Route
          path='liste/apporteurs'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Liste des honoraires Apporteurs d'affaire</PageTitle>
              <HonoraireListeApporteur />
            </>
          }
        />
        <Route
          path='liste/partenaires-met'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Liste des honoraires partenaires metiers</PageTitle>
              <HonoraireListePartenaireMet />
            </>
          }
        />
        <Route
          path='liste/partenaires-tech'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Liste des honoraires partenaires technique</PageTitle>
              <HonoraireListePartenaireTech />
            </>
          }
        />
        <Route index element={<Navigate to='/apps/honoraires/parametrages' />} />
      </Route>
    </Routes>
  )
}

export default HonorairesPage
