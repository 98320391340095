import {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag, Input} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addEvent,
  deleteEvent,
  getListeEvent,
  updateEtatEvent,
  updateEvent,
} from '../../../data/params/event'
import {getListeRisque} from '../../../data/params/risque'

const EvenementSourceDeRisque = () => {
  const [events, setEvents] = useState([])
  const [event, setEvent] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [risqueId, setRisqueId] = useState(null)
  const [risques, setRisques] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)

  const [tableParams, setTableParams] = useState({
    pagination: {
        current: 1,
        pageSize: 50,
    },
});

  useEffect(() => {
    fetchRisqueInherent()
  }, [])

  const fetchRisqueInherent = () => {
    getListeRisque().then((res) => {
      setRisques(res.result)
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
        pagination,
        filters,
        ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setEvents([]);
    }
};

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Rubrique de risque',
      dataIndex: 'rique',
      key: 'rique',
      width: 100,
      render: (_, record) => <span className=''>{record.Bog_RisqueInherent?.libelle}</span>,
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
      width: 300,
      render: (text, record) =>(
        <Input.TextArea
          size="large"
          value={record.libelle}
          maxLength={5000}
          showCount
          // rows={4}
          autoSize
          readOnly
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (record) =>(
        <Input.TextArea
          size="large"
          value={record.description}
          maxLength={5000}
          showCount
          autoSize
          readOnly
        />
      ),
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      width: 70,
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.User?.nom_user + ' ' + record.User?.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button type='primary' icon={<EditOutlined />} onClick={() => selectEvent(record.id)} />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer la période ${record.libelle} ?`}
              description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
              onConfirm={() => handleUpdateEtatBanque(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver la période ${record.libelle} ?`}
              description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
              onConfirm={() => handleUpdateEtatBanque(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la période ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
            onConfirm={() => handleDeleteEvent(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]


  const handleFiltered = (search) => {
    const filtered = risques.filter(
      (item) =>
      item.libelle.toLowerCase().includes(search.toLowerCase()) ||
      item.description.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
    )
    setFilteredItems(filtered)
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleAddEvent = async () => {
    setLoading(true)
    const data = {libelle, description, risqueId}
    console.log(data)
    const {status, message} = await addEvent(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setRisqueId(null)
      setDescription('')
      handleListeEvent()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierEvent = async () => {
    setLoading(true)
    const data = {event_id: event.id, libelle, description, risqueId}
    console.log(data)
    const {status, message} = await updateEvent(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setRisqueId(null)
      setDescription('')
      handleListeEvent()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setLibelle('')
    setDescription('')
    setError(false)
  }

  const selectEvent = (event_id) => {
    const privil = events.filter((periode) => periode.id === event_id)[0]
    setEvent(privil)
    setLibelle(privil.libelle)
    setDescription(privil.description)
    setEdite(true)
    setOpen(true)
  }

  const handleListeEvent = async () => {
    setLoading(true)
    const {result, status, message} = await getListeEvent()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setEvents(result)
      setFilteredItems(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteEvent = async (event_id) => {
    setLoading(true)
    const data = {event_id}
    const {status, message} = await deleteEvent(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeEvent()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatBanque = async (event_id, etat) => {
    setLoading(true)
    const data = {event_id, etat}
    const {status, message} = await updateEtatEvent(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      handleListeEvent()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  // const handleSearchDesCivilite = () => {
  //   const civs = events.filter(civilite => civilite.id == )

  // }

  useEffect(() => {
    handleListeEvent()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des evenements source de risque</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={handleFiltered}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>
            <div>
              <div className='fv-row fv-plugins-icon-container me-7'>
                <select
                  className='form-select mb-2 w-350px'
                  data-control='select2'
                  data-placeholder='Select an option'
                  name='payment_method'
                  data-select2-id='select2-data-kt_ecommerce_edit_order_payment'
                  tabIndex='-1'
                  onChange={(e) => {
                    const updateList = events.filter((item) => item.Bog_RisqueInherent?.id === parseInt(e.target.value))
                    if (updateList && updateList.length > 0) {
                      setFilteredItems(updateList)
                      setRisqueId(e.target.value)
                    } else {
                      setFilteredItems(risques)
                      setRisqueId(null)
                    }
                  }}
                  value={risqueId}
                >
                  <option data-select2-id='select2-data-11-txbs'>
                    Filtrer par le risque...
                  </option>
                  {risques.map((item, index) => (
                    <option value={item.id} key={index} data-select2-id='select2-data-132-sp74'>
                      {item.libelle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        title={!edite ? 'Enregistrer une nouvelle event' : 'Modifier la event'}
        onOk={!edite ? handleAddEvent : handleModifierEvent}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddEvent : handleModifierEvent}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Rubrique de risque</label>
          <select
            name='risque'
            className='form-select w-100 me-15'
            onChange={(e) => {
              setRisqueId(parseInt(e.target.value))
            }}
            value={risqueId}
          >
            <option value=''>Selectionner un risque...</option>
            {risques.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.libelle}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label className='bold'>Libelle</label>
          <Input.TextArea
                size="large"
                placeholder="Libellé"
                value={libelle}
                maxLength={2000}
                showCount
                rows={4}
                onChange={(e) => setLibelle(e.target.value)}
                style={{width: "100%"}}
              />
        </p>
        <p>
          <label className='bold'>Description</label>
          <Input.TextArea
                size="large"
                placeholder="Description"
                value={description}
                maxLength={2000}
                showCount
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
                style={{width: "100%"}}
              />
        </p>
      </Modal>
    </>
  )
}

export default EvenementSourceDeRisque
