import axios from 'axios'
import {
    AJOUTER_NIVEAU,
    LISTE_NIVEAU, LISTE_NIVEAU_ACTIVE,  
    MODIFIER_NIVEAU,
    MODIFIER_ETAT_NIVEAU,  SUPPRIMER_NIVEAU
} from '../../api/apiRoute'

export const addNiveau = async (data) => {
    return axios.post(AJOUTER_NIVEAU, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateNiveau = async (data) => {
    return axios.post(MODIFIER_NIVEAU, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeNiveau = async () => {
    return axios.get(LISTE_NIVEAU).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeNiveauActive = async () => {
    return axios.get(LISTE_NIVEAU_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteNiveau = async (data) => {
    return axios.post(SUPPRIMER_NIVEAU, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatNiveau = async (data) => {
    return axios.post(MODIFIER_ETAT_NIVEAU, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}