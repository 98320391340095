import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CREATED_PAIEMENT_URL = `${API_URL}/facture/enregistrer-paiement`
const LISTE_ABONNEMENT_CLIENT_URL = `${API_URL}/facture/liste-des-paiements`

export const paiementAdd = async (data) => {
  return axios
    .post(CREATED_PAIEMENT_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

// export const ModifierClient = async (data) => {
//   return axios
//     .post(EDITED_CLIENT_URL, data)
//     .then((res) => {
//       if (res.status === 200) {
//         return res.data
//       } else {
//         return {
//           status: 'error',
//           message: 'La connexion à internet est instable !',
//         }
//       }
//     })
//     .catch((err) => console.log(err))
// }

export const paiementList = async () => {
  return axios
    .get(LISTE_ABONNEMENT_CLIENT_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

// export const GetApporteurClient = (client_id) => {
//   return axios.get(`${GET_APPORTEUR_CLIENT_URL}/${client_id}`).then((response) => response.data)
// }
