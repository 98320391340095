import React, {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Tag} from 'antd'
import {DateComponent} from '../../../components/liste/dateComponent'
import {
  ApiOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import {getListeFacture} from '../../../data/facturation/facture'
import {Link} from 'react-router-dom'
import CompteClientAccess from '../../../components/clients/modals/CompteClientAccess'
import ApporteurAffaireModal from '../../../components/clients/modals/ClientApporteurAffaire'
import ClientAbonnement from '../../../components/clients/modals/ClientAbonnemnt'
import PaiementClient from '../../../components/clients/PaiementClient'
import {KTSVG} from '../../../components/helpers/KTSVG'

const FacturePage = () => {
  const [factures, setFactures] = useState([])
  const [facture, setFacture] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null)

  const showModal = () => {
    setOpen(true)
  }

  // const handleAddBanque = async () => {
  //   setLoading(true)
  //   const data = {libelle, description}
  //   console.log(data)
  //   const {status, message} = await addBanque(data)
  //   setMessage(message)
  //   if (status === 'success') {
  //     setError(false)
  //     setLibelle('')
  //     setDescription('')
  //     handleListeFacture()
  //     setLoading(false)
  //   } else {
  //     setError(true)
  //     setLoading(false)
  //   }
  // }

  // const handleModifierBanque = async () => {
  //   setLoading(true)
  //   const data = {banque_id: facture.id, libelle, description}
  //   console.log(data)
  //   const {status, message} = await updateBanque(data)
  //   setMessage(message)
  //   if (status === 'success') {
  //     setError(false)
  //     setLibelle('')
  //     setDescription('')
  //     handleListeFacture()
  //     setLoading(false)
  //   } else {
  //     setError(true)
  //     setLoading(false)
  //   }
  // }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setLibelle('')
    setDescription('')
    setError(false)
  }

  const selectFacture = (facture_id, modal_id) => {
    console.log(facture_id)
    const facture = factures.filter((fact) => fact.id === facture_id)[0]

    setFacture(facture)
    handleButtonClick(modal_id)
    setEdite(true)
    setOpen(true)
  }

  const handleListeFacture = async () => {
    setLoading(true)
    const {result, status, message} = await getListeFacture()
    setMessage(message)
    if (status === 'success') {
      console.log(result)
      setError(false)
      setFactures(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleButtonClick = (modalId) => {
    setSelectedModal(modalId)
  }

  // const handleDeleteBanque = async (banque_id) => {
  //   setLoading(true)
  //   const data = {banque_id}
  //   const {status, message} = await deleteBanque(data)
  //   setMessage(message)
  //   if (status === 'success') {
  //     setError(false)
  //     handleListeFacture()
  //     setLoading(false)
  //   } else {
  //     setError(true)
  //     setLoading(false)
  //   }
  // }

  // const handleUpdateEtatBanque = async (banque_id, etat) => {
  //   setLoading(true)
  //   const data = {banque_id, etat}
  //   const {status, message} = await updateEtatBanque(data)
  //   setMessage(message)
  //   if (status === 'success') {
  //     setError(false)
  //     setLibelle('')
  //     setDescription('')
  //     handleListeFacture()
  //     setLoading(false)
  //   } else {
  //     setError(true)
  //     setLoading(false)
  //   }
  // }

  // const handleSearchDesCivilite = () => {
  //   const civs = factures.filter(civilite => civilite.id == )

  // }

  useEffect(() => {
    handleListeFacture()
  }, [])

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Numéro',
      dataIndex: 'numero',
      key: 'numero',
      render: (text) => <span className='display-block bold'>#{text}</span>,
    },
    {
      title: 'Nom du cabinet',
      dataIndex: 'nom_cabinet',
      key: 'nom_cabinet',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Libelle de formule',
      dataIndex: 'libelle_formule',
      key: 'libelle_formule',
      render: (text) => (
        <div className='badge badge-light fw-bolder' style={{fontSize: '15px', marginLeft: '30px'}}>
          {text}
        </div>
      ),
    },
    {
      title: 'Montant ttc',
      dataIndex: 'montant_ttc',
      key: 'montant_ttc',
      render: (_, record, index) => (
        <span className='display-block bold'>
          {parseInt(record?.montant_ttc).toLocaleString('fr') + ' FCFA'}
        </span>
      ),
    },

    {
      title: 'Montant payé',
      dataIndex: 'montant_paye',
      key: 'montant_paye',
      render: (_, record, index) => (
        <div className='text-end fw-bolder fs-6 text-gray-800'>
          {' '}
          {parseInt(record?.montant_paye).toLocaleString('fr') + ' FCFA'}
        </div>
      ),
    },

    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat_facture === 2 ? (
          <Tag color='green'>Payée</Tag>
        ) : record.etat_facture === 1 ? (
          <Tag color='orange'>En partie payée</Tag>
        ) : (
          <Tag color='yellow'>Impayée</Tag>
        ),
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <a
            href='#'
            className='btn btn-light btn-active-light-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Actions
            <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
          </a>
          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              {record.etat_facture !== 2 && (
                <a href='#' className='menu-link px-5' onClick={() => selectFacture(record.id, 1)}>
                  <CreditCardOutlined />
                  <span className='indicator-label' style={{paddingLeft: '15px'}}>
                    Payer
                  </span>
                </a>
              )}
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <Link
                className='menu-link px-5'
                to={`/apps/clients/facture/${record.id}`}
                state={{record}}
              >
                <FileDoneOutlined />
                <span className='indicator-label' style={{paddingLeft: '15px'}}>
                  Imprimer facture
                </span>
              </Link>
            </div>
            {/* end::Menu item */}

            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5' onClick={() => selectFacture(record.id, 3)}>
                <HistoryOutlined />
                <span className='indicator-label' style={{paddingLeft: '15px'}}>
                  Historique de paiemnt
                </span>
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            {/*<div className='menu-item px-3'>*/}
            {/*  {record?.etat === 1 && (*/}
            {/*    <a*/}
            {/*      href='#'*/}
            {/*      className='menu-link px-5'*/}
            {/*      // onClick={() => handleUpdateClientEtat(record.id, 0)}*/}
            {/*    >*/}
            {/*      <LockOutlined />*/}
            {/*      <span className='indicator-label' style={{paddingLeft: '15px'}}>*/}
            {/*        Désactiver*/}
            {/*      </span>*/}
            {/*    </a>*/}
            {/*  )}*/}
            {/*  {record?.etat === 0 && (*/}
            {/*    <a*/}
            {/*      href='#'*/}
            {/*      className='menu-link px-5'*/}
            {/*      // onClick={() => handleUpdateClientEtat(record.id, 1)}*/}
            {/*    >*/}
            {/*      <UnlockOutlined />*/}
            {/*      <span className='indicator-label' style={{paddingLeft: '15px'}}>*/}
            {/*        Activer*/}
            {/*      </span>*/}
            {/*    </a>*/}
            {/*  )}*/}
            {/*</div>*/}
            {/* end::Menu item */}
            {/* begin::Menu item */}
            {/*<div className='menu-item px-3'>*/}
            {/*  <a*/}
            {/*    href='#'*/}
            {/*    className='menu-link px-5'*/}
            {/*    // onClick={() => handleDeleteClient(record.id)}*/}
            {/*  >*/}
            {/*    <DeleteOutlined style={{color: 'red'}} />*/}
            {/*    <span className='indicator-label' style={{paddingLeft: '15px', color: 'red'}}>*/}
            {/*      Supprimer*/}
            {/*    </span>*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </>
      ),
    },
  ]

  const filteredItems = factures.filter(
    (item) =>
      item.libelle_formule.toLowerCase().includes(search.toLowerCase()) ||
      item.libelle_categorie.toLowerCase().includes(search.toLowerCase()) ||
      item.nom_cabinet.toLowerCase().includes(search.toLowerCase()) ||
      item.etat_facture.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
  )

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des factures</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              {/* <input type="text" name="fname" onChange={e => setSearch(e.target.value)} class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Rechercher" /> */}
            </div>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={''}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        {selectedModal === 1 && (
          <PaiementClient facture={facture} handleUpdate={handleListeFacture} />
        )}
        {/*{selectedModal === 2 && (*/}
        {/*  <DateComponent*/}
        {/*    date={client.createdAt}*/}
        {/*    user={client.nom_user + ' ' + client.prenom_user}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{selectedModal === 3 && <ApporteurAffaireModal client={client} client_id={client.id} />}*/}
        {/*{selectedModal === 4 && (*/}
        {/*  <ClientAbonnement client={client} handleUpdate={handleListeClient} />*/}
        {/*)}*/}
      </Modal>
    </>
  )
}

export default FacturePage
