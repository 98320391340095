import React, {useEffect, useState} from 'react'
import {
  DownloadOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordFilled,
  UploadOutlined,
} from '@ant-design/icons'
import moment from 'moment/moment'
// import {getListeFichierClient} from "../../data/file";
// import { DOWNLOAD_FILE_CLIENT } from '../../api/apiRoutes';
import {FileUploader} from '../../../components/fileManager/FIileUploader'
import {getListeNormeISA} from '../../../core/file/_requests'
import {DOWNLOAD_FILE_CLIENT} from '../../../api/apiRoute'
import { Modal } from 'antd'

const NormeISA = () => {
  const [filtersItems, setFiltersItems] = useState([])
  const [documents, setDocuments] = useState([])
  const [downloadUrl, setDownloadUrl] = useState([])
  const [message, setMessage] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    fetchDocuments()
  }, [])

  // const fetchDocuments = () => {
  //     setLoading(true);
  //     getListeNormeISA()
  //         .then((res) => {
  //             setDocuments(res.result);
  //             setFiltersItems(res.result)
  //             setLoading(false);
  //         });
  // };

  const fetchDocuments = async () => {
    try {
      const res = await getListeNormeISA()
      if (res.result) {
        if (res.result) {
          setDocuments(res.result)
          setFiltersItems(res.result)
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des événements risques', error)
    }
  }

  const downloadDocumentsClient = (client_bo, client_lea, fichier) => {
    setLoading(true)
    window.open(`${DOWNLOAD_FILE_CLIENT}/NORMES/${fichier}`, '_blank')
    // downloadFile(client_bo, client_lea, fichier)
    //     .then((res) => {
    //         const blobUrl = URL.createObjectURL(res.data);
    //         setDownloadUrl(blobUrl);

    //         const downloadLink = document.createElement('a');
    //         downloadLink.href = blobUrl;
    //         downloadLink.download = fichier;
    //         document.body.appendChild(downloadLink);
    //         downloadLink.click();
    //         document.body.removeChild(downloadLink);

    //         URL.revokeObjectURL(blobUrl);
    //     })
    // .catch((err) => {
    //     setError(true)
    //     setMessage("Oups! Une erreur est survenue...")
    // })
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase()
    const filtered = documents.filter(
      (item) =>
        item.nom_user.toLowerCase().includes(searchTerm) ||
        item.prenom_user.toLowerCase().includes(searchTerm) ||
        item.libelleProfil.toLowerCase().includes(searchTerm)
    )
    setFiltersItems(filtered)
  }

  const getIconForFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return <FilePdfOutlined style={{fontSize: '25px', color: 'red'}} />
      case 'doc':
      case 'docx':
      case 'txt':
        return <FileWordFilled style={{fontSize: '25px', color: 'blue'}} />
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <FileImageOutlined style={{fontSize: '25px', color: 'blue'}} />
      default:
        return <FileOutlined style={{fontSize: '25px', color: 'blue'}} />
    }
  }

  // const menuItems = [
  //   {
  //     label: (
  //       <>
  //         <Link to={`/clients/dossier/${client.id}`} state={{record: client}}>
  //           Télecharger
  //         </Link>
  //       </>
  //     ),
  //     onClick: () => {},
  //     icon: <DownloadOutlined />,
  //     state: {client},
  //   },
  // ]

  return (
    <>
      <div className='d-flex flex-column w-100'>
        <div className='card w-100'>
          {error ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{message}</div>
            </div>
          ) : (
            ''
          )}

          <div className='card-header border-0 pt-6'>
            {/*<SearchHeader handleSearch={handleSearchChange}/>*/}
            <div className='card-toolbar'>
              <div className='d-flex justify-content-end' data-kt-record-table-toolbar='base'>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_add_user'
                  onClick={handleOpenModal}
                >
                  <span className='svg-icon svg-icon-2'>
                    <UploadOutlined style={{fontSize: '2px'}} />
                  </span>
                  Importer
                </button>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div
              id='kt_file_manager_listwrapper'
              className='dataTables_wrapper dt-bootstrap no-footer'
            >
              <div className='table-responsive'>
                <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                  <div
                    className='dataTables_scrollBody position-relative overflow-auto w-100'
                    style={{maxHeight: '700px'}}
                  >
                    <table
                      id='kt_file_manager_list'
                      data-kt-filemanager-table='folders'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer w-100'
                    >
                      <thead>
                        <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs- h-1px'>
                          <th
                            className='w-10px pe-2 w-30px sorting_disabled pt-0 pd-0 border-top-width-0 border-botton-width-0 h-0'
                            rowSpan='1'
                            colSpan='1'
                          >
                            <div className='dataTables_sizing h-0 overflow-hidden'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  data-kt-check='true'
                                  data-kt-check-target='#kt_file_manager_list .form-check-input'
                                  value='1'
                                />
                              </div>
                            </div>
                          </th>
                          <th
                            className='min-w-250px w-526.5px sorting_disabled pt-0 pd-0 border-top-width-0 border-botton-width-0 h-0'
                            rowSpan='1'
                            colSpan='1'
                          >
                            <div className='dataTables_sizing h-0 overflow-hidden'>Nom</div>
                          </th>
                          <th
                            className='min-w-10px w-116px sorting_disabled pt-0 pd-0 border-top-width-0 border-botton-width-0 h-0'
                            rowSpan='1'
                            colSpan='1'
                          >
                            <div className='dataTables_sizing h-0 overflow-hidden'>Taille</div>
                          </th>
                          <th
                            className='min-w-125px w-325px sorting_disabled pt-0 pd-0 border-top-width-0 border-botton-width-0 h-0'
                            rowSpan='1'
                            colSpan='1'
                          >
                            <div className='dataTables_sizing h-0 overflow-hidden'>
                              Dernière modification
                            </div>
                          </th>
                          <th
                            className='w-125px sorting_disabled sorting_disabled pt-0 pd-0 border-top-width-0 border-botton-width-0 h-0'
                            rowSpan='1'
                            colSpan='1'
                          >
                            <div className='dataTables_sizing h-0 overflow-hidden'></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='fw-bold text-gray-600'>
                        {filtersItems.map((file, index) => (
                          <tr className='even' key={index}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input' type='checkbox' value='1' />
                              </div>
                            </td>
                            <td data-order='account'>
                              <div className='d-flex align-items-center'>
                                <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                                  {getIconForFileType(file.libelle)}
                                </span>
                                <div className='text-gray-800 text-hover-primary'>
                                  {file.libelle}
                                </div>
                              </div>
                            </td>
                            <td data-order=''>{file.size} KO</td>
                            <td data-order=''>
                              {moment(file.updatedAt).format('DD/MM/YYYY à HH:mm')}
                            </td>
                            <td data-kt-filemanager-table='action_dropdown'>
                              <button
                                type='button'
                                className='btn btn-light-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_add_user'
                                style={{marginRight: '10px'}}
                                // disabled={loading}
                                onClick={() =>
                                  downloadDocumentsClient(
                                    file.client_bo,
                                    file.client_lea,
                                    file.fichier
                                  )
                                }
                              >
                                <span className='svg-icon svg-icon-2'>
                                  <DownloadOutlined style={{fontSize: '10px'}} />
                                </span>
                              </button>
                              {/*<CustomMenu  menuItems={menuItems}/>*/}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
        open={isModalOpen}
        onCancel={handleCloseModal}
        className='mx-auto mh-auto align-center '
        width={800}
        title={'Enregistrer une nouvelle norme'}
        footer=""
      >
        <FileUploader onClose={handleCloseModal} fetchFile={fetchDocuments} />
      </Modal>
        // <div
        //   className='top-0 bottom-0 z-index-1000 h-100'
        //   style={{
        //     insetInlineEnd: '0',
        //     insetInlineStart: '0',
        //     backgroundColor: 'rgba(0, 0, 0, 0.45)',
        //   }}
        // >
        //   <div
        //     className='modal fade show'
        //     id='kt_modal_move_to_folder'
        //     tabIndex='-1'
        //     aria-modal='true'
        //     role='dialog'
        //     style={{
        //       display: 'block',
        //       insetInlineEnd: '0',
        //       insetInlineStart: '0',
        //       backgroundColor: 'rgba(0, 0, 0, 0.45)',
        //     }}
        //   >
        //     <div
        //       className='modal fade show'
        //       id='kt_modal_upload'
        //       tabIndex='-1'
        //       aria-modal='true'
        //       role='dialog'
        //       style={{display: 'block'}}
        //     >
        //       {/*<FileUploadModal onClose={handleCloseModal} client={client} />*/}
        //       <FileUploader onClose={handleCloseModal} fetchFile={fetchDocuments} />
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  )
}

export default NormeISA
