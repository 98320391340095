import React, {useState} from 'react'
import {
  CloseSquareOutlined,
  DeleteFilled,
  DeleteRowOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordFilled,
} from '@ant-design/icons'
import {uploadNormesISA} from '../../core/file/_requests'
import swal from 'sweetalert'
import { Select } from 'antd'
// import {uploadFormulaireQuestionMission} from "../../data/file";

const sommaires = [
  'EB1',
  'EB2',
  'EB3',
  'EC1',
  'EC2',
  'EC3',
  'EC4',
  'ED1',
  'ED2',
  'ED3',
  'ED4',
  'ED5',
  'EE1',
  'EE2',
  'EE3',
  'EF1',
  'EF2',
  'EF3',
  'EF4',
  'EG1.1',
  'EG1.2',
  'EG1.4',
  'EG1.5',
  'EG1.6',
  'EG1.7',
  'EG1.8',
  'EG1.9',
  'EG1.10',
  'EG2.1',
  'EG2.2',
  'EG2.3',
  'EG2.4',
  'EG2.5',
  'EG2.6',
  'EG2.7',
  'EH1',
  'EH2',
  'EH3',
  'EI1',
  'EI2',
  'EI3',
  'EI4',
  'EI5',
  'EI6',
  'EI7',
]

const FileUploader = ({onClose, fetchFile}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [codeSommaire, setCodeSommaire] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')

  const handleFileChange = (event) => {
    const files = event.target.files

    const fileSizeLimit = 10 * 1024 * 1024

    const validFiles = Array.from(files).filter((file) => file.size <= fileSizeLimit)

    for (const file of files) {
      setLibelle(file.name)
    }

    setSelectedFiles([...selectedFiles, ...validFiles])
  }
  //
  const handleUploadAll = async (e) => {
    e.preventDefault()
    setMessage('')
    setError(false)
    const fileSizeLimit = 10 * 1024 * 1024
    for (const file of selectedFiles) {
      if (file.size <= fileSizeLimit) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('libelle', libelle)
        formData.append('codeSommaire', codeSommaire)
        formData.append('description', description)

        try {
          const response = await uploadNormesISA(formData)
          if (response.status === 'success') {
            setLibelle('')
            setDescription('')
            setCodeSommaire([])
            setMessage('')
            fetchFile()
            await swal('Opération éffectuée avec succès', '', 'success')
            console.log('File uploaded successfully!')
          } else {
            await swal("Echec d'importation", '', 'error')
            setError(true)
            setMessage(response.message)
            console.log('Failed to upload file: ' + file.name)
          }
        } catch (error) {
          console.error('Error uploading file:', error)
          console.log('Failed to upload file: ' + file.name)
        }
      } else {
        console.log('File size exceeds the limit: ' + file.name)
      }
    }

    setSelectedFiles([])
  }

  const handleRemoveAll = () => {
    setSelectedFiles([])
    setLibelle('')
    setDescription('')
  }

  const getIconForFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return <FilePdfOutlined style={{fontSize: '25px', color: 'red'}} />
      case 'doc':
      case 'docx':
      case 'txt':
        return <FileWordFilled style={{fontSize: '25px', color: 'blue'}} />
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <FileImageOutlined style={{fontSize: '25px', color: 'blue'}} />
      default:
        return <FileOutlined style={{fontSize: '25px', color: 'blue'}} />
    }
  }

  return (
    <>
      <div className='card mw-650px'>
        <div className='card-content'>
          <form className='form' id='kt_modal_upload_form' onSubmit={(e) => e.preventDefault()}>
            {/* <div className='card-header'>
              <h2 className='fw-bolder'>{title}</h2>
              <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={onClose}>
                <span className='svg-icon svg-icon-1'>
                  <CloseSquareOutlined style={{color: 'red'}} />
                </span>
              </div>
            </div> */}
            <div className='card-body scrool-y pt-5 pb-7 px-lg-7'>
              {error ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{message}</div>
                </div>
              ) : (
                ''
              )}

              <div className='form-group'>
                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                  <div className='d-flex align-center'>
                    <div className='me-5'>
                      <div className='w-500px d-flex align-items-center mb-2 mt-2 px-10'>
                        <input
                          type='file'
                          className='form-control me-10'
                          // accept=".xlsx, .csv, .xls"
                          onChange={handleFileChange}
                          required={true}
                        />
                      </div>
                      <span className='form-text fs-6 text-muted'>
                        Taille maximale de fichier 10MB.
                      </span>
                    </div>
                    <div className='pt-2'>
                      <button
                        className='dropzone-remove-all btn btn-sm btn-light-primary'
                        onClick={handleRemoveAll}
                      >
                        <DeleteFilled style={{color: 'red', fontSize: '15px'}} />
                      </button>
                    </div>
                  </div>

                  <div className='dropzone-items wm-200px'>
                    {/* Show the list of selected files */}
                    {selectedFiles.map((file, index) => (
                      <div key={index} className='m-6'>
                        {getIconForFileType(file.name)} {file.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                  <div className='col-md-3 text-md-end'>
                    {/*begin::Label*/}
                    <label className='fs-6 fw-bold form-label mt-3'>
                      <span>Code Sommaire</span>
                    </label>
                    {/*end::Label*/}
                  </div>
                  <div className='col-md-9'>
                    {/*begin::Input*/}
                    {/* <select
                        style={{fontSize: '13px'}}
                        className="form-select mb-2"
                        data-control="select2"
                        data-select2-id="select2-data-16-9pfx" tabIndex="-1"
                        value={codeSommaire}
                        onChange={(e) => setCodeSommaire(e.target.value)}
                    >
                        <option value=''>Selectionner un code...</option>
                        { sommaires.map((opt, index) => (
                            <option value={index}>
                              {opt}
                            </option>
                        ))}
                    </select> */}
                    <Select
                      mode='tags'
                      style={{width: '100%'}}
                      placeholder='choisir les codes'
                      onChange={setCodeSommaire}
                      value={codeSommaire}
                      options={sommaires.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                    {/*end::Input*/}
                  </div>
                </div>

                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                  <div className='col-md-3 text-md-end'>
                    {/*begin::Label*/}
                    <label className='fs-6 fw-bold form-label mt-3'>
                      <span>Nom du fichier</span>
                    </label>
                    {/*end::Label*/}
                  </div>
                  <div className='col-md-9'>
                    {/*begin::Input*/}
                    <input
                      type='text'
                      className='form-control'
                      name='libelle'
                      onChange={(e) => setLibelle(e.target.value)}
                      value={libelle}
                    />
                    {/*end::Input*/}
                  </div>
                </div>

                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                  <div className='col-md-3 text-md-end'>
                    {/*begin::Label*/}
                    <label className='fs-6 fw-bold form-label mt-3'>
                      <span>Description</span>
                    </label>
                    {/*end::Label*/}
                  </div>
                  <div className='col-md-9'>
                    {/*begin::Input*/}
                    <textarea
                      className={`form-control mb-2`}
                      rows='50'
                      cols='100'
                      maxLength={1000}
                      style={{
                        height: '70px',
                        marginBottom: 24,
                      }}
                      // onChange={onChange}
                      // value={mesure_sauve}
                      placeholder='Votre texte ici...'
                    ></textarea>
                    {/*end::Input*/}
                  </div>
                  <div className='col-md-9'>
                    <button
                      className='btn btn-light-primary text-hover-primary btn-md'
                      onClick={handleUploadAll}
                    >
                      {' '}
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {FileUploader}
