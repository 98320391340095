const QUERIES = {
  USERS_LIST: 'users-list',
  PROFIL_LIST: 'profild-list',
}

// min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
const PASSWORDRULES = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

const PERMISSION = {
  level_1: 'Administrateur',
  level_2: 'Gestionnaire',
  level_3: 'Revendeur',
  level_4: 'Partenaire',
  level_6: 'Comptable',
  // level_7: 'Administrateur',
}

export {QUERIES, PASSWORDRULES, PERMISSION}
