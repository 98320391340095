import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../core/layout'
import ClientList from '../../components/clients/ClientList'
import ClientCreate from '../../components/clients/ClientAdd'
import ClientEdit from '../../components/clients/ClientEdit'
import ClientInfo from '../../components/clients/modals/InfosClient'
import FactureClient from '../facture/facturations/FactureClient'
import PaiementClient from '../../components/clients/PaiementClient'
import PaiementClientPrint from '../../components/clients/paiementClientPrint'

const clientsBreadcrumbs = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Retour',
    path: '/dashboard',
    isSeparator: true,
    isActive: false,
  },
]

const ClientsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='liste'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Liste des client</PageTitle>
              <ClientList />
            </>
          }
        />
        <Route
          path='ajout'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Enregistrer un nouveau client</PageTitle>
              <ClientCreate />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Modifier le client</PageTitle>
              <ClientEdit />
            </>
          }
        />
        <Route
          path='infos/:id'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Détails du client</PageTitle>
              <ClientInfo />
            </>
          }
        />
        <Route
          path='facture/:id'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Détails du client</PageTitle>
              <FactureClient />
            </>
          }
        />
        <Route
          path='reglement/:id'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Reglement client</PageTitle>
              <PaiementClient />
            </>
          }
        />
        <Route
          path='reglement/print/:id'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Reglement client</PageTitle>
              <PaiementClientPrint />
            </>
          }
        />

        <Route index element={<Navigate to='/apps/clients/liste' />} />
      </Route>
    </Routes>
  )
}

export default ClientsPage
