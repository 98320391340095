import React, {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useReactToPrint} from 'react-to-print'
import {PrinterOutlined} from '@ant-design/icons'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import {getListeParams} from '../../../data/params/paramsGlobaux'
import {NumberToLetter} from 'convertir-nombre-lettre'

function FactureClient() {
  const [tva, setTva] = useState(0)
  const [devise, setDevise] = useState(0)
  const [details, setDetails] = useState({})
  const [client, setClient] = useState({})
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  let location = useLocation()
  const facture = location.state.record

  useEffect(() => {
    if (facture.abonnement) {
      setDetails(facture.abonnement)
    } else {
      setDetails(facture)
    }
    setClient(facture)
  }, [setDetails, setClient])

  useEffect(() => {
    getListeParams()
      .then((res) => {
        const taux = res.result.find((parametre) => parametre.libelle === 'VALEUR_TVA')
        const devise = res.result.find((parametre) => parametre.libelle === 'DEVISE_EGOU')
        if (taux) {
          const tvaValeur = taux.valeur
          setTva(tvaValeur)
        }
        if (devise) {
          const deviseValeur = devise.valeur
          setDevise(deviseValeur)
        }
      })
      .catch((err) => console.log(err))
  }, [setTva])

  return (
    <div className='post d-flex flex-column-fluid' id='kt_post'>
      <div id='kt_content_container' className='container-xxl'>
        <div ref={componentRef} className='card'>
          <div className='card-body p-lg-20'>
            <div className='d-flex flex-column flex-xl-row'>
              <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
                <div className='mt-n1'>
                  <div className='d-flex flex-stack pb-10'></div>
                  <div className='m-0'>
                    <div className='fw-bolder fs-3 text-gray-800 mb-8'>
                      Facture #{details?.numero}
                    </div>
                    <div className='row g-5 mb-11'>
                      <div className='col-sm-6'>
                        <a href='#'>
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/logo_egouexpert.png')}
                            className='h-75px'
                          />
                        </a>
                        {/*<div className='fw-bolder fs-6 text-gray-800'>EgouExpert.</div>*/}
                        {/*<div className='fw-bold fs-7 text-gray-600'>*/}
                        {/*  8692 Wild Rose Drive*/}
                        {/*  <br />*/}
                        {/*  Livonia, MI 48150*/}
                        {/*</div>*/}
                      </div>
                      <div className='col-sm-6'>
                        <div className='' style={{paddingBottom: '20px'}}>
                          <div className='fw-bolder fs-6 text-gray-800'>
                            Nom: {client.nom_cabinet}.
                          </div>
                          <div className='fw-bold fs-7 text-gray-600'>
                            CC: {client?.numero_cc}.
                            <br />
                            Tel:{client.cel1}
                          </div>
                          <div className='fw-bold fs-7 text-gray-600'>
                            Localisation: {client?.localisation}.{/*<br />*/}
                            {/*Matthews, NC 28104*/}
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className='fw-bold fs-7 text-gray-600 mb-1'>Etablit par:</div>
                          <div className='fw-bolder fs-6 text-gray-800'>
                            {client?.nom_user + ' ' + client?.prenom_user}
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div className='fw-bold fs-7 text-gray-600 mb-1'>Date:</div>
                          <div className='fw-bolder fs-6 text-gray-800'>
                            {moment(details?.createdAt).format('DD/MM/Y')} à{' '}
                            {moment(details?.createdAt).format('h:m:s')}
                          </div>
                        </div>
                        <div className='fw-bold fs-7 text-gray-600 mb-1'>
                          Date fin souscription:
                        </div>
                        <div className='fw-bolder fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                          <span className='pe-2'>
                            {moment(details?.date_fin_abonmoment).format('DD/MM/Y')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='row g-5 mb-12'>
                      <div className='col-sm-6'></div>
                    </div>
                    <div className='flex-grow-1'>
                      <div className='table-responsive border-bottom mb-9'>
                        <table className='table mb-3'>
                          <thead>
                            <tr className='border-bottom fs-6 fw-bolder '>
                              <th className='min-w-175px pb-2'>Désignation</th>
                              <th className='min-w-70px text-end pb-2'>Qté</th>
                              <th className='min-w-80px text-end pb-2'>Prix HT</th>
                              <th className='min-w-100px text-end pb-2'>Prix Total HT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='fw-bolder text-gray-700 fs-5 text-end'>
                              <td className='d-flex align-items-center pt-6'>
                                <i className='fa fa-genderless text-success fs-2 me-2'></i>
                                {`${details?.libelle_formule}(${details?.libelle_categorie})`}
                              </td>
                              <td className='pt-6'>1</td>
                              <td className='pt-6'>
                                {' '}
                                {parseInt(details?.montant_ht).toLocaleString('fr') + ` ${devise}`}
                              </td>
                              <td className='pt-6'>
                                {' '}
                                {parseInt(details?.montant_ht).toLocaleString('fr') + ` ${devise}`}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div className='mw-200px'>
                          {details?.etat_facture === 2 ? (
                            <img
                              src={toAbsoluteUrl(`/media/svg/paid_stamp_payee.svg`)}
                              alt=''
                              className='w-75'
                            />
                          ) : details?.etat_facture === 1 ? (
                            <img
                              src={toAbsoluteUrl(`/media/svg/paid_stamp_partiellement.svg`)}
                              alt=''
                              className='w-75'
                            />
                          ) : (
                            <img
                              src={toAbsoluteUrl(`/media/svg/paid_stamp_impayee.svg`)}
                              alt=''
                              className='w-75'
                            />
                          )}
                        </div>
                        <div className='mw-300px'>
                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-bold pe-10 text-gray-600 fs-7'>Total HT:</div>
                            <div className='text-end fw-bolder fs-6 text-gray-800'>
                              {' '}
                              {parseInt(details?.montant_ht).toLocaleString('fr') + ` ${devise}`}
                            </div>
                          </div>
                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-bold pe-10 text-gray-600 fs-7'>Total Remise</div>
                            <div className='text-end fw-bolder fs-6 text-gray-800'>
                              {parseInt(details?.montant_remise).toLocaleString('fr') +
                                ` ${devise}`}
                            </div>
                          </div>
                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-bold pe-10 text-gray-600 fs-7'>Total HT Remise</div>
                            <div className='text-end fw-bolder fs-6 text-gray-800'>
                              {' '}
                              {parseInt(details?.montant_ht_remise).toLocaleString('fr') +
                                ` ${devise}`}
                            </div>
                          </div>
                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-bold pe-10 text-gray-600 fs-7'>
                              Montant TVA({tva}%)
                            </div>
                            <div className='text-end fw-bolder fs-6 text-gray-800'>
                              {' '}
                              {parseInt(details?.montant_tva).toLocaleString('fr') + ` ${devise}`}
                            </div>
                          </div>
                          <div className='d-flex flex-stack'>
                            <div className='fw-bold pe-10 text-gray-600 fs-7'>Net à payer</div>
                            <div className='text-end fw-bolder fs-6 text-dark fw-boldest'>
                              {' '}
                              {parseInt(details?.montant_ttc).toLocaleString('fr') + ` ${devise}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='separator separator-dashed  pt-6'></div>
                  <div className='text-end fw-bolder fs-6 text-gray-700 align-items-center  pt-6'>
                    <span style={{fontWeight: '700', fontSize: '14px'}} className='pe-2'>
                      Arrêtée la présente facture en {` ${devise}`} à la somme de :{' '}
                      {details?.montant_ttc ? NumberToLetter(details?.montant_ttc) : '0'}{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-stack flex-wrap mt-lg-20 pt-13'>
          <div className='my-1 me-5'>
            <button type='button' className='btn btn-success my-1 me-12' onClick={handlePrint}>
              <PrinterOutlined />
              Imprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FactureClient
