import axios from 'axios'
import {
  AJOUTER_PROGRAMME,
  LISTE_PROGRAMME,
  LISTE_PROGRAMME_ACTIVE,
  MODIFIER_PROGRAMME,
  MODIFIER_ETAT_PROGRAMME,
  SUPPRIMER_PROGRAMME,
} from '../../api/apiRoute'

const AJOUTER_PROGRAMME_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/ajouter-programme-audit-initial`
const MODIFIER_PROGRAMME_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/modifier-programme-audit-initial`
const MODIFIER_ETAT_PROGRAMME_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/modifier-etat-programme-audit-initial`
const SUPPRIMER_PROGRAMME_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/supprimer-programme-audit-initial`
const LISTE_PROGRAMME_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/liste-programme-audit-initial`
const LISTE_PROGRAMME_ACTIVE_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/liste-programme-audit-initial-active`


// TRAVAUX PROFESSIONNELS
const AJOUTER_PROGRAMME_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/params/ajouter-programme-travaux-professionnel`
const MODIFIER_PROGRAMME_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/params/modifier-programme-travaux-professionnel`
const MODIFIER_ETAT_PROGRAMME_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/params/modifier-etat-programme-travaux-professionnel`
const SUPPRIMER_PROGRAMME_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/params/supprimer-programme-travaux-professionnel`
const LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/params/liste-programme-travaux-professionnel`
const LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-programme-travaux-professionnel-active`


//CONTOLE FIN DE MISSION
const AJOUTER_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/params/ajouter-questions-fin-mission`
const MODIFIER_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/params/modifier-questions-fin-mission`
const MODIFIER_ETAT_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-questions-fin-mission`
const SUPPRIMER_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/params/supprimer-questions-fin-mission`
const LISTE_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/params/liste-questions-fin-mission`
const LISTE_CONTROLE_FIN_MISSION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-questions-fin-mission-active`

export const addProgramme = async (data) => {
  return axios
    .post(AJOUTER_PROGRAMME, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateProgramme = async (data) => {
  return axios
    .post(MODIFIER_PROGRAMME, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProgramme = async () => {
  return axios
    .get(LISTE_PROGRAMME)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProgrammeActive = async () => {
  return axios
    .get(LISTE_PROGRAMME_ACTIVE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteProgramme = async (data) => {
  return axios
    .post(SUPPRIMER_PROGRAMME, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatProgramme = async (data) => {
  return axios
    .post(MODIFIER_ETAT_PROGRAMME, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const addProgrammeAuditInitial = async (data) => {
  return axios
    .post(AJOUTER_PROGRAMME_AUDIT_INITIAL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateProgrammeAuditInitial = async (data) => {
  return axios
    .post(MODIFIER_PROGRAMME_AUDIT_INITIAL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProgrammeAuditInitial = async () => {
  return axios
    .get(LISTE_PROGRAMME_AUDIT_INITIAL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProgrammeAuditInitialActive = async () => {
  return axios
    .get(LISTE_PROGRAMME_ACTIVE_AUDIT_INITIAL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteProgrammeAuditInitial = async (data) => {
  return axios
    .post(SUPPRIMER_PROGRAMME_AUDIT_INITIAL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatProgrammeAuditInitial = async (data) => {
  return axios
    .post(MODIFIER_ETAT_PROGRAMME_AUDIT_INITIAL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}





export const addProgrammeTravauxProfessionnel = async (data) => {
  return axios
    .post(AJOUTER_PROGRAMME_TRAVAUX_PROFESSIONNEL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateProgrammeTravauxProfessionnel = async (data) => {
  return axios
    .post(MODIFIER_PROGRAMME_TRAVAUX_PROFESSIONNEL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProgrammeTravauxProfessionnel = async () => {
  return axios
    .get(LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProgrammeTravauxProfessionnelActive = async () => {
  return axios
    .get(LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL_ACTIVE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteProgrammeTravauxProfessionnel = async (data) => {
  return axios
    .post(SUPPRIMER_PROGRAMME_TRAVAUX_PROFESSIONNEL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatProgrammeTravauxProfessionnel = async (data) => {
  return axios
    .post(MODIFIER_ETAT_PROGRAMME_TRAVAUX_PROFESSIONNEL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}




export const addControleFinMission = async (data) => {
  return axios
    .post(AJOUTER_CONTROLE_FIN_MISSION, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateControleFinMission = async (data) => {
  return axios
    .post(MODIFIER_CONTROLE_FIN_MISSION, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeControleFinMission = async () => {
  return axios
    .get(LISTE_CONTROLE_FIN_MISSION)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeControleFinMissionActive = async () => {
  return axios
    .get(LISTE_CONTROLE_FIN_MISSION_ACTIVE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteControleFinMission = async (data) => {
  return axios
    .post(SUPPRIMER_CONTROLE_FIN_MISSION, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatControleFinMission = async (data) => {
  return axios
    .post(MODIFIER_ETAT_CONTROLE_FIN_MISSION, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
