import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from '../components/layout/aside/AsideDefault'
import {Footer} from '../components/layout/Footer'
import {HeaderWrapper} from '../components/layout/header/HeaderWrapper'
import {Toolbar} from '../components/layout/toolbar/Toolbar'
import {ScrollTop} from '../components/layout/ScrollTop'
import {Content} from '../components/layout/Content'
import {PageDataProvider} from '../core/layout'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div
            id='kt_content'
            className='content d-flex flex-column flex-column-fluid'
            style={{backgroundColor: '#f8f8f8'}}
          >
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
