import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../helpers'
import {User} from '../users/_models'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_CLIENT_URL = `${API_URL}/client/add-client`
const EDITED_CLIENT_URL = `${API_URL}/client/modifier-client`
const GET_CLIENT_BY_ID = `${API_URL}/user/select-user`
const GET_CLIENTS_URL = `${API_URL}/client/liste-client`
const DELETE_CLIENTS_URL = `${API_URL}/client/supprimer-client`
const UPDATE_CLIENTS_STATE_URL = `${API_URL}/client/modifier-etat-client`
const CLOTURE_ABONNEMENT_CLIENT_URL = `${API_URL}/client/cloturer-abonnement-client`
const LISTE_ABONNEMENT_CLIENT_URL = `${API_URL}/client/liste-abonnement-client`
const ABONNEMENT_CLIENT_URL = `${API_URL}/client/reabonnement-client`
const ACCESS_CLIENT_URL = `${API_URL}/client/creer-cles-acces-client`
const GET_ABONNEMENT_CLIENT_URL = `${API_URL}/client/facture-abonnement-client`
const GET_APPORTEUR_CLIENT_URL = `${API_URL}/client/liste-abonnement-client`

export const addClient = async (data) => {
  return axios
    .post(CREATE_CLIENT_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const ModifierClient = async (data) => {
  return axios
    .post(EDITED_CLIENT_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getClients = async () => {
  return axios
    .get(GET_CLIENTS_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getClientById = async (id) => {
  return axios
    .get(GET_CLIENT_BY_ID, id)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteClient = async (data) => {
  return axios
    .post(DELETE_CLIENTS_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatClient = async (data) => {
  return axios
    .post(UPDATE_CLIENTS_STATE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const ClotureAbonnementClient = async (id) => {
  return axios
    .post(CLOTURE_ABONNEMENT_CLIENT_URL, id)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const ListeAbonnementClient = async () => {
  return axios
    .get(LISTE_ABONNEMENT_CLIENT_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const AbonnementClient = async (data) => {
  return axios
    .post(ABONNEMENT_CLIENT_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const AcccesClient = async (data) => {
  return axios
    .post(ACCESS_CLIENT_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const GetSingleFactureClient = async (id) => {
  return axios
    .get(GET_ABONNEMENT_CLIENT_URL, id)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
// export const GetApporteurClient = async (client_id) => {
//   return axios
//     .get(GET_APPORTEUR_CLIENT_URL, client_id)
//     .then((res) => {
//       if (res.status === 200) {
//         return res.data
//       } else {
//         return {
//           status: 'error',
//           message: 'La connexion à internet est instable !',
//         }
//       }
//     })
//     .catch((err) => console.log(err))
// }
export const GetApporteurClient = (client_id) => {
  return axios.get(`${GET_APPORTEUR_CLIENT_URL}/${client_id}`).then((response) => response.data)
}
