import axios from 'axios'
import {
  CREATE_PROFIL_LEA_URL, DELETE_PROFILS_LEA_URL, GET_PROFILS_LEA_URL, MODIFIER_ETAT_LEA_PROFIL, UPDATE_PROFIL_LEA_URL,
  
} from '../../api/apiRoute'

export const addProfilLea = async (data) => {
  return axios
    .post(CREATE_PROFIL_LEA_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateProfilLea = async (data) => {
  return axios
    .post(UPDATE_PROFIL_LEA_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProfilLea = async () => {
  return axios
    .get(GET_PROFILS_LEA_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteProfilLea = async (data) => {
  return axios
    .post(DELETE_PROFILS_LEA_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatProfilLea = async (data) => {
  return axios
    .post(MODIFIER_ETAT_LEA_PROFIL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
