import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuPage} from '../pages/dashboard/MenuPage'
import {MasterLayout} from '../layouts/MasterLayout'
import {getCSSVariableValue} from '../assets/ts/_utils'
import CivilitePage from '../pages/params/civilite/CivilitePage'
import PeriodePage from '../pages/params/periode/PeriodePage'
import {PageTitle} from '../core/layout'
import CategoriePage from '../pages/categories/categoriePage'

import PrivilegePage from '../pages/params/privileges/PrivilegePage'

import ProfilList from '../components/profils/ProfilList'
import PaysPage from '../pages/params/pays/PaysPage'
import ParametreGlobauxPage from '../pages/params/globaux/ParametresGlobauxPage'
import SecteurActivite from '../pages/params/secteur_activite/SecteurActivite'
import Banque from '../pages/params/banques/Banque'
import Unite from '../pages/params/unites/Unite'
import StatutPage from '../pages/params/statut/StatutPage'
import ModePage from '../pages/params/mode_paiement/ModePage'
import OperateurPage from '../pages/params/operateurs/OperateurPage'
import ClientsPage from '../pages/clients/ClientPages'
import AuditPage from '../pages/audit/AuditPage'
import CategoriFormule from '../pages/params/categorie_formule/CategoriFormule'
import Cycle from '../pages/params/cycle/Cycle'
import Assertion from '../pages/params/assertion/assertion'
import EvenementSourceDeRisque from '../pages/params/evenement_source_de_risque/EvenementSourceDeRisque'
import ImpactFinancier from '../pages/params/impact_financier/ImpactFinancier'
import NiveauDeRisque from '../pages/params/niveau_de_risque/NiveauDeRisque'
import ProbabiliteDeSurvenance from '../pages/params/probabilite_de_survenance/ProbabiliteDeSurvenance'
import RisqueInherent from '../pages/params/risque_inherent/RisqueInherent'
import Sommaire from '../pages/params/sommaire/sommaire'
import Utilisateur from '../pages/params/utilisateur/Utilisateur'
import Intervention from '../pages/params/intervention/intervention'
import GestionEquipeExercice from '../pages/params/gestion_equipe_exercice/GestionEquipeExercice'
import TacheAutorisee from '../pages/params/tache_autorisee/TacheAutorisee'
import QuestionnaireGeneral from '../pages/params/questionnaire_generale/QuestionnaireGeneral'
import ProgrammeDeTravail from '../pages/params/programme_de_travail/ProgrammeDeTravail'
import FormulePage from '../formule_abonnement/FormulePage'
import FacturePage from '../pages/facture/facturations/facture_page'
import Paiement from '../pages/paiment/paiment'
import RubriqueCycle from '../pages/params/rubrique_cycle/RubriqueCycle'
import QuestionCycle from '../pages/params/question_cycle/QuestionCycle'
import ProgrammeAuditInitial from '../pages/params/programme_de_travail/ProgrammeAuditInitial'
import ProgrammeTravauxProfessionnel from '../pages/params/programme_de_travail/ProgrammeTravauxProfessionnel'
import NormeISA from '../pages/params/normes/normes'
import ProflLea from '../pages/profilsLea/ProfilLea'
import VerificationSASARL from '../pages/params/verificationSA_SARL/VerificationSA_SARL'
import ControleFinMission from '../pages/params/controleFinMission/ControleFinMission'
import HonorairesPage from '../pages/honoraires/HonorairesPage'

const usersBreadcrumbs = [
  {
    title: 'Tableau de bord',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Retour',
    path: '/dashboard',
    isSeparator: true,
    isActive: false,
  },
]

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../pages/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../pages/users/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='auth/forgot-password' element={<Navigate to='/auth/forgot-password' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-pages' element={<MenuPage />} />
        {/* Lazy Modules */}
        <Route
          path='/apps/utilisateurs/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/profil/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='/apps/clients/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/souscriptions/*'
          element={
            <SuspensedView>
              <FormulePage />
            </SuspensedView>
          }
        />
        <Route
          path='/apps/honoraires/*'
          element={
            <SuspensedView>
              <HonorairesPage />
            </SuspensedView>
          }
        />

        {/*begin::Parmetre globale*/}
        <Route
          path='/apps/parametres/normes-isa'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des normes</PageTitle>
              <NormeISA />
            </>
          }
        />
        <Route
          path='/apps/parametres/civilites'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des civilité</PageTitle>
              <CivilitePage />
            </>
          }
        />
        <Route
          path='/apps/parametres/pays'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des pays</PageTitle>
              <PaysPage />
            </>
          }
        />

        <Route
          path='/apps/parametres/periodes'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des périodes</PageTitle>
              <PeriodePage />
            </>
          }
        />

        <Route
          path='/apps/users/profils'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des Profils</PageTitle>
              <ProfilList />
            </>
          }
        />
        
        <Route
          path='/apps/users/profils-lea'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des Profils du LEA</PageTitle>
              <ProflLea />
            </>
          }
        />

        <Route
          path='/apps/parametres/categories'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des Categorie</PageTitle>
              <CategoriePage />
            </>
          }
        />

        <Route
          path='/apps/parametres/privileges'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des Privilèges</PageTitle>
              <PrivilegePage />
            </>
          }
        />

        <Route
          path='/apps/parametres/globaux'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des paramètres globaux</PageTitle>
              <ParametreGlobauxPage />
            </>
          }
        />

        <Route
          path='/apps/parametres/secteurs-activites'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des secteurs d'activités</PageTitle>
              <SecteurActivite />
            </>
          }
        />

        <Route
          path='/apps/parametres/banques'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des banques</PageTitle>
              <Banque />
            </>
          }
        />

        <Route
          path='/apps/parametres/unites'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des unités</PageTitle>
              <Unite />
            </>
          }
        />

        <Route
          path='/apps/parametres/statut'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des statuts juridiques</PageTitle>
              <StatutPage />
            </>
          }
        />

        <Route
          path='/apps/parametres/mode-de-paiement'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des modes de paiements</PageTitle>
              <ModePage />
            </>
          }
        />

        <Route
          path='/apps/parametres/operateur-mobile'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des opérateurs mobiles</PageTitle>
              <OperateurPage />
            </>
          }
        />

        <Route
          path='/apps/audit-app'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Audit APP</PageTitle>
              <AuditPage />
            </>
          }
        />

        <Route
          path='/apps/parametres/categorie-formule'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des categories de formules</PageTitle>
              <CategoriFormule />
            </>
          }
        />
        <Route
          path='/apps/parametres/cycle'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des cycles</PageTitle>
              <Cycle />
            </>
          }
        />
        <Route
          path='/apps/parametres/rubrique-cycle'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des rubriques de cycle</PageTitle>
              <RubriqueCycle />
            </>
          }
        />
        <Route
          path='/apps/parametres/questions-cycle'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                Liste des questions sources de cycle
              </PageTitle>
              <QuestionCycle />
            </>
          }
        />

        <Route
          path='/apps/parametres/assertion'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des assertions</PageTitle>
              <Assertion />
            </>
          }
        />

        <Route
          path='/apps/parametres/evenement-source-de-risque'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Evenements source de risque</PageTitle>
              <EvenementSourceDeRisque />
            </>
          }
        />
        <Route
          path='/apps/parametres/impact_financier'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Impact financier</PageTitle>
              <ImpactFinancier />
            </>
          }
        />
        <Route
          path='/apps/parametres/niveau_de_risque'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Niveau de risque</PageTitle>
              <NiveauDeRisque />
            </>
          }
        />
        <Route
          path='/apps/parametres/probabilite_de_sourvenance'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Probabilité de sourvenance</PageTitle>
              <ProbabiliteDeSurvenance />
            </>
          }
        />
        <Route
          path='/apps/parametres/risque_inherent'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Risque inhérent</PageTitle>
              <RisqueInherent />
            </>
          }
        />
        <Route
          path='/apps/parametres/sommaire'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Sommaire</PageTitle>
              <Sommaire />
            </>
          }
        />
        <Route
          path='/apps/parametres/utilisateurs'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Utilisateur</PageTitle>
              <Utilisateur />
            </>
          }
        />
        <Route
          path='/apps/parametres/intervention'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Intervention</PageTitle>
              <Intervention />
            </>
          }
        />
        <Route
          path='/apps/parametres/gestion_equipe_exercice'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Gestion d'équipe exercice</PageTitle>
              <GestionEquipeExercice />
            </>
          }
        />

        <Route
          path='/apps/parametres/tache_autorisee'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Tâches autorisées</PageTitle>
              <TacheAutorisee />
            </>
          }
        />
        <Route
          path='/apps/formules-abonnement'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des formules d'abonnement</PageTitle>
              <FormulePage />
            </>
          }
        />

        <Route
          path='/apps/facture/liste_facture'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des factures</PageTitle>
              <FacturePage />
            </>
          }
        />

        <Route
          path='/apps/paiement/liste_paiement'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des paiements</PageTitle>
              <Paiement />
            </>
          }
        />
        <Route
          path='/apps/parametres/questionnaire_general'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Questionnaire general</PageTitle>
              <QuestionnaireGeneral />
            </>
          }
        />
        <Route
          path='/apps/parametres/programme_de_travail'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Programmes de travail</PageTitle>
              <ProgrammeDeTravail />
            </>
          }
        />
        <Route
          path='/apps/parametres/programme_audit_initial'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Programmes d'audit initial</PageTitle>
              <ProgrammeAuditInitial />
            </>
          }
        />
        <Route
          path='/apps/parametres/programme_travaux_professionnel'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Programmes d'audit initial</PageTitle>
              <ProgrammeTravauxProfessionnel />
            </>
          }
        />
        <Route
          path='/apps/parametres/programme_verification_specifique_sa_sarl'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Programmes Vérification spécifique SA/SARL</PageTitle>
              <VerificationSASARL />
            </>
          }
        />
        <Route
          path='/apps/parametres/controle-fin-mission'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Questionnaire de contrôle de fin de mission</PageTitle>
              <ControleFinMission />
            </>
          }
        />

        {/*begin::Parmetre globale*/}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
