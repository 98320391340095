import axios from 'axios'

const LISTE_RUBRIQUE_CYCLE = `${process.env.REACT_APP_API_URL}/params/liste-rubrique-cycle`
const AJOUTER_RUBRIQUE_CYCLE = `${process.env.REACT_APP_API_URL}/params/ajouter-rubrique-cycle`
const MODIFIER_RUBRIQUE_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-rubrique-cycle`
const SUPPRIMER_RUBRIQUE_CYCLE = `${process.env.REACT_APP_API_URL}/params/supprimer-rubrique-cycle`
const MODIFIER_RUBRIQUE_ETAT_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-rubrique-cycle`

export const addRubriqueCycle = async (data) => {
  return axios
    .post(AJOUTER_RUBRIQUE_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateRubriqueCycle = async (data) => {
  return axios
    .post(MODIFIER_RUBRIQUE_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeRubriqueCycle = async () => {
  return axios
    .get(LISTE_RUBRIQUE_CYCLE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeRubriqueByCycle = async (cycle_id) => {
  return axios
    .get(`${LISTE_RUBRIQUE_CYCLE}/${cycle_id}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteRubriqueCycle = async (data) => {
  return axios
    .post(SUPPRIMER_RUBRIQUE_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatRubriqueCycle = async (data) => {
  return axios
    .post(MODIFIER_RUBRIQUE_ETAT_CYCLE, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
