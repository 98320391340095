import {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag} from 'antd'

import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  addCategorie,
  deleteCategorie,
  getListeCategorie,
  updateCategorie,
  updateEtatCategorie,
} from '../../data/params/categorie'
import {DateComponent} from '../../components/liste/dateComponent'

const CategoriePage = () => {
  const [categories, setCategories] = useState([])
  const [categorie, setCategorie] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [search, setSearch] = useState('')

  const filteredItems = categories.filter(
    (item) =>
      item.libelle.toLowerCase().includes(search.toLowerCase()) ||
      item.description.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
  )

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => selectCategorie(record.id)}
          />

          {record.etat === 0 && (
            <Popconfirm
              title={`Activer la civilité ${record.libelle} ?`}
              description={`Voulez vous vraiment activer la categorie ${record.libelle} ?`}
              onConfirm={() => handleUpdateCategorie(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat === 1 && (
            <Popconfirm
              title={`Désactiver la civilité ${record.libelle} ?`}
              description={`Voulez vous vraiment désactiver la categorie ${record.libelle} ?`}
              onConfirm={() => handleUpdateCategorie(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la civilité ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer la categorie ${record.libelle} ?`}
            onConfirm={() => handleDeleteCategorie(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const showModal = () => {
    setOpen(true)
  }
  const handleAddCategorie = async () => {
    setLoading(true)
    const data = {libelle: libelle, description: description}
    console.log(data)
    const {status, message} = await addCategorie(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeCategorie()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierCategorie = async () => {
    setLoading(true)
    const data = {categorie_id: categorie.id, libelle: libelle, description: description}
    console.log(data)
    const {status, message} = await updateCategorie(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeCategorie()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setError(false)
  }

  const selectCategorie = (categorie_id) => {
    console.log(categorie_id)

    const categorie = categories.filter((categorie) => categorie.id === categorie_id)[0]
    setCategorie(categorie)
    setLibelle(categorie.libelle)
    setDescription(categorie.description)
    setEdite(true)
    setOpen(true)
  }

  const handleListeCategorie = async () => {
    setLoading(true)
    const {result, status, message} = await getListeCategorie()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setCategories(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteCategorie = async (categorie_id) => {
    setLoading(true)
    const data = {categorie_id}
    const {status, message} = await deleteCategorie(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeCategorie()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateCategorie = async (categorie_id, etat) => {
    setLoading(true)
    const data = {categorie_id, etat}
    const {status, message} = await updateEtatCategorie(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeCategorie()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleListeCategorie()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Categories enregistrées</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={(e) => setSearch(e.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer une nouvelle categorie' : 'Modifier la nouvelle categorie'}
        onOk={!edite ? handleAddCategorie : handleModifierCategorie}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddCategorie : handleModifierCategorie}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
        <p>
          <label className='bold'>Description</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder='Entrez la description'
          />
        </p>
      </Modal>
    </>
  )
}

export default CategoriePage
