import axios from 'axios'
import { AJOUTER_PRIVILEGE, LISTE_PRIVILEGE, MODIFIER_ETAT_PRIVILEGE, MODIFIER_PRIVILEGE, SUPPRIMER_PRIVILEGE } from '../../api/apiRoute'

export const addPrivilege = async (data) => {
    return axios.post(AJOUTER_PRIVILEGE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updatePrivilege = async (data) => {
    return axios.post(MODIFIER_PRIVILEGE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListePrivilege = async () => {
    return axios.get(LISTE_PRIVILEGE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deletePrivilege = async (data) => {
    return axios.post(SUPPRIMER_PRIVILEGE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatPrivilege = async (data) => {
    return axios.post(MODIFIER_ETAT_PRIVILEGE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}