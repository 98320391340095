import React, {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Tag, Progress} from 'antd'

import {
  ApiOutlined,
  BarsOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  HistoryOutlined,
  LockOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlusOutlined,
  ProfileOutlined,
  SolutionOutlined,
  UnlockOutlined,
} from '@ant-design/icons'

import {DateComponent} from '../liste/dateComponent'
import {KTSVG, optionsSwal, PERMISSION, toAbsoluteUrl} from '../../helpers'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import {deleteClient, getClients, updateEtatClient} from '../../core/clients/_requests'
import CompteClientAccess from './modals/CompteClientAccess'
import ApporteurAffaireModal from './modals/ClientApporteurAffaire'
import ClientAbonnement from './modals/ClientAbonnemnt'
import moment from 'moment'
import {useAuth} from '../../core/auth'

const ClientList = () => {
  const {currentUser} = useAuth()
  const [clients, setClients] = useState([])
  const [client, setClient] = useState({})
  const [abonnement, setAbonnement] = useState({})
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedModal, setSelectedModal] = useState(null)

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setError(false)
  }

  const selectCilient = (client_id, modal_id) => {
    console.log(client_id)

    const clts = clients.filter((clt) => clt.id === client_id)[0]
    const souscript = clients.filter((clt) => clt.id === client_id)[0]?.abonnement

    setClient(clts)
    setAbonnement(souscript)
    handleButtonClick(modal_id)
    setEdite(true)
    setOpen(true)
  }

  const handleButtonClick = (modalId) => {
    setSelectedModal(modalId)
  }

  const handleListeClient = async () => {
    setLoading(true)
    const {result, status, message} = await getClients()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setClients(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleListeClient()
  }, [])

  const handleDeleteClient = async (id) => {
    Swal.fire(optionsSwal).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        const {status, message} = await deleteClient({id})
        if (status === 'error') {
          await swal(`${message}`, '', 'warning')
        }
        setMessage(message)
        if (status === 'success') {
          setError(false)
          await handleListeClient()
          setLoading(false)
        } else {
          setError(true)
          setLoading(false)
        }
      }
    })
  }

  const handleUpdateClientEtat = async (id, etat) => {
    Swal.fire(optionsSwal).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        const data = {client_id: id, etat}
        const {status, message} = await updateEtatClient(data)
        if (status === 'error') {
          await swal(`${message}`, '', 'warning')
        }
        setMessage(message)
        if (status === 'success') {
          setError(false)
          await handleListeClient()
          setLoading(false)
        } else {
          setError(true)
          setLoading(false)
        }
      }
    })
  }

  const filteredItems = clients.filter(
    (item) =>
      item.nom_cabinet.toLowerCase().includes(search.toLowerCase()) ||
      item.nom_gerant.toLowerCase().includes(search.toLowerCase()) ||
      item.libelle_formule.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
  )

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      render: (_, record, index) => (
        <div className='d-flex align-items-center'>
          {/* begin:: Avatar */}
          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
            {record.avatar ? (
              <div className='symbol-label'>
                <img
                  src={toAbsoluteUrl(`/media/${record.logo_cabinet}`)}
                  alt={record.logo_cabinet}
                  className='w-100'
                />
              </div>
            ) : (
              <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                {record.nom_cabinet[0]}
              </div>
            )}
          </div>
          <div className='d-flex flex-column'>
            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
              {record.nom_cabinet}
            </a>
            <span style={{}}>
              <PhoneOutlined /> {record.cel1}
            </span>
            <span style={{}}>
              <MessageOutlined /> {record.cel2}
            </span>
            <span style={{}}>
              <EnvironmentOutlined /> {record.localisation}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Gerant',
      dataIndex: 'nom_gerant',
      key: 'nom_gerant',
      render: (_, record, index) => (
        <div className='d-flex align-items-center'>
          {/* begin:: Avatar */}
          <div className='d-flex flex-column'>
            <div className='badge badge-light fw-bolder' style={{fontSize: '15px'}}>
              {record?.nom_gerant}
            </div>
            <span style={{}}>
              <PhoneOutlined /> {record?.telephone_gerant}
            </span>
            <span style={{}}>
              <ProfileOutlined /> {record?.fonction_gerant}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Formule',
      dataIndex: 'libelle_formule',
      key: 'libelle_formule',
      render: (_, record, index) =>
        Object.keys(record?.abonnement).length !== 0 ? (
          <div className='d-flex align-items-center'>
            <div className='d-flex flex-column align-items-center'>
              <div className='badge badge-light-success fw-bolder'>
                {record?.abonnement?.libelle_formule}
              </div>

              <div className='text-gray-800 text-hover-primary mb-1 '>
                {record?.abonnement?.nom_apporteur + ' ' + record?.abonnement?.prenom_apporteur}
              </div>
              <Progress
                percent={parseInt(
                  Math.max(
                    0,
                    Math.min(
                      100,
                      ((moment() - moment(record?.abonnement?.date_debut_abon)) /
                        (moment(record?.abonnement?.date_fin_abon) -
                          moment(record?.abonnement?.date_debut_abon))) *
                        100
                    )
                  )
                )}
              />
            </div>
          </div>
        ) : (
          currentUser &&
          (currentUser.libelleProfil !== PERMISSION.level_3 ||
            currentUser.libelleProfil !== PERMISSION.level_4) && (
            <a href='#' className='menu-link px-5' onClick={() => selectCilient(record.id, 4)}>
              <SolutionOutlined style={{fontSize: '30px'}} />
              <span className='indicator-label' style={{paddingLeft: '15px'}}>
                Faire une souscription
              </span>
            </a>
          )
        ),
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record, index) =>
        record.etat === 1 ? <Tag color='green'>Activé</Tag> : <Tag color='red'>Désactivé</Tag>,
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <a
            href='#'
            className='btn btn-light btn-active-light-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Actions
            <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
          </a>
          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <Link
                className='menu-link px-5'
                to={`/apps/clients/edit/${record.id}`}
                state={{record}}
              >
                <ProfileOutlined />
                <span className='indicator-label' style={{paddingLeft: '15px'}}>
                  Voir fiche client
                </span>
              </Link>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <Link
                className='menu-link px-5'
                to={`/apps/clients/edit/${record.id}`}
                state={{record}}
              >
                <EditOutlined />
                <span className='indicator-label' style={{paddingLeft: '15px'}}>
                  Modifier infos
                </span>
              </Link>
            </div>
            {/*end::Menu item*/}

            {currentUser &&
              (currentUser.libelleProfil !== PERMISSION.level_3 ||
                currentUser.libelleProfil !== PERMISSION.level_4) && (
                <>
                  {/* begin::Menu item */}
                  {Object.keys(record?.abonnement).length !== 0 && (
                    <div className='menu-item px-3'>
                      <Link
                        className='menu-link px-5'
                        to={`/apps/clients/facture/${record.id}`}
                        state={{record}}
                      >
                        <FileDoneOutlined />
                        <span className='indicator-label' style={{paddingLeft: '15px'}}>
                          Imprimer facture
                        </span>
                      </Link>
                    </div>
                  )}
                  {/* end::Menu item */}

                  {/* begin::Menu item */}
                  <div className='menu-item px-3'>
                    <a
                      href='#'
                      className='menu-link px-5'
                      onClick={() => selectCilient(record.id, 3)}
                    >
                      <HistoryOutlined />
                      <span className='indicator-label' style={{paddingLeft: '15px'}}>
                        Historique Abonnement
                      </span>
                    </a>
                  </div>
                  {/* end::Menu item */}
                  {/* begin::Menu item */}
                  {Object.keys(record?.abonnement).length !== 0 &&
                    record?.abonnement.cloture === 1 && (
                      <div className='menu-item px-3'>
                        <a
                          href='#'
                          className='menu-link px-5'
                          onClick={() => selectCilient(record.id, 4)}
                        >
                          <FileSyncOutlined />
                          <span className='indicator-label' style={{paddingLeft: '15px'}}>
                            Réabonnement
                          </span>
                        </a>
                      </div>
                    )}
                  {/* end::Menu item */}
                  {currentUser && currentUser.libelleProfil !== PERMISSION.level_6 && (
                    <>
                      {/* begin::Menu item */}
                      <div className='menu-item px-3'>
                        <a
                          href='#'
                          className='menu-link px-5'
                          onClick={() => selectCilient(record.id, 2)}
                        >
                          <BarsOutlined />
                          <span className='indicator-label' style={{paddingLeft: '15px'}}>
                            Infos création
                          </span>
                        </a>
                      </div>
                      {/* end::Menu item */}
                      {/* begin::Menu item */}
                      <div className='menu-item px-3'>
                        <a
                          href='#'
                          className='menu-link px-5'
                          onClick={() => selectCilient(record.id, 1)}
                        >
                          <ApiOutlined />
                          <span className='indicator-label' style={{paddingLeft: '15px'}}>
                            Clés d'accès
                          </span>
                        </a>
                      </div>
                      {/* end::Menu item */}
                      {/* begin::Menu item */}
                      <div className='menu-item px-3'>
                        {record?.etat === 1 && (
                          <a
                            href='#'
                            className='menu-link px-5'
                            onClick={() => handleUpdateClientEtat(record.id, 0)}
                          >
                            <LockOutlined />
                            <span className='indicator-label' style={{paddingLeft: '15px'}}>
                              Désactiver
                            </span>
                          </a>
                        )}
                        {record?.etat === 0 && (
                          <a
                            href='#'
                            className='menu-link px-5'
                            onClick={() => handleUpdateClientEtat(record.id, 1)}
                          >
                            <UnlockOutlined />
                            <span className='indicator-label' style={{paddingLeft: '15px'}}>
                              Activer
                            </span>
                          </a>
                        )}
                      </div>
                      {/* end::Menu item */}
                      {/* begin::Menu item */}
                      <div className='menu-item px-3'>
                        <a
                          href='#'
                          className='menu-link px-5'
                          onClick={() => handleDeleteClient(record.id)}
                        >
                          <DeleteOutlined style={{color: 'red'}} />
                          <span
                            className='indicator-label'
                            style={{paddingLeft: '15px', color: 'red'}}
                          >
                            Supprimer
                          </span>
                        </a>
                      </div>
                    </>
                  )}
                </>
              )}
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </>
      ),
    },
  ]

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Clients enregistrés</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={(e) => setSearch(e.target.value)}
                class='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <Button type='primary' icon={<PlusOutlined />} size='large'>
              <Link
                type='primary'
                className='menu-link px-5'
                to={`/apps/clients/ajout`}
                style={{color: 'white'}}
              >
                {' '}
                Ajouter
              </Link>
            </Button>

            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
        <Modal
          open={open}
          title={''}
          onCancel={handleCancel}
          width={800}
          footer={[
            <Button key='back' onClick={handleCancel}>
              Annuler
            </Button>,
          ]}
        >
          {error === true && (
            <Space
              direction='vertical'
              style={{
                width: '100%',
              }}
            >
              <Alert message={message} type='error' showIcon />
            </Space>
          )}
          {selectedModal === 1 && (
            <CompteClientAccess client={client} handleUpdate={handleListeClient} />
          )}
          {selectedModal === 2 && (
            <DateComponent
              date={client.createdAt}
              user={client.nom_user + ' ' + client.prenom_user}
            />
          )}
          {selectedModal === 3 && <ApporteurAffaireModal client={client} client_id={client.id} />}
          {selectedModal === 4 && (
            <ClientAbonnement client={client} handleUpdate={handleListeClient} />
          )}
        </Modal>
      </div>
    </>
  )
}

export default ClientList
