import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_USER_URL = `${API_URL}/user/add-user`
const EDITED_USER_URL = `${API_URL}/user/modifier-user`
const GET_USER_BY_ID = `${API_URL}/user/select-user`
const GET_USERS_URL = `${API_URL}/user/list-user`
const GET_REVENDEURS_USERS_URL = `${API_URL}/user/liste-revendeur`
const DELETE_USERS_URL = `${API_URL}/user/supprimer-user`
const UPDATE_USERS_STATE_URL = `${API_URL}/user/modifier-etat-user`
const UPDATE_USERS_ACCES_DISTAN_URL = `${API_URL}/user/modifier-etat-acces-distant-user`
const UPDATE_USERS_ACCES_MOBILE_URL = `${API_URL}/user/modifier-etat-version-mobile-user`
const UPDATE_USERS_ACCES_WEB_URL = `${API_URL}/user/modifier-etat-version-web-user`
const GENERATE_USERS_PASSWORD_URL = `${API_URL}/user/generer-mot-de-passe-user`
const UPDATE_USERS_PASSWORD_URL = `${API_URL}/user/modifier-mot-de-passe-user`

export const addUser = async (data) => {
  return axios
    .post(CREATE_USER_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const ModifierUser = async (data) => {
  return axios
    .post(EDITED_USER_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const ModiferMotPasse = async (data) => {
  return axios
    .post(UPDATE_USERS_PASSWORD_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getUsers = async () => {
  return axios
    .get(GET_USERS_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getRevendeurUsers = async () => {
  return axios
    .get(GET_REVENDEURS_USERS_URL)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteUser = async (data) => {
  return axios
    .post(DELETE_USERS_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatUser = async (data) => {
  return axios
    .post(UPDATE_USERS_STATE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatAcessDistantUser = async (data) => {
  return axios
    .post(UPDATE_USERS_ACCES_DISTAN_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatAcessWebUser = async (data) => {
  return axios
    .post(UPDATE_USERS_ACCES_WEB_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatAcessMobileUser = async (data) => {
  return axios
    .post(UPDATE_USERS_ACCES_MOBILE_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const GenererPasswordUser = async (data) => {
  return axios
    .post(GENERATE_USERS_PASSWORD_URL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
