import axios from 'axios'
import {
    AJOUTER_TACHE,
    LISTE_TACHE, LISTE_TACHE_ACTIVE,  
    MODIFIER_TACHE,
    MODIFIER_ETAT_TACHE,  SUPPRIMER_TACHE
} from '../../api/apiRoute'

export const addTache = async (data) => {
    return axios.post(AJOUTER_TACHE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateTache = async (data) => {
    return axios.post(MODIFIER_TACHE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeTache = async () => {
    return axios.get(LISTE_TACHE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeTacheActive = async () => {
    return axios.get(LISTE_TACHE_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteTache = async (data) => {
    return axios.post(SUPPRIMER_TACHE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatTache = async (data) => {
    return axios.post(MODIFIER_ETAT_TACHE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}