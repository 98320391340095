import axios from 'axios'
import {LISTE_FACTURE, LISTE_PAIEMENT_FACTURE} from '../../api/apiRoute'

// export const addBanque = async (data) => {
//     return axios.post(AJOUTER_BANQUE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }

// export const updateBanque = async (data) => {
//     return axios.post(MODIFIER_BANQUE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }

export const getListeFacture = async () => {
  return axios
    .get(LISTE_FACTURE)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

// export const deleteBanque = async (data) => {
//     return axios.post(SUPPRIMER_BANQUE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }

// export const updateEtatBanque = async (data) => {
//     return axios.post(MODIFIER_ETAT_BANQUE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }

export const getListePaiementFacture = (facture_id) => {
  return axios.get(`${LISTE_PAIEMENT_FACTURE}/${facture_id}`).then((response) => response.data)
}
