import React, {useEffect, useState} from 'react'
import {Space, Table, Alert, Tag, Button, Tooltip} from 'antd'
import { DateComponent } from '../../components/liste/dateComponent'
import { getListeHonoraireParametre } from '../../data/params/honoraire'

const HonoraireParametrage = () => {
  const [parametres, setParametres] = useState([])
  const [parametre, setParametre] = useState(null)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null);

  const [tableParams, setTableParams] = useState({
    pagination: {
        current: 1,
        pageSize: 50,
    },
});

  const handleListeHonoraire = async () => {
    setLoading(true)
    const {result, status, message} = await getListeHonoraireParametre()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setParametres(result)
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleListeHonoraire()
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
        pagination,
        filters,
        ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setParametres([]);
    }
};

  const handleModalToggle = (data, modalId) => {

    setParametre(data);
    setSelectedModal(modalId)
    setIsModalOpen(prevState => !prevState);
  }

  const columns = [
    {
      title: '#',
      render: (_, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Bénéficiaire',
      dataIndex: 'beneficiaire',
      key: 'beneficiaire',
      render: (text) => {

      },
    },
    {
      title: 'Type de bénéficiaire',
      dataIndex: 'type_beneficiaire',
      key: 'type_beneficiaire',
      render: (text) => (
        <div className='badge badge-light fw-bolder' style={{fontSize: '15px', marginLeft: '30px'}}>
          
        </div>
      ),
    },
    {
      title: 'Valeur',
      dataIndex: 'valeur',
      key: 'valeur',
      render: (_, record) => (
        <span className='display-block bold'>
        </span>
      ),
    },

    {
      title: 'description',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <div className='text-end fw-bolder fs-6 text-gray-800'>
         
        </div>
      ),
    },

    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record) =>
        record.etat_facture === 2 ? (
          <Tag color='green'>Payée</Tag>
        ) : record.etat_facture === 1 ? (
          <Tag color='orange'>En partie payée</Tag>
        ) : (
          <Tag color='yellow'>Impayée</Tag>
        ),
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateComponent
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
            <Space>
                <Tooltip color='blue' title="Modifier le manager de mission">
                    <Button
                        shape="circle"
                        size={"middle"}
                        style={{backgroundColor: "blue"}}
                        onClick={() => handleModalToggle(record, 1)}
                        icon={""}
                    />
                </Tooltip>
                <Tooltip color='blue' title="Modifier le manager de mission">
                    <Button
                        shape="circle"
                        size={"middle"}
                        style={{backgroundColor: "blue"}}
                        onClick={() => handleModalToggle(record, 2)}
                        icon={""}
                    />
                </Tooltip>
            </Space>
        </>
      ),
    },
  ]

  const filteredItems = parametres.filter(
    (item) =>
      item.libelle_formule.toLowerCase().includes(search.toLowerCase()) ||
      item.libelle_categorie.toLowerCase().includes(search.toLowerCase()) ||
      item.nom_cabinet.toLowerCase().includes(search.toLowerCase()) ||
      item.etat_facture.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
  )

  return (
    <>
      <div className='card mb-5 mb-lg-10'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Liste des paramètres d'honoraires</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
            </div>
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HonoraireParametrage
