import axios from 'axios'
import {
    AJOUTER_CATEGORIE_ABONNEMENT, LISTE_CATEGORIE_ABONNEMENT, LISTE_CATEGORIE_ABONNEMENT_ACTIVE,
    MODIFIER_CATEGORIE_ABONNEMENT, MODIFIER_ETAT_CATEGORIE_ABONNEMENT, SUPPRIMER_CATEGORIE_ABONNEMENT
} from '../../api/apiRoute'

export const addCategorieFormule = async (data) => {
    return axios.post(AJOUTER_CATEGORIE_ABONNEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateCategorieFormule = async (data) => {
    return axios.post(MODIFIER_CATEGORIE_ABONNEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeCategorieFormule = async () => {
    return axios.get(LISTE_CATEGORIE_ABONNEMENT).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeCategorieFormuleActive = async () => {
    return axios.get(LISTE_CATEGORIE_ABONNEMENT_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteCategorieFormule = async (data) => {
    return axios.post(SUPPRIMER_CATEGORIE_ABONNEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatCategorieFormule = async (data) => {
    return axios.post(MODIFIER_ETAT_CATEGORIE_ABONNEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}