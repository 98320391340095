import {useLocation} from 'react-router-dom'
import {useRef} from 'react'
import {useReactToPrint} from 'react-to-print'
import moment from 'moment/moment'
import './paiement.css'

const PaiementClientPrint = () => {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  let location = useLocation()
  const print = location.state.record
  return (
    <div className='receipt' ref={componentRef}>
      <table className='body-wrap'>
        <tbody>
          <tr>
            <td></td>
            <td className='container-print w-843'>
              <div className='content-print'>
                <table className='main' width='100%' cellPadding='0' cellSpacing='0'>
                  <tbody>
                    <tr>
                      <td className='content-wrap aligncenter'>
                        <table width='100%' cellPadding='0' cellSpacing='0'>
                          <tbody>
                            <tr>
                              <td className='content-block'>
                                <h2>Récu de paiement</h2>
                              </td>
                            </tr>
                            <tr>
                              <td className='content-block'>
                                <table className='invoice'>
                                  <tr className='item-block'>
                                    <td className='item-infos'>Client</td>
                                    <td className='alignright fw-bolder fs-5'>
                                      {print.nom_cabinet}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Service 2</td>
                                    <td className='alignright'>$ 10.00</td>
                                  </tr>
                                  <tr>
                                    <td>Service 3</td>
                                    <td className='alignright'>$ 6.00</td>
                                  </tr>
                                  <tr className='total'>
                                    <td className='alignright' width='80%'>
                                      Total
                                    </td>
                                    <td className='alignright'>$ 36.00</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr></tr>
                            <tr>
                              <td className='content-block'>
                                Company Inc. 123 Van Ness, San Francisco 94102
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='footer'>
                  <table width='100%'>
                    <tbody>
                      <tr>{/* Add your footer content here */}</tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export default PaiementClientPrint
