/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {MixedWidget10, StatsWidget1} from '../../components/layout/contents/widgets'
import {PageTitle} from '../../core/layout'
import {TablesWidget9} from '../../components/layout/contents/widgets/tables/TablesWidget9'
import CamembertChart1 from '../../components/layout/contents/widgets/others/camember1'
import {StatCard} from '../../components/dashboard/GestionnaireDashboard'

const DashboardPage: FC = () => (
  <>
    <div className='row d-flex flex-wrap' style={{marginRight: '-20px', marginLeft: '-20px'}}>
      <StatCard />
      <StatCard />
      <StatCard />
    </div>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-6'>
        <MixedWidget10
          className='card-xxl-stretch-100 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <TablesWidget9 className='card-xxl-stretch-100 mb-5 mb-xl-8' />{' '}
      </div>
      <div className='col-xl-3'>
        <CamembertChart1 className='card-flush h-md-50 mb-5 mb-xl-10' />
      </div>
      <div className='col-xl-3'>
        <CamembertChart1 className='card-flush h-md-50 mb-5 mb-xl-10' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
