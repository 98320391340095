/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from '../../components/auth/ForgotPassword'
import {Login} from '../../components/auth/Login'
import {toAbsoluteUrl} from '../../helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  const Title = () => {
    return (
      <h1 className='fw-bolder fs-2qx pb-5 pb-md-10' style={{color: '#0e0e0e'}}>
        Bienvenue sur EgouExpert Audit
      </h1>
    )
  }

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
      <div
        className='d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative'
        style={{backgroundColor: '#D1EC51', width: '100%'}}
      >
        <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
          <div className='d-flex flex-row-fluid flex-column text-center pt-lg-20'>
            <a href='#' className='mb-12'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo_egouexpert.png')}
                className='h-45px'
              />
            </a>
            <Title />
            {/*<p className='fw-bold fs-2 ' style={{color: '#070707'}}>*/}
            {/*  Discover Amazing Metronic*/}
            {/*  <br />*/}
            {/*  with great build tools*/}
            {/*</p>*/}
            <div
              className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center< bgi-position-y-bottom min-h-100px min-h-lg-800px'
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/egou/bg_cover.jpg')})`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row-fluid py-10'>
        <div className='d-flex flex-center flex-column flex-column-fluid'>
          <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
